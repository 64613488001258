import React from 'react';

import {
    Filter,
    ReferenceArrayInput,
    SelectInput,
    SearchInput,
} from 'react-admin';

import { StyledSelectArrayInput } from '@src/custom';
import expirationFilters from '@src/global/choices/expiration-filters';
import status from '@src/global/choices/status';
import { filterTypes } from './data';

import { source } from './data';

const WebinarsFilter = (props) => (
    <Filter {...props}>
        <SearchInput alwaysOn source="query" />
        <SelectInput
            allowEmpty={false}
            source="end_time"
            choices={filterTypes}
        />
        <SelectInput
            source="expiration"
            choices={expirationFilters}
        />
        <ReferenceArrayInput
            label="Products"
            reference={source.productsReference}
            source={source.productIds}
        >
            <StyledSelectArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput
            label="States"
            reference={source.statesReference}
            source={source.stateIds}
        >
            <StyledSelectArrayInput />
        </ReferenceArrayInput>
        {
            /*
        <ReferenceArrayInput
            label="Sales &amp; Marketing Category"
            source={source.salesAndMarketingCategories}
            reference={source.salesAndMarketingCategoriesReference}
        >
            <StyledSelectArrayInput />
        </ReferenceArrayInput>
            */
        }
        <ReferenceArrayInput
            label="System Category"
            source={source.systemCategories}
            reference={source.systemCategoriesReference}
        >
            <StyledSelectArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput
            label="Product Category"
            source={source.productCategories}
            reference={source.productCategoriesReference}
        >
            <StyledSelectArrayInput />
        </ReferenceArrayInput>
        <SelectInput
            source={source.status}
            choices={status}
        />
        <ReferenceArrayInput
            label="Sales Channels"
            reference={source.salesChannelsReference}
            source={source.salesChannelIds}
        >
            <StyledSelectArrayInput />
        </ReferenceArrayInput>
    </Filter>
);

export default WebinarsFilter;
