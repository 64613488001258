const source = {
    id: 'id',
    position: 'position',
    recordId: 'record_id',
    recordType: 'record_type',
    title: 'title',
    status: 'status',
    updatedAt: 'updated_at',
    salesChannels: 'sales_channels',
    daysOnHomepage: 'days_on_homepage',
    expiredAt: 'expired_at',
    archivedAt: 'archived_at',
};

export {
    source,
};
