import React from 'react';

import {
    Datagrid,
    List,
    TextField,
    ReferenceField,
} from 'react-admin';

import {
    BulkActionButtons,
    EditButtonCustom,
} from '@src/custom';

import FilterForm from './FilterForm';

import { source } from './data';

const ProductAvailabilityList = (props) => (
    <List
        {...props}
        title="Product Availability"
        sort={{ field: source.name, order: 'ASC' }}
        filters={<FilterForm />}
        bulkActionButtons={<BulkActionButtons resource="products" {...props} />}
    >
        <Datagrid>
            <TextField
                label="Product Name"
                source={source.name}
            />
            <ReferenceField
                label="Business Unit"
                source={source.businessUnitId}
                reference="business_units"
                link={false}
            >
                <TextField source="name" />
            </ReferenceField>
            <EditButtonCustom resource="products" {...props} />
        </Datagrid>
    </List>
);

export default ProductAvailabilityList;
