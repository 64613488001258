import React from 'react';
import PropTypes from 'prop-types';

import { EditButton } from 'react-admin';

import { Permissions } from '@src/global/permissions';

const EditButtonCustom = (props) => {
    const { resource } = props;

    let res = resource;
    if (res === 'admins' || res === 'employees') {
        res = 'users';
    }

    if (res === 'attachments') {
        res = 'images';
    }

    if (Permissions(res, 'update') === false) {
        return null;
    }

    return (
        <EditButton {...props} />
    );
};

EditButtonCustom.propTypes = {
    resource: PropTypes.string,
};

export default EditButtonCustom;
