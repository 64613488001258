import React from 'react';

import { SimpleForm } from 'react-admin';

import { CreateActions, MaxWidthCreate } from '@src/custom';
import { source } from '../data';
import Form from './Form';
import { validate } from './validate';

const initialValues = {
    [source.accessLevel]: 'admin',
};

const CreateAdmin = (props) => (
    <MaxWidthCreate
        {...props}
        title="Create Administrator"
        actions={<CreateActions />}
    >
        <SimpleForm
            initialValues={initialValues}
            redirect="list"
            submitOnEnter={false}
            validate={validate}
        >
            <Form {...props} isCreate />
        </SimpleForm>
    </MaxWidthCreate>
);

export default React.memo(CreateAdmin);
