import { Taxonomies } from '@src/global/taxonomies';

export const source = {
    authorName: 'authorName',
    authorEmail: 'authorEmail',
    authorId: 'author_id',
    contentImages: 'content_images',
    roleType: 'role_type',
    status: 'status',
    title: 'title',
    slug: 'slug',
    description: 'description',
    note: 'note',
    content: 'content.content',
    contentExpiredAt: 'content.expired_at',
    externalUrl: 'external_url',
    startsAt: 'starts_at',
    endsAt: 'ends_at',
    image: 'image',
    isTake20: 'take20',
    categories: 'categories',
    salesAndMarketingCategories: 'salesAndMarketingCategories',
    salesAndMarketingCategoriesReference: `taxonomies/${Taxonomies.salesAndMarketing}/categories`,
    systemCategories: 'systemCategories',
    systemCategoriesReference: `taxonomies/${Taxonomies.systemAndTechnical}/categories`,
    productCategories: 'productCategories',
    productCategoriesReference: `taxonomies/${Taxonomies.productNewsAndUpdates}/categories`,
    salesChannelIds: 'sales_channel_ids',
    salesChannels: 'sales_channels',
    salesChannelsReference: 'sales_channels',
    productIds: 'product_ids',
    products: 'products',
    productsReference: 'products',
    stateIds: 'state_ids',
    states: 'states',
    statesReference: 'states',
    createdAt: 'created_at',
    updatedAt: 'updated_at',
    publishedAt: 'published_at',
    expiredAt: 'expired_at',
    isHomeArticle: 'is_home_article',
    versionDescription: 'version_description',
};

export const staticWebinarType = [
    {
        id: 'take20',
        name: 'Take20',
    },
    {
        id: 'product_training',
        name: 'Product Training',
    },
    {
        id: 'technical_training',
        name: 'Technical Training',
    },
];

export const filterTypes = [
    { id: '', name: 'Future' },
    { id: 'past', name: 'Past' },
    { id: 'all', name: 'All' },
];
