import React from 'react';
import PropTypes from 'prop-types';

import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles({
    listItem: {
        color: 'rgba(0, 0, 0, 0.54)',
        paddingBottom: '12px',
        paddingLeft: '16px',
        paddingRight: '2px',
        paddingTop: '12px',
    },
    menuItem: {
        'color': 'rgba(0, 0, 0, 0.54)',
        'paddingBottom': '0',
        'paddingLeft': '0',
        'paddingRight': '2px',
        'paddingTop': '0',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        '& span': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
    },
});

const SubMenu = ({ currentItem, item, title, children, onClick }) => {
    const classes = useStyles();

    const handleClick = React.useCallback(() => {
        onClick(item);
    }, [item, onClick]);

    const isCurrent = currentItem === item;

    return (
        <>
            <ListItem
                button
                className={classes.listItem}
                onClick={handleClick}
            >
                <MenuItem className={classes.menuItem}>
                    { title }
                </MenuItem>
                { isCurrent ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse unmountOnExit in={isCurrent} timeout="auto">
                { children }
            </Collapse>
        </>
    );
};

SubMenu.propTypes = {
    children: PropTypes.node,
    currentItem: PropTypes.string,
    item: PropTypes.string.isRequired,
    title: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

export default SubMenu;
