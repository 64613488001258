export const Taxonomies = {
    fieldManagementUpdates: 'field_management_updates',
    salesAndMarketing: 'sales_and_marketing',
    productNewsAndUpdates: 'product_news_and_updates',
    jobAids: 'job_aids',
    systemAndTechnical: 'system_and_technical',
    socialSharings: 'social_sharings',
    safetyTips: 'safety_tips',
    holidayAndSeasonal: 'holiday_and_seasonal',
    collateral: 'marketing_collateral',
};
