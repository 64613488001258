import productName from '@src/utils/productName';

export function mapAuthorForOne(responseData) {
    if (!responseData.author) {
        return responseData.author;
    }

    const {
        first_name: firstName,
        last_name: lastName,
        email,
        id,
    } = responseData.author;

    return {
        authorName: `${firstName} ${lastName}`,
        authorEmail: email,
        // eslint-disable-next-line camelcase
        author_id: id,
    };
}

export function mapAttachmentsForOne(responseData) {
    if (responseData.attachment) {
        if (responseData.attachment.filename) {
            return {
                attachment: responseData.attachment,
            };
        }
    }

    if (responseData.attachments) {
        return {
            attachments: responseData.attachments,
        };
    }
}

export function mapProductsForList(responseData) {
    if (!responseData.products) {
        return responseData.products;
    }

    return {
        products: responseData.products.map((product) => {
            const name = productName(product.name, product.platform);

            return { id: product.slug, name };
        }),
    };
}

export function mapProductsForOne(responseData) {
    if (!responseData.products) {
        return responseData.products;
    }

    return {
        products: responseData.products.map((item) => {
            return item.slug;
        }),
    };
}

export function mapSalesChannelsForList(data) {
    if (!data.sales_channels) {
        return data.sales_channels;
    }

    return {
        // eslint-disable-next-line camelcase
        sales_channels: data.sales_channels.map((datum) => {
            return { id: datum.slug, name: datum.name };
        }),
    };
}

export function mapStatesForList(responseData) {
    if (!responseData.states) {
        return responseData.states;
    }

    return {
        states: responseData.states.map((state) => ({
            id: state.slug,
            slug: state.slug,
            name: state.abbreviation,
        })),
    };
}

export function mapBrandsForOne(responseData) {
    if (!responseData.brands) {
        return responseData.brands;
    }

    return {
        brands: responseData.brands.map((item) => {
            return item.id;
        }),
    };
}

export function mapBrandsForList(responseData) {
    if (!responseData.brands) {
        return responseData.brands;
    }

    return {
        brands: responseData.brands,
    };
}

export function mapLogosForOne(responseData) {
    if (!responseData.co_branded_video_logos) {
        return responseData.co_branded_video_logos;
    }

    return {
        // eslint-disable-next-line camelcase
        co_branded_video_logos: responseData.co_branded_video_logos.map((item) => {
            return item.id;
        }),
    };
}

export function mapLogosForList(responseData) {
    if (!responseData.co_branded_video_logos) {
        return responseData.co_branded_video_logos;
    }

    return {
        // eslint-disable-next-line camelcase
        co_branded_video_logos: responseData.co_branded_video_logos,
    };
}

export function mapFeedItem(responseData) {
    if (!responseData.feed_item) {
        return responseData.feed_item;
    }

    return {
        // eslint-disable-next-line camelcase
        feed_item: {
            ...responseData.feed_item,
            enabled: true,
        },
    };
}

function mapBySlug(key) {
    return (responseData) => {
        if (!responseData[key]) {
            return responseData[key];
        }

        return {
            [key]: responseData[key].map((item) => item.slug),
        };
    };
}

export const mapStatesForOne = mapBySlug('states');
export const mapSalesChannelsForOne = mapBySlug('sales_channels');
