import {
    cleanCommonParams,
    mapImageParams,
    mapSecondImageParams,
    mapStatesParams,
} from './common';

const mapCoBrandedVideoLogoParams = (
    isCreate,
    {
        image,
        // eslint-disable-next-line camelcase
        second_image,
        states,
        ...params
    },
) => {
    return new Promise((resolve) => {
        resolve({
            // eslint-disable-next-line camelcase
            co_branded_video_logo: {
                ...cleanCommonParams(params),
                ...mapImageParams(isCreate, image),
                ...mapSecondImageParams(isCreate, second_image),
                ...mapStatesParams(isCreate, states),
            },
        });
    });
};

export { mapCoBrandedVideoLogoParams };
