import React from 'react';
import PropTypes from 'prop-types';

import {
    TopToolbar,
    ListButton,
} from 'react-admin';

const ShowActions = ({ basePath, className, hasList }) => (
    <TopToolbar className={className}>
        { hasList && <ListButton basePath={basePath} />}
    </TopToolbar>
);

ShowActions.propTypes = {
    basePath: PropTypes.string.isRequired,
    className: PropTypes.string,
    hasList: PropTypes.bool,
    data: PropTypes.object,
    resource: PropTypes.string,
};

export default ShowActions;
