function mapGetOneCategoryData(data) {
    return {
        ...data,
        id: data.slug,
    };
}

function mapGetListCategoriesData(responseData) {
    return responseData.map((data) => ({
        ...data,
        id: data.slug,
    }));
}

export {
    mapGetOneCategoryData,
    mapGetListCategoriesData,
};
