import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme) => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + (theme.spacing(6)))]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2, 3, 3),
    },
    form: {
        marginTop: theme.spacing(),
    },
    submit: {
        marginTop: theme.spacing(3),
    },
    alert: {
        backgroundColor: theme.palette.error.dark,
    },
});

const LoginForm = ({ classes, values, error, onSubmit, onFieldChange }) => (
    <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
            <Paper className={classes.paper}>
                <Typography component="h1" variant="h5">Sign in</Typography>
                { error && <SnackbarContent className={classes.alert} message={error} /> }
                <form className={classes.form} onSubmit={onSubmit}>
                    <FormControl required fullWidth margin="normal" >
                        <InputLabel htmlFor="login">Login</InputLabel>
                        <Input
                            autoFocus
                            id="login"
                            name="login"
                            value={values.login}
                            onChange={onFieldChange}
                        />
                    </FormControl>
                    <FormControl required fullWidth margin="normal">
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input
                            name="password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={values.password}
                            onChange={onFieldChange}
                        />
                    </FormControl>
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Sign in
                    </Button>
                </form>
            </Paper>
        </main>
    </React.Fragment>
);

LoginForm.propTypes = {
    values: PropTypes.shape({
        login: PropTypes.string,
        password: PropTypes.string,
    }).isRequired,
    error: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    onFieldChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginForm);
