import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import {
    TextInput,
} from 'react-admin';

import {
    source,
} from './data';

const RedirectForm = (_props) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <TextInput
                    fullWidth
                    label="Source URL"
                    source={source.sourceUrl}
                />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    fullWidth
                    label="Target URL"
                    source={source.targetUrl}
                />
            </Grid>
        </Grid>
    );
};

RedirectForm.propTypes = {
    resource: PropTypes.string.isRequired,
    record: PropTypes.object,
};

export default RedirectForm;
