import React from 'react';

import {
    Datagrid,
    List,
    TextField,
    DateField,
    ArrayField,
} from 'react-admin';

import { Permissions } from '@src/global/permissions';

import {
    BulkActionButtons,
    EditButtonCustom,
    SingleFieldListCustom,
} from '@src/custom';

import { source } from './data';

const CoBrandedVideoCategoriesList = (props) => (
    <List
        {...props}
        title="Video Categories"
        sort={{ field: source.updatedAt, order: 'DESC' }}
        hasCreate={Permissions('co_branded_videos', 'create') === true}
        bulkActionButtons={<BulkActionButtons resource="co_branded_videos" {...props} />}
    >
        <Datagrid>
            <TextField
                label="Position"
                source={source.position}
            />
            <TextField
                label="Name"
                source={source.name}
            />
            <ArrayField
                label="Brands"
                source={source.brands}
                sortable={false}
            >
                <SingleFieldListCustom linkType={false}>
                    <TextField source="name" />
                </SingleFieldListCustom>
            </ArrayField>
            <DateField
                label="Created Date"
                source={source.createdAt}
            />
            <TextField
                label="Note"
                source={source.note}
            />
            <EditButtonCustom resource="co_branded_videos" {...props} />
        </Datagrid>
    </List>
);

export default CoBrandedVideoCategoriesList;
