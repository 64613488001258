import React from 'react';

import {
    ArrayField,
    Datagrid,
    DateField,
    List,
    TextField,
} from 'react-admin';

import { Permissions } from '@src/global/permissions';

import {
    BulkActionButtons,
    EditButtonCustom,
    CapitalizedTextField,
    ExpirationDateField,
    SingleFieldListCustom,
} from '@src/custom';

import { source } from './data';
import Filter from './Filter';

const WebinarsList = (props) => (
    <List
        {...props}
        title="Upcoming Live Webinars"
        filters={<Filter />}
        sort={{ field: source.updatedAt, order: 'DESC' }}
        hasCreate={Permissions('webinars', 'create') === true}
        bulkActionButtons={<BulkActionButtons resource="webinars" {...props} />}
    >
        <Datagrid>
            <TextField
                label="Title"
                source={source.title}
            />
            <ArrayField
                label="Category"
                source={source.categories}
                sortable={false}
            >
                <SingleFieldListCustom linkType={false}>
                    <TextField source="name" />
                </SingleFieldListCustom>
            </ArrayField>
            <ArrayField
                label="Products"
                source={source.products}
                sortable={false}
            >
                <SingleFieldListCustom linkType={false}>
                    <TextField source="name" />
                </SingleFieldListCustom>
            </ArrayField>
            <ArrayField
                label="Sales Channel"
                source={source.salesChannels}
                sortable={false}
            >
                <SingleFieldListCustom linkType={false}>
                    <TextField source="name" />
                </SingleFieldListCustom>
            </ArrayField>
            <DateField
                label="Start Date"
                source={source.startsAt}
            />
            <DateField
                label="End Date"
                source={source.endsAt}
            />
            <DateField
                label="Creation Date"
                source={source.createdAt}
            />
            <DateField
                label="Last Updated"
                source={source.updatedAt}
            />
            <ExpirationDateField
                label="Expiration Date"
                source={source.expiredAt}
            />
            <CapitalizedTextField
                label="Status"
                source={source.status}
            />
            <EditButtonCustom resource="webinars" {...props} />
        </Datagrid>
    </List>
);

export default WebinarsList;
