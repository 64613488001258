import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import {
    ReferenceInput,
    SelectInput,
} from 'react-admin';

import { Permissions } from '@src/global/permissions';

const FirstRow = (props) => {
    const {
        children,
        isCreate,
        record,
        resource,
        source,
    } = props;

    const childCount = React.Children.count(children);
    const oneChild = childCount === 1;
    const manyChildren = childCount > 1;
    const userName = (record) => `${record.first_name} ${record.last_name}`;

    return (
        <Grid container>
            <Grid item xs={12} sm={2}>
                <Grid container justify="flex-start">
                    <Grid item>
                        {
                            !isCreate &&
                                <ReferenceInput
                                    required
                                    label="Author"
                                    source={source.authorId}
                                    resource={resource}
                                    record={record}
                                    reference="admins"
                                >
                                    <SelectInput
                                        fullWidth
                                        emptyText="Select new admin"
                                        optionText={userName}
                                        disabled={Permissions(resource, 'update') !== true}
                                    />
                                </ReferenceInput>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={10}>
                <Grid container justify="flex-end">
                    {
                        oneChild &&
                        children
                    }
                    {
                        manyChildren &&
                        React.Children.map(children, (child) => {
                            return (
                                <Grid item>
                                    {child}
                                </Grid>
                            );
                        })
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

FirstRow.propTypes = {
    children: PropTypes.node,
    isCreate: PropTypes.bool,
    record: PropTypes.object.isRequired,
    resource: PropTypes.string.isRequired,
    source: PropTypes.object.isRequired,
};

export default FirstRow;
