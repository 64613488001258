import React from 'react';

import { SimpleForm } from 'react-admin';

import { validate } from './validate';

import { CreateActions, MaxWidthCreate } from '@src/custom';
import Form from './Form';

const CreateAlert = (props) => (
    <MaxWidthCreate
        title="Create Alert"
        actions={<CreateActions />}
        {...props}
    >
        <SimpleForm
            initialValues={{ status: 'draft' }}
            redirect="edit"
            submitOnEnter={false}
            validate={validate}
        >
            <Form isCreate {...props} />
        </SimpleForm>
    </MaxWidthCreate>
);

export default CreateAlert;
