import React from 'react';
import PropTypes from 'prop-types';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Button } from 'react-admin';

import { useCopyStringToClipboard } from '@src/hooks/use-copy-string-to-clipboard';

const CopyVideoURLButton = ({ video }) => {
    const copyCallback = useCopyStringToClipboard(video.source);

    return (
        <Button
            label="Copy URL"
            onClick={copyCallback}
        >
            <FileCopyIcon />
        </Button>
    );
};

CopyVideoURLButton.propTypes = {
    video: PropTypes.shape({
        source: PropTypes.string.isRequired,
    }).isRequired,
};

export default CopyVideoURLButton;
