import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useNotify } from 'react-admin';

import { uploadAndCreateAttachment } from '@src/services/api/attachments';
import { file2Attachment } from './utils';

import Dropzone from './attachment-dropzone';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    backdrop: {
        color: '#fff',
        zIndex: theme.zIndex.drawer + 1,
        position: 'absolute',
    },
}));

const AttachmentUploader = (props) => {
    const { accept, multiple = true, onAttachmentsAdded } = props;

    const [uploading, setUploading] = useState(false);
    const notify = useNotify();
    const classes = useStyles();

    const handleFileSelection = useCallback((selectedFiles) => {
        setUploading(true);
        const attachments = selectedFiles.map(file2Attachment);

        Promise.all(attachments.map(uploadAndCreateAttachment))
            .then((createdAttachments) => {
                onAttachmentsAdded(createdAttachments);
                setUploading(false);
            }).catch((e) => {
                notify(`Error on uploading files: ${e.message}`, 'error');
                setUploading(false);
            });
    }, [setUploading, onAttachmentsAdded, notify]);

    return (
        <div className={classes.root}>
            <Dropzone accept={accept} multiple={multiple} onFileSelected={handleFileSelection} />
            <Backdrop className={classes.backdrop} open={uploading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

AttachmentUploader.propTypes = {
    accept: PropTypes.string,
    multiple: PropTypes.bool,
    onAttachmentsAdded: PropTypes.func.isRequired,
};

export default AttachmentUploader;
