import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import isArray from 'lodash/isArray';
import { Labeled, useInput } from 'react-admin';

import Item from './attachment-item';
import Selector from './attachment-selector';
import Uploader from './attachment-uploader';

const AttachmentInput = ({
    accept,
    fullWidth = true,
    helperText,
    itemLabel,
    label = 'Attachment',
    hideThumbnail,
    ...props
}) => {
    const { input, meta, isRequired } = useInput(props);

    const handleAttachmentsAdded = useCallback((attachment) => {
        if (isArray(attachment)) {
            attachment = attachment.length > 0 ? attachment[0] : null;
        }

        input.onChange(attachment);
        input.onBlur();
    }, [input]);

    const handleAttachmentRemoved = useCallback(() => {
        input.onChange(null);
        input.onBlur();
    }, [input]);

    const handleAttachmentUpdated = useCallback((attachment) => {
        input.onChange(attachment);
        input.onBlur();
    }, [input]);

    return (
        <Labeled
            fullWidth={fullWidth}
            label={label}
            isRequired={isRequired}
            meta={meta}
            input={input}
            resource={props.resource}
            source={props.source}
        >
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Uploader accept={accept} multiple={false} onAttachmentsAdded={handleAttachmentsAdded} />
                </Grid>
                <Grid item xs={12}>
                    <Selector onAttachmentSelected={handleAttachmentsAdded} />
                </Grid>
                {
                    input.value &&
                        <Grid item xs={12}>
                            <Item
                                attachment={input.value}
                                label={itemLabel}
                                hideThumbnail={hideThumbnail}
                                onAttachmentRemoved={handleAttachmentRemoved}
                                onAttachmentUpdated={handleAttachmentUpdated}
                            />
                        </Grid>
                }
                { meta.touched && meta.error && <Grid item xs={12}><FormHelperText>{meta.error}</FormHelperText></Grid> }
                { helperText && <Grid item xs={12}><FormHelperText>{helperText}</FormHelperText></Grid> }
            </Grid>
        </Labeled>
    );
};

AttachmentInput.propTypes = {
    accept: PropTypes.string,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
    itemLabel: PropTypes.string,
    label: PropTypes.string,
    hideThumbnail: PropTypes.bool,
    resource: PropTypes.string,
    source: PropTypes.string,
};

export default React.memo(AttachmentInput);
