
import jwtDecode from 'jwt-decode';

export const Permissions = (resource, action) => {
    const jwt = window.localStorage.getItem('jwt');
    if (jwt) {
        const decodedJWT = jwtDecode(jwt);

        if (decodedJWT && !decodedJWT.permissions) {
            // old jwt has no permissions
            return true;
        }

        const { permissions } = decodedJWT;

        if (!permissions[resource]) {
            // old jwt has no resource in permissions
            return true;
        }

        return permissions[resource][action];
    }

    return false;
};
