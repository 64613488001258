import React from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import { imageURL } from '@src/services/api/images';

import theme from '@src/containers/theme';
import ImageChooser from '@src/custom/image_input/image_chooser';
import ImageUploader from '@src/custom/image_input/image_uploader';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    or: {
        padding: theme.spacing(2),
        textAlign: 'center',
    },
}));

/**
 * Images selector for CKEditor images selector.
 *
 * This component is used as root for /image-selectors path and loaded by
 * popup window from the CKEditor file browser.
 */
const ImageSelector = () => {
    const classes = useStyles();
    const { search } = useLocation();

    const handleImageSelect = React.useCallback((image) => {
        const parsedSearch = queryString.parse(search) || {};
        const funcRef = parsedSearch.CKEditorFuncNum;

        if (funcRef) {
            window.opener.CKEDITOR.tools.callFunction(funcRef, imageURL(image));
        }

        window.close();
    }, [search]);

    const handleCancel = React.useCallback(() => {
        window.close();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.container}
            >
                <Grid item xs={12}>
                    <ImageUploader
                        folderName="content"
                        onCancel={handleCancel}
                        onImageUploaded={handleImageSelect}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.or} variant="body1">
                        or choose existing
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <ImageChooser
                        folderName="content"
                        showCopy={false}
                        onImageChoosed={handleImageSelect}
                    />
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default ImageSelector;
