import {
    mapAuthorForOne,
    mapSalesChannelsForOne,
    mapSalesChannelsForList,
} from './common';

function mapGetOneHomeBannerData(responseData) {
    return {
        ...responseData,
        ...mapAuthorForOne(responseData),
        ...mapSalesChannelsForOne(responseData),
    };
}

function mapGetListHomeBannersData(responseData) {
    return responseData.map((data) => ({
        ...data,
        ...mapSalesChannelsForList(data),
    }));
}

export { mapGetOneHomeBannerData, mapGetListHomeBannersData };
