const source = {
    carrierName: 'carrier.name',
    createdAt: 'created_at',
    nbPremDirect: 'nb_prem_direct',
    nbPremReferral: 'nb_prem_referral',
    productName: 'product.name',
    renDirect: 'ren_direct',
    renReferral: 'ren_referral',
    stateAbbreviation: 'state.abbreviation',
    updatedAt: 'updated_at',
};

export { source };
