import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import {
    DateInput,
    BooleanInput,
    FormDataConsumer,
    ReferenceArrayInput,
    SelectInput,
    TextInput,
} from 'react-admin';

import { FirstRow, SecondRow } from '@src/components/FormHeader';

import {
    AttachmentsInput,
    ContentImagesInput,
    AddToHomepageField,
    ExpirationDateInput,
    PublicationDateInput,
    FeedInputs,
    ImageInput,
    ProductStatesSelect,
    RichTextInput,
    RoleTypeInput,
    StyledSelectArrayInput,
    TitleSlug,
} from '@src/custom';
import { status, RSMTrainings } from '@src/global/choices';
import { source } from './data';

const JobAidForm = (props) => {
    const { isCreate, resource } = props;

    return (
        <>
            <FirstRow key="first-row" {...props} source={source}>
                <ExpirationDateInput resource={resource} source={source} />
                <PublicationDateInput resource={resource} source={source} />
                <SelectInput
                    source={source.status}
                    choices={status}
                />
            </FirstRow>
            <SecondRow key="second-row">
                <RoleTypeInput displayLeft source={source.roleType} />
                <FormDataConsumer displayLeft>
                    {
                        ({ formData }) => (
                            formData[source.roleType] === 'manager' &&
                            <SelectInput
                                allowEmpty
                                label="RSM Training"
                                source={source.rsmTraining}
                                choices={RSMTrainings}
                            />
                        )
                    }
                </FormDataConsumer>
                <AddToHomepageField resource={resource} source={source} />
                <FeedInputs resource={resource} />
            </SecondRow>
            <TitleSlug
                required
                isCreate={isCreate}
                source={source}
                pageUrlPart="training-and-job-aids/job-aids"
            />

            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        required
                        label="Description"
                        source={source.description}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TextInput fullWidth label="Internal Note" source={source.note} />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <RichTextInput label="Content" source={source.content} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DateInput
                        label="Content Expiration Date"
                        source={source.contentExpiredAt}
                    />
                </Grid>
                <Grid item md xs={12}>
                    <ContentImagesInput
                        label="Expiration dates for embedded content images"
                        source={source.contentImages}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <ImageInput source={source.image} />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={4}>
                    <BooleanInput
                        label="This job aid includes a video"
                        source={source.hasVideo}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <BooleanInput
                        label="This job aid was a Take20"
                        source={source.take20}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12}>
                    <AttachmentsInput
                        fullWidth
                        source={source.attachments}
                        label="Attachments"
                        // Selecting ".docm" file by mimetype works incorrectly
                        // in the chrome on MacOS X (it allows to select file by later rejects it).
                        // Because of this I added ".docm" extension to the accept list.
                        accept="application/pdf, application/vnd.ms-word.document.macroEnabled.12, .docm"
                    />
                </Grid>
            </Grid>

            {
                /*
            <Grid container>
                <Grid item xs={12}>
                    <ReferenceArrayInput
                        required
                        label="Sales &amp; Marketing Category"
                        source={source.salesAndMarketingCategories}
                        reference={source.salesAndMarketingCategoriesReference}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
            </Grid>
                */
            }

            <Grid container>
                <Grid item xs={12}>
                    <ReferenceArrayInput
                        required
                        label="System Category"
                        source={source.systemCategories}
                        reference={source.systemCategoriesReference}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <ReferenceArrayInput
                        key="select-array-input-products"
                        required
                        label="Product Category"
                        source={source.productCategories}
                        reference={source.productCategoriesReference}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <ReferenceArrayInput
                        key="select-array-sales-channels"
                        required
                        label="Sales Channel"
                        source={source.salesChannels}
                        reference={source.salesChannelReference}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={6}>
                    <ReferenceArrayInput
                        required
                        label="Applicable Products"
                        reference={source.productsReference}
                        source={source.products}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth groupBy="business_unit_name" />
                    </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ProductStatesSelect
                        fullWidth
                        required
                        label="Applicable States"
                        source={source.states}
                        productsSource={source.products}
                        resource={resource}
                    />
                </Grid>
            </Grid>
        </>
    );
};

JobAidForm.propTypes = {
    isCreate: PropTypes.bool,
    resource: PropTypes.string.isRequired,
};

export default JobAidForm;
