import React from 'react';

import {
    Filter,
    SearchInput,
    SelectInput,
} from 'react-admin';

import expirationFilters from '@src/global/choices/expiration-filters';
import status from '@src/global/choices/status';

import { source } from './data';

const SocialSharingsFormFilter = (props) => {
    return (
        <Filter {...props}>
            <SearchInput alwaysOn source="query" />
            <SelectInput
                source="expiration"
                choices={expirationFilters}
            />
            <SelectInput
                source={source.status}
                choices={status}
            />
        </Filter>
    );
};

export default SocialSharingsFormFilter;
