import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import PeopleIcon from '@material-ui/icons/People';
import {
    Button,
} from 'react-admin';

import Modal from '@src/custom/modal-window';
import Reassign from './reassign';

const ReassingButton = ({ user }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenButtonClick = useCallback(() => {
        setModalOpen(true);
    }, [setModalOpen]);

    const handleClose = useCallback(() => {
        setModalOpen(false);
    }, [setModalOpen]);

    const handleCreate = useCallback(() => {
        setModalOpen(false);
    }, [setModalOpen]);

    return (
        <>
            <Button
                label="Reassign Content"
                onClick={handleOpenButtonClick}
            >
                <PeopleIcon />
            </Button>

            {
                modalOpen &&
                    <Modal
                        open={modalOpen}
                        title="How should we reassign the content owned by this user?"
                        onClose={handleClose}
                    >
                        <Reassign
                            userId={user.id}
                            onCancel={handleClose}
                            onCreated={handleCreate}
                        />
                    </Modal>

            }
        </>
    );
};

ReassingButton.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
};

export default ReassingButton;
