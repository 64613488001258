import { createTheme } from '@material-ui/core/styles';
import { defaultTheme } from 'react-admin';

const appTheme = createTheme({
    ...defaultTheme,
    sidebar: {
        width: 280,
        closedWidth: 280,
    },
    props: {
        MuiGrid: {
            spacing: 2,
        },
    },
});

export default appTheme;
