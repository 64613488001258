import get from 'lodash/get';

import { addError } from '@src/validations';
import { source } from '../data';

export const validate = (values) => {
    const errors = {};

    if (!get(values, source.employeeId)) {
        addError(errors, source.employeeId, 'An EmployeeID is required');
    }

    if (!get(values, source.email)) {
        addError(errors, source.email, 'An Email is required');
    }

    if (!get(values, source.roleId)) {
        addError(errors, source.roleId, 'A Group Access is required');
    }

    if (get(values, source.notifyAboutPageExpiration) || get(values, source.notifyAboutItemExpiration)) {
        if (!get(values, source.expireDayBefore) && !get(values, source.expireIn7Days) && !get(values, source.expireIn30Days)) {
            addError(errors, source.expireDayBefore, 'Choose expire period');
        }
    }

    return errors;
};

