import React, { cloneElement, createElement, Component, Children } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { linkToRecord } from 'ra-core';

import { Link } from 'react-admin';

const styles = createStyles({
    root: { display: 'flex', flexWrap: 'wrap' },
});

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e) => e.stopPropagation();

const sanitizeRestProps = ({
    currentSort,
    setSort,
    isLoading,
    loadedOnce,
    loading,
    ...props
}) => props;

/**
 * Iterator component to be used to display a list of entities, using a single field
 *
 * @example Display all the books by the current author
 * <ReferenceManyField reference="books" target="author_id">
 *     <SingleFieldListCustom>
 *         <ChipField source="title" />
 *     </SingleFieldListCustom>
 * </ReferenceManyField>
 *
 * By default, it includes a link to the <Edit> page of the related record
 * (`/books/:id` in the previous example).
 *
 * Set the linkType prop to "show" to link to the <Show> page instead.
 *
 * @example
 * <ReferenceManyField reference="books" target="author_id" linkType="show">
 *     <SingleFieldListCustom>
 *         <ChipField source="title" />
 *     </SingleFieldListCustom>
 * </ReferenceManyField>
 *
 * You can also prevent `<SingleFieldListCustom>` from adding link to children by setting
 * `linkType` to false.
 *
 * @example
 * <ReferenceManyField reference="books" target="author_id" linkType={false}>
 *     <SingleFieldListCustom>
 *         <ChipField source="title" />
 *     </SingleFieldListCustom>
 * </ReferenceManyField>
 */
export class SingleFieldListCustom extends Component {
    // Our handleClick does nothing as we wrap the children inside a Link but it is
    // required fo ChipField which uses a Chip from material-ui.
    // The material-ui Chip requires an onClick handler to behave like a clickable element
    handleClick = () => { };

    render() {
        const {
            classes = {},
            className,
            ids,
            data,
            loadedOnce,
            resource,
            basePath,
            children,
            linkType,
            ...rest
        } = this.props;

        if (loadedOnce === false) {
            return <LinearProgress />;
        }

        return (
            <div
                className={classnames(classes.root, className)}
                {...sanitizeRestProps(rest)}
            >
                {ids.map((id, index) => {
                    // eslint-disable-next-line no-negated-condition
                    const resourceLinkPath = !linkType ?
                        false :
                        linkToRecord(basePath, id, linkType);

                    if (resourceLinkPath) {
                        return (
                            <Link
                                key={id}
                                className={classnames(classes.link, className)}
                                to={resourceLinkPath}
                                onClick={stopPropagation}
                            >
                                {cloneElement(Children.only(children), {
                                    record: data[id],
                                    resource,
                                    basePath,
                                    // Workaround to force ChipField to be clickable
                                    onClick: this.handleClick,
                                })}
                            </Link>
                        );
                    }

                    let childClone = cloneElement(
                        Children.only(children),
                        {
                            key: id,
                            record: data[id],
                            resource,
                            basePath,
                        }
                    );

                    if (ids.length - 1 === index) {
                        return childClone;
                    }
                    return createElement(
                        Typography,
                        {
                            key: 'spacer-' + id,
                            component: 'span',
                            variant: 'body2',
                            classes: { root: 'white-space-pre' },
                        },
                        <React.Fragment>{childClone.props.record.name + String.fromCharCode(44, 160)}</React.Fragment>
                    );
                })}
            </div>
        );
    }
}

SingleFieldListCustom.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.element.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
    data: PropTypes.object,
    ids: PropTypes.array,
    linkType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    loadedOnce: PropTypes.bool,
    resource: PropTypes.string,
};

SingleFieldListCustom.defaultProps = {
    classes: {},
    linkType: 'edit',
};

export default withStyles(styles)(SingleFieldListCustom);
