function mapGetOneCoBrandedVideoRequestData(responseData) {
    return {
        ...responseData,
    };
}

function mapGetListCoBrandedVideoRequestsData(responseData) {
    return responseData.map((data) => ({
        ...data,
    }));
}

export { mapGetOneCoBrandedVideoRequestData, mapGetListCoBrandedVideoRequestsData };
