import React from 'react';
import PropTypes from 'prop-types';

import { Redirect } from 'react-router-dom';

const CheckNotLogin = ({ children }) => {
    if (window.localStorage.getItem('jwt')) {
        return <Redirect to="/" />;
    }

    return children;
};

CheckNotLogin.propTypes = {
    children: PropTypes.node,
};

export default CheckNotLogin;
