import get from 'lodash/get';

import { addError } from '@src/validations';

import { source } from './data';

function hasProductOrState(values) {
    const products = get(values, source.products, []);
    const states = get(values, source.states, []);

    return products.length > 0 || states.length > 0;
}

export const validate = (values) => {
    const errors = {};

    if (!get(values, source.name)) {
        addError(errors, source.name, 'A Name is required');
    }

    if (!get(values, source.video)) {
        addError(errors, source.video, 'A Video is required');
    }

    if (!values[source.brands] || values[source.brands].length <= 0) {
        errors[source.brands] = ['A Brand is required'];
    }

    if (!hasProductOrState(values)) {
        addError(errors, source.products, 'A Product or State is required');
        addError(errors, source.states, 'A Product or State is required');
    }

    if (!get(values, source.transitionTimeStr)) {
        addError(errors, source.transitionTimeStr, 'A Transition Time is required');
    }

    if (get(values, source.transitionTimeStr)) {
        const validMMSS = (str) => /^([012345]?[0-9]):[0-5][0-9]$/.test(str);

        if (!validMMSS(get(values, source.transitionTimeStr))) {
            addError(errors, source.transitionTimeStr, 'Inavild format');
        }
    }

    return errors;
};

