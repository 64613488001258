import React from 'react';
import PropTypes from 'prop-types';

import {
    TextInput,
    NumberInput,
    ReferenceArrayInput,
} from 'react-admin';

import {
    StyledSelectArrayInput,
} from '@src/custom';

import Grid from '@material-ui/core/Grid';

import { source } from './data';

const CoBrandedVideoCategoryForm = (props) => {
    const { resource } = props;

    return (
        <>
            <Grid container >
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        required
                        label="Name"
                        source={source.name}
                    />
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={12}>
                    <ReferenceArrayInput
                        key="select-array-input-brands"
                        required
                        label="Brands"
                        reference={source.brandsReference}
                        source={source.brands}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        label="Note"
                        source={source.note}
                    />
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={12}>
                    <NumberInput
                        fullWidth
                        min={0}
                        step={1}
                        label="Position"
                        source={source.position}
                    />
                </Grid>
            </Grid>
        </>
    );
};

CoBrandedVideoCategoryForm.propTypes = {
    resource: PropTypes.string.isRequired,
    record: PropTypes.object,
};

export default CoBrandedVideoCategoryForm;
