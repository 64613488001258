import React from 'react';

import {
    Datagrid,
    List,
    TextField,
} from 'react-admin';

import { EditResourceButton, BulkActionButtons } from '@src/custom';

import { source } from './data';
import Filter from './filter';

const CategorizationsList = (props) => (
    <List
        {...props}
        title="Categorizations"
        filters={<Filter />}
        bulkActionButtons={<BulkActionButtons {...props} />}
    >
        <Datagrid>
            <TextField
                label="Title"
                source={source.title}
                sortable={false}
            />
            <TextField
                label="Type"
                source={source.recordType}
                sortable={false}
            />
            <TextField
                label="Discarded At"
                source={source.discardedAt}
            />
            <TextField
                label="Status"
                source={source.status}
                sortable={false}
            />
            <EditResourceButton />
        </Datagrid>
    </List>
);

export default CategorizationsList;

