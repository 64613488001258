import React from 'react';

import Grid from '@material-ui/core/Grid';
import {
    ArrayInput,
    SimpleFormIterator,
    TextInput,
} from 'react-admin';

import { source } from './data';

import ResourceInput from './resource-input';

const CategoryForm = () => {
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        required
                        label="Category Name"
                        source={source.name}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        label="Notes"
                        source={source.note}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <ArrayInput label="Pages using this category" source={source.resource}>
                        <SimpleFormIterator disableAdd disableRemove>
                            <ResourceInput />
                        </SimpleFormIterator>
                    </ArrayInput>
                </Grid>
            </Grid>
        </>
    );
};

export default CategoryForm;

