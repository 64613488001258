import React from 'react';

import axios from 'axios';
import { useParams } from 'react-router-dom';

import Auth from '@src/containers/auth/auth';
import { apiUrl, authHeaders } from '@src/services/api/helpers';

const GetSignedUrlFileHidden = () => {
    const { id } = useParams();

    React.useEffect(() => {
        axios.get(
            apiUrl(`/attachments/${id}/download`),
            { headers: authHeaders() },
        ).then((response) => {
            location.href = response.data.attachment.download_url;
        }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }, [id]);

    return null;
};

const GetSignedUrlFile = (props) => (
    <Auth>
        <GetSignedUrlFileHidden {...props} />
    </Auth>
);

export default GetSignedUrlFile;
