const source = {
    name: 'name',
    updatedAt: 'updated_at',
    usersCount: 'users_count',
};

const resources = {
    // eslint-disable-next-line camelcase
    home_articles: 'Featured Content',
    // eslint-disable-next-line camelcase
    home_links: 'Custom Homepage Links',
    // eslint-disable-next-line camelcase
    product_updates: 'Product News & Updates',
    // eslint-disable-next-line camelcase
    underwriting_guidelines: 'Underwriting Guidlines',
    // eslint-disable-next-line camelcase
    rate_bulletins: 'Rate Bulletins',
    trainings: 'eLearning',
    // eslint-disable-next-line camelcase
    job_aids: 'Job Aids',
    webinars: 'Webinar',
    alerts: 'Alerts',
    products: 'Product Availability',
    // eslint-disable-next-line camelcase
    form_entries: 'Contact Forms',
    categories: 'Categories',
    images: 'Media Files',
    // eslint-disable-next-line camelcase
    co_branded_videos: 'Cobranded Videos',
    // eslint-disable-next-line camelcase
    co_branded_marketing_collaterals: 'Cobranded Marketing Collaterals',
    // eslint-disable-next-line camelcase
    page_expirations: 'Expiration',
    redirects: 'Redirects',
    settings: 'Session Timeouts',
    // eslint-disable-next-line camelcase
    promo_notification_settings: 'Promo Notification Settings',
    roles: 'Access Groups',
    users: 'Users',
};

const actions = {
    read: 'Read',
    delete: 'Delete',
    create: 'Create',
    update: 'Edit',
    // eslint-disable-next-line camelcase
    set_expiration: 'Set Expiration Date',
    // eslint-disable-next-line camelcase
    set_published: 'Modify Publication Date',
    // eslint-disable-next-line camelcase
    set_homepage: 'Add to home page',
    // eslint-disable-next-line camelcase
    set_rss: 'Add to RSS feed',
};

const allActions = Object.keys(actions);

const setActions = [
    'set_expiration',
    'set_published',
    'set_homepage',
    'set_rss',
];

const permissionsMatrix = {
    // eslint-disable-next-line camelcase
    home_articles: ['read', 'update'],
    // eslint-disable-next-line camelcase
    home_links: allActions,
    // eslint-disable-next-line camelcase
    product_updates: allActions,
    // eslint-disable-next-line camelcase
    underwriting_guidelines: allActions,
    // eslint-disable-next-line camelcase
    rate_bulletins: allActions,
    trainings: allActions,
    // eslint-disable-next-line camelcase
    job_aids: allActions,
    webinars: allActions,
    alerts: allActions,
    products: allActions.filter((a) => !setActions.includes(a)),
    // eslint-disable-next-line camelcase
    form_entries: ['read', 'delete'],
    categories: allActions.filter((a) => !setActions.includes(a)),
    images: allActions.filter((a) => !['set_published', 'set_homepage', 'set_rss'].includes(a)),
    // eslint-disable-next-line camelcase
    co_branded_videos: allActions.filter((a) => !setActions.includes(a)),
    // eslint-disable-next-line camelcase
    co_branded_marketing_collaterals: allActions.filter((a) => !setActions.includes(a)),
    // eslint-disable-next-line camelcase
    page_expirations: ['read'],
    redirects: allActions.filter((a) => !setActions.includes(a)),
    settings: ['read', 'update'],
    // eslint-disable-next-line camelcase
    promo_notification_settings: ['read', 'update'],
    roles: allActions.filter((a) => !setActions.includes(a)),
    users: allActions.filter((a) => !setActions.includes(a)),
};

export {
    source,
    resources,
    permissionsMatrix,
};
