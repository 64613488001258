import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Modal from '@src/custom/modal-window';

import {
    DateField,
    TextField,
} from 'react-admin';

const RevisionHistoryPopup = (props) => {
    const { modalOpen, handleClose, recordRevisions } = props;
    const reversedRevisions = recordRevisions.slice().reverse();

    return (
        <Modal
            open={modalOpen}
            title="Revision History"
            onClose={handleClose}
        >
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Updated By</TableCell>
                            <TableCell>Date/time updated</TableCell>
                            <TableCell>Version Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            reversedRevisions.map((rr) => {
                                return (
                                    <TableRow key={rr.id}>
                                        <TableCell>
                                            <TextField
                                                label="Order"
                                                record={rr}
                                                source="user_name"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <DateField
                                                showTime
                                                record={rr}
                                                source="created_at"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                label="Order"
                                                record={rr}
                                                source="description"
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Modal>
    );
};

RevisionHistoryPopup.propTypes = {
    modalOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    recordRevisions: PropTypes.array.isRequired,
};

export default RevisionHistoryPopup;
