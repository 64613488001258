import {
    cleanCommonParams,
    mapProductsParams,
    mapStatesParams,
    mapImageParams,
    mapSecondImageParams,
} from './common';

const mapCollateralTemplateParams = (
    isCreate,
    {
        products,
        states,
        image,
        // eslint-disable-next-line camelcase
        second_image,
        ...params
    },
) => {
    return new Promise((resolve) => {
        resolve({
            // eslint-disable-next-line camelcase
            collateral_template: {
                ...cleanCommonParams(params),
                ...mapProductsParams(isCreate, products),
                ...mapStatesParams(isCreate, states),
                ...mapImageParams(isCreate, image),
                ...mapSecondImageParams(isCreate, second_image),
            },
        });
    });
};

export { mapCollateralTemplateParams };
