import React from 'react';

import {
    CreateButton,
    Datagrid,
    DateField,
    List,
    ShowButton,
    TopToolbar,
} from 'react-admin';

import { CapitalizedTextField } from '@src/custom';

import { source } from './data';

const CustomCommissionImportActions = ({ className, basePath }) => (
    <TopToolbar className={className}>
        <CreateButton basePath={basePath} label="New Import" />
    </TopToolbar>
);

const CarrierCommissionImportsList = (props) => (
    <List
        {...props}
        title="Carrier Commission Imports"
        actions={<CustomCommissionImportActions />}
        sort={{ field: source.createdAt, order: 'DESC' }}
    >
        <Datagrid>
            <CapitalizedTextField
                label="Status"
                source={source.status}
            />
            <DateField
                showTime
                label="Created"
                source={source.createdAt}
            />
            <DateField
                showTime
                label="Last updated"
                source={source.updatedAt}
            />
            <ShowButton />
        </Datagrid>
    </List>
);

export default CarrierCommissionImportsList;
