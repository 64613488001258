import React from 'react';

import {
    Datagrid,
    List,
    TextField,
} from 'react-admin';

import { source } from './data';

const BusinessUnitsList = (props) => (
    <List
        {...props}
        title="Business Units"
        bulkActionButtons={null}
    >
        <Datagrid>
            <TextField
                label="Name"
                source={source.name}
            />
        </Datagrid>
    </List>
);

export default BusinessUnitsList;
