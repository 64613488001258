import React from 'react';

import {
    Filter,
    ReferenceArrayInput,
    SearchInput,
    SelectInput,
} from 'react-admin';

import { StyledSelectArrayInput } from '@src/custom';
import expirationFilters from '@src/global/choices/expiration-filters';
import status from '@src/global/choices/status';

import { source } from './data';

const FieldManagementNewsFormFilter = (props) => {
    return (
        <Filter {...props}>
            <SearchInput alwaysOn source="query" />
            <SelectInput
                source="expiration"
                choices={expirationFilters}
            />
            <ReferenceArrayInput
                label="Products"
                reference={source.productsReference}
                source={source.productIds}
            >
                <StyledSelectArrayInput />
            </ReferenceArrayInput>
            <ReferenceArrayInput
                label="States"
                reference={source.statesReference}
                source={source.stateIds}
            >
                <StyledSelectArrayInput />
            </ReferenceArrayInput>
            <SelectInput
                source={source.status}
                choices={status}
            />
            <ReferenceArrayInput
                label="Sales Channels"
                reference={source.salesChannelsReference}
                source={source.salesChannelIds}
            >
                <StyledSelectArrayInput />
            </ReferenceArrayInput>
        </Filter>
    );
};

export default FieldManagementNewsFormFilter;
