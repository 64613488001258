function isValidUrl(string, opts = {}) {
    try {
        if (opts.relative) {
            // eslint-disable-next-line no-new
            new URL(string, 'http://example.com');
        } else {
            // eslint-disable-next-line no-new
            new URL(string);
        }
        return true;
    } catch (_) {
        return false;
    }
}

export { isValidUrl };
