export const source = {
    id: 'id',
    name: 'name',
    slug: 'slug',
    businessUnitId: 'business_unit_id',
    productStates: 'product_states',
};

export const availabilityMapStatuses = [
    { id: 'Available', name: 'Available' },
    { id: 'Piloting', name: 'Piloting' },
    { id: 'Coming Soon', name: 'Coming Soon' },
    { id: 'Not Available', name: 'Not Available' },
];
