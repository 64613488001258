import get from 'lodash/get';
import set from 'lodash/set';

import { addError } from '@src/validations';
import { source } from './data';

function validatePublished(values, errors) {
    if (!get(values, source.publishedAt)) {
        addError(errors, source.publishedAt, 'A Publication Date is required');
    }

    const writingCompanies = get(values, source.writingCompanies, []);
    if (writingCompanies.length < 1) {
        addError(errors, source.writingCompanies, 'At least 1 writing company must be applied');
    }

    if (!get(values, source.businessEffectiveDate)) {
        addError(errors, source.businessEffectiveDate, 'A New Business Effective Date is required');
    }

    if (!get(values, source.renewalEffectiveDate)) {
        addError(errors, source.renewalEffectiveDate, 'A Renewal Effective Date is required');
    }

    const products = get(values, source.products, []);
    if (products.length !== 1) {
        addError(errors, source.products, 'Exactly 1 product must be applied');
    }

    const states = get(values, source.states, []);
    if (states.length !== 1) {
        addError(errors, source.states, 'Exactly 1 state must be applied');
    }

    const attachment = get(values, source.attachment);
    if (!attachment || attachment._destroy) {
        set(errors, source.attachment, 'An Attachment is required');
        // addError(errors, source.attachment, 'An Attachment is required');
    }
}

export const validate = (values) => {
    const errors = {};

    if (!get(values, source.title)) {
        addError(errors, source.title, 'An Internal Title is required');
    }

    if (get(values, source.status) === 'published') {
        validatePublished(values, errors);
    }

    return errors;
};
