import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';
import { Labeled, useInput } from 'react-admin';

import ExpirationDate from '@src/custom/expiration-date';
import CopyURLButton from './copy-image-url-button';
import EditButton from './edit-image-button';
import ImageChooserButton from './image_chooser_button';
import ImageUploaderButton from './image_uploader_button';
import RemoveButton from './remove-image-button';

const useStyles = makeStyles((theme) => ({
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    thumbnailContainer: {
        position: 'relative',
    },
    thumbnail: {
        height: '100%',
        width: '100%',
        minHeight: '14rem',
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

const ImageInput = ({
    fullWidth = true,
    helperText,
    label = 'Featured Image',
    folderName = 'featured',
    allowEdit = true,
    ...props
}) => {
    const { input, meta, isRequired } = useInput(props);
    const classes = useStyles();

    const handleChooserChoice = useCallback((image) => {
        input.onChange(image);
    }, [input]);

    const handleImageUploaded = useCallback((image) => {
        input.onChange(image);
    }, [input]);

    const handleImageUpdated = useCallback((image) => {
        input.onChange(image);
        input.onBlur();
    }, [input]);

    const handleImageRemoved = useCallback(() => {
        input.onChange(null);
        input.onBlur();
    }, [input]);

    return (
        <Labeled
            fullWidth={fullWidth}
            label={label}
            isRequired={isRequired}
            meta={meta}
            input={input}
            resource={props.resource}
            source={props.source}
        >
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <Grid container spacing={0}>
                            {
                                input.value &&
                                    <Grid item className={classes.thumbnailContainer} xs={12} sm={3} md={4}>
                                        <CardMedia
                                            className={classes.thumbnail}
                                            image={get(input.value, 'source')}
                                            title={get(input.value, 'alt')}
                                        />
                                    </Grid>
                            }
                            <Grid item className={classes.details} xs={12} sm={9} md={8}>
                                <CardContent className={classes.content}>
                                    <Typography component="h5" variant="h5">
                                        { get(input.value, 'alt') }
                                    </Typography>
                                    <ExpirationDate expiredAt={get(input.value, 'expired_at')} />
                                </CardContent>
                                <CardActions className={classes.actions}>
                                    <ImageChooserButton folderName={folderName} onImageChoosed={handleChooserChoice} />
                                    <ImageUploaderButton folderName={folderName} onImageUploaded={handleImageUploaded} />
                                </CardActions>
                                {
                                    input.value &&
                                        <CardActions className={classes.actions}>
                                            <CopyURLButton image={input.value} />
                                            {
                                                allowEdit && (
                                                    <>
                                                        <EditButton image={input.value} onUpdated={handleImageUpdated} />
                                                        <RemoveButton
                                                            image={input.value}
                                                            onRemove={handleImageRemoved}
                                                        />
                                                    </>
                                                )
                                            }
                                        </CardActions>
                                }
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                { meta.touched && meta.error && <Grid item xs={12}><FormHelperText>{meta.error}</FormHelperText></Grid> }
                { helperText && <Grid item xs={12}><FormHelperText>{helperText}</FormHelperText></Grid> }
            </Grid>
        </Labeled>
    );
};

ImageInput.propTypes = {
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    resource: PropTypes.string,
    source: PropTypes.string,
    folderName: PropTypes.string,
    required: PropTypes.bool,
    allowEdit: PropTypes.bool,
};

export default React.memo(ImageInput);
