const removeCharacter = (str, charPos) => {
    if (!str) {
        return '';
    }
    const part1 = str.substring(0, charPos);
    const part2 = str.substring(charPos + 1, str.length);
    return (part1 + part2);
};

export default removeCharacter;
