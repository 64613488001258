function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    let result = false;

    try {
        document.execCommand('copy');
        result = true;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Fallback: Oops, unable to copy', err);
        result = false;
    }

    document.body.removeChild(textArea);
    return result;
}

function copyStringToClipboard(text) {
    if (!navigator.clipboard) {
        return new Promise((resolve, reject) => {
            if (fallbackCopyTextToClipboard(text)) {
                resolve();
            } else {
                reject();
            }
        });
    }

    return navigator.clipboard.writeText(text);
}

export default copyStringToClipboard;
