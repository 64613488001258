import React from 'react';
import PropTypes from 'prop-types';

import { createStyles, withStyles } from '@material-ui/core/styles';

import {
    TextField,
    SimpleShowLayout,
} from 'react-admin';

const TextFieldCustom = (props) => {
    const {
        classes,
        label,
        record,
        resource,
        source,
    } = props;

    return (
        <SimpleShowLayout className={classes.root} record={record} resource={resource}>
            <TextField label={label} source={source} />
        </SimpleShowLayout>
    );
};

TextFieldCustom.propTypes = {
    classes: PropTypes.object,
    label: PropTypes.string,
    record: PropTypes.any,
    resource: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
};

const styles = createStyles({
    root: { paddingBottom: '0 !important', paddingTop: '0 !important' },
});

export default withStyles(styles)(TextFieldCustom);
