import {
    cleanCommonParams,
    mapContentParams,
    mapImageParams,
    mapSalesChannelsParams,
} from './common';

const mapHomeBannerParams = (
    isCreate,
    {
        content,
        image,
        // eslint-disable-next-line camelcase
        sales_channels,
        ...params
    },
) => {
    return new Promise((resolve) => {
        resolve({
            // eslint-disable-next-line camelcase
            home_banner: {
                ...cleanCommonParams(params),
                ...mapContentParams(isCreate, content),
                ...mapImageParams(isCreate, image),
                ...mapSalesChannelsParams(isCreate, sales_channels),
            },
        });
    });
};

export { mapHomeBannerParams };
