import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import get from 'lodash/get';

import { useCopyStringToClipboard } from '@src/hooks/use-copy-string-to-clipboard';

const TextCopyField = ({ record = {}, source, title = 'Copy' }) => {
    const value = get(record, source);
    const copyCallback = useCopyStringToClipboard(value);

    if (!value) return null;

    return (
        <Button
            size="small"
            color="primary"
            onClick={copyCallback}
        >
            { title }
        </Button>
    );
};

TextCopyField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    title: PropTypes.string,
};

export default React.memo(TextCopyField);
