import React from 'react';

import {
    ArrayField,
    Datagrid,
    DateField,
    List,
    TextField,
} from 'react-admin';

import { Permissions } from '@src/global/permissions';

import {
    BulkActionButtons,
    EditButtonCustom,
    CapitalizedTextField,
    ExpirationDateField,
    SingleFieldListCustom,
} from '@src/custom';

import Filter from './Filter';
import { source } from './data';

const ProductUpdatesList = (props) => (
    <List
        {...props}
        title="Product News and Updates"
        filters={<Filter />}
        sort={{ field: source.updatedAt, order: 'DESC' }}
        hasCreate={Permissions('product_updates', 'create') === true}
        bulkActionButtons={<BulkActionButtons resource="product_updates" {...props} />}
    >
        <Datagrid>
            <TextField
                label="Title"
                source={source.title}
            />
            <ArrayField
                label="Category"
                source={source.categories}
                sortable={false}
            >
                <SingleFieldListCustom linkType={false}>
                    <TextField source="name" />
                </SingleFieldListCustom>
            </ArrayField>
            <ArrayField
                label="Products"
                source={source.products}
                sortable={false}
            >
                <SingleFieldListCustom linkType={false}>
                    <TextField source="name" />
                </SingleFieldListCustom>
            </ArrayField>
            <ArrayField
                label="Sales Channels"
                source={source.salesChannels}
                sortable={false}
            >
                <SingleFieldListCustom linkType={false}>
                    <TextField source="name" />
                </SingleFieldListCustom>
            </ArrayField>
            <DateField
                label="Creation Date"
                source={source.createdAt}
            />
            <DateField
                label="Last Updated"
                source={source.updatedAt}
            />
            <ExpirationDateField
                label="Expiration Date"
                source={source.expiredAt}
            />
            <DateField
                label="Publication Date"
                source={source.publishedAt}
            />
            <CapitalizedTextField
                label="Status"
                source={source.status}
            />
            <CapitalizedTextField
                label="Featured"
                source={source.isFeatured}
            />
            <EditButtonCustom resource="product_updates" {...props} />
        </Datagrid>
    </List>
);

export default ProductUpdatesList;
