import {
    mapAttachmentsForOne,
    mapAuthorForOne,
    mapFeedItem,
    mapProductsForList,
    mapProductsForOne,
    mapSalesChannelsForOne,
    mapSalesChannelsForList,
    mapStatesForList,
    mapStatesForOne,
} from './common';

function mapCategoriesforOne(responseData) {
    return {
        categories: responseData.categories.length === 0 ? [] : responseData.categories.map((category) => {
            return category.slug;
        }),
    };
}

function mapCategoriesForList(data) {
    return {
        categories: data.categories.length === 0 ? [] : data.categories.map((category) => {
            return {
                id: category.slug,
                name: category.name,
            };
        }),
    };
}

function mapGetOneProductUpdateData(responseData) {
    return {
        ...responseData,
        ...mapCategoriesforOne(responseData),
        ...mapSalesChannelsForOne(responseData),
        ...mapProductsForOne(responseData),
        ...mapStatesForOne(responseData),
        ...mapFeedItem(responseData),
        ...mapAttachmentsForOne(responseData),
        ...mapAuthorForOne(responseData),
    };
}

function mapGetListProductUpdatesData(responseData) {
    return responseData.map((data) => ({
        ...data,
        ...mapCategoriesForList(data),
        ...mapProductsForList(data),
        ...mapSalesChannelsForList(data),
        ...mapStatesForList(data),
    }));
}

export { mapGetOneProductUpdateData, mapGetListProductUpdatesData };
