import axios from 'axios';

import { BASE_API_URL } from './helpers';

const IMPERSONATE_PATH = '/auth_token/impersonate';

export const impersonate = (id) => {
    const token = window.localStorage.getItem('jwt');
    return axios({
        method: 'post',
        url: `${BASE_API_URL}${IMPERSONATE_PATH}`,
        data: {
            // eslint-disable-next-line camelcase
            agent_id: id,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }).then((res) => {
        if (!res) {
            throw new Error('Invalid employee ID');
        }

        if (res.status < 200 || res.status >= 300) {
            throw new Error('Invalid employee ID');
        }

        return res.data;
    }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error on impersonate request: ', error);
        throw new Error('Invalid employee ID');
    });
};
