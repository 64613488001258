import axios from 'axios';
import forOwn from 'lodash/forOwn';

const uploadToCloudinary = (file, uploadUrl, bodyParams) => {
    const data = new FormData();
    data.append('file', file);

    forOwn(bodyParams, (v, k) => data.append(k, v));

    return axios.post(uploadUrl, data)
        .then((response) => response.data);
};

const chunkedUploadToCloudinary = async (file, uploadUrl, bodyParams) => {
    let result = null;
    const chunkSize = 20 * 1024 * 1024;
    const totalChunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    const uniqueUploadId = `uqid-${Date.now()}`;

    const uploadChunk = async (start, end) => {
        const formData = new FormData();
        forOwn(bodyParams, (v, k) => formData.append(k, v));
        formData.append('file', file.slice(start, end));
        const contentRange = `bytes ${start}-${end - 1}/${file.size}`;

        try {
            const response = await fetch(
                uploadUrl,
                {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'X-Unique-Upload-Id': uniqueUploadId,
                        'Content-Range': contentRange,
                    },
                },
            );

            if (!response.ok) {
                throw new Error('Chunk upload failed.');
            }

            currentChunk++;

            if (currentChunk < totalChunks) {
                const nextStart = currentChunk * chunkSize;
                const nextEnd = Math.min(nextStart + chunkSize, file.size);
                await uploadChunk(nextStart, nextEnd);
            } else {
                result = await response.json();
                return result;
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error uploading chunk:', error);
        }
    };

    const start = 0;
    const end = Math.min(chunkSize, file.size);
    await uploadChunk(start, end);
    return result;
};

export {
    uploadToCloudinary,
    chunkedUploadToCloudinary,
};
