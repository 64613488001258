import React from 'react';
import PropTypes from 'prop-types';

import includes from 'lodash/includes';
import map from 'lodash/map';
import { useField, useForm } from 'react-final-form';

import { StyledSelectArrayInput } from '@src/custom/Styled';

const SELECT_ALL_VALUE = 'select-all';
const SELECT_ALL_LABEL = 'Select all';
const CLEAR_ALL_VALUE = 'clear-all';
const CLEAR_ALL_LABEL = 'Clear all';

const SelectAllArrayInput = ({
    source,
    choices,
    optionText,
    optionValue,
    ...props
}) => {
    const { change } = useForm();
    const { input } = useField(source);
    const value = input.value || [];
    choices = choices || [];

    React.useEffect(() => {
        if (includes(value, SELECT_ALL_VALUE)) {
            change(source, map(choices, optionValue));
        } else if (includes(value, CLEAR_ALL_VALUE)) {
            change(source, []);
        }
    }, [value, change, source]);

    const isAllSelected = value.length === choices.length;

    const choicesWithAll = [{
        [optionValue]: isAllSelected ? CLEAR_ALL_VALUE : SELECT_ALL_VALUE,
        [optionText]: isAllSelected ? CLEAR_ALL_LABEL : SELECT_ALL_LABEL,
    }].concat(choices);

    return (
        <StyledSelectArrayInput
            {...props}
            source={source}
            optionText={optionText}
            optionValue={optionValue}
            choices={choicesWithAll}
        />
    );
};

SelectAllArrayInput.propTypes = {
    source: PropTypes.string.isRequired,
    choices: PropTypes.array,
    optionText: PropTypes.string,
    optionValue: PropTypes.string,
};

SelectAllArrayInput.defaultProps = {
    optionText: 'name',
    optionValue: 'id',
};

export default React.memo(SelectAllArrayInput);
