const FeedItemSource = {
    enabled: 'feed_item.enabled',
    priority: 'feed_item.priority',
    channels: 'feed_item.channels',
};

const FeedItemPriorities = [
    {
        id: 'low',
        name: 'Low',
    },
    {
        id: 'medium',
        name: 'Medium',
    },
    {
        id: 'high',
        name: 'High',
    },
];

const FeedItemChannels = [
    {
        id: 'IA',
        name: 'IA',
    },
    {
        id: 'PCS',
        name: 'PCS',
    },
    {
        id: 'GCS Sales',
        name: 'GCS Sales',
    },
    {
        id: 'GCS Service',
        name: 'GCS Service',
    },
    {
        id: 'MetGA',
        name: 'MetGA',
    },
    {
        id: 'Other',
        name: 'Other',
    },
];

export { FeedItemSource, FeedItemChannels, FeedItemPriorities };
