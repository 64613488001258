import {
    cleanCommonParams,
} from './common';

const mapBrandParams = (
    isCreate,
    {
        ...params
    },
) => {
    return new Promise((resolve) => {
        resolve({
            brand: {
                ...cleanCommonParams(params),
            },
        });
    });
};

export { mapBrandParams };
