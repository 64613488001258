import React from 'react';

import { SimpleForm } from 'react-admin';

import { EditActions, MaxWidthEdit, EditWithNewVersionToolbar } from '@src/custom';
import Form from './Form';
import { validate } from './validate';

const EditWebinar = (props) => (
    <MaxWidthEdit
        {...props}
        title="Edit Upcoming Live Webinar"
        actions={<EditActions />}
    >
        <SimpleForm
            toolbar={<EditWithNewVersionToolbar />}
            redirect={false}
            submitOnEnter={false}
            validate={validate}
        >
            <Form {...props} />
        </SimpleForm>
    </MaxWidthEdit>
);

export default EditWebinar;
