import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import capitalize from 'lodash/capitalize';

const ContentTypeField = ({ record = {}, source }) => {
    const contentType = get(record, source);

    if (!contentType) return null;

    let type = capitalize(contentType);
    if (type !== 'Attachment') {
        type = `${type} Image`;
    }

    return (
        <span>{type}</span>
    );
};

ContentTypeField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default ContentTypeField;
