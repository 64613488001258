import React from 'react';

import {
    Filter,
    SelectInput,
    SearchInput,
} from 'react-admin';

import expirationFilters from '@src/global/choices/expiration-filters';
import { filterFolder } from './data';

const ImagesFilter = (props) => (
    <Filter {...props}>
        <SearchInput alwaysOn source="query" />
        <SelectInput
            allowEmpty={false}
            source="folder"
            choices={filterFolder}
        />
        <SelectInput
            source="expiration"
            choices={expirationFilters}
        />
    </Filter>
);

export default ImagesFilter;
