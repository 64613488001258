import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Loading, Error, useGetOne, useUpdate, useNotify } from 'react-admin';

import Form from './edit-attachment-form';

const EditAttachment = (props) => {
    const { attachmentId, onUpdated, onCancel } = props;

    const { loading, error, data } = useGetOne('attachments', attachmentId);
    const [update, { loading: updating }] = useUpdate('attachments', attachmentId);
    const notify = useNotify();

    const handleSubmit = useCallback((values) => {
        update(
            { payload: { data: values } },
            {
                onSuccess: ({ data }) => {
                    onUpdated(data);
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                },
            }
        );
    }, [update, notify, onUpdated]);

    if (loading) return <Loading />;
    if (error) return <Error error={error} errorInfo={{ componentStack: null }} />;
    if (!data) return null;

    return (
        <Form attachment={data} updating={updating} onCancel={onCancel} onSubmit={handleSubmit} />
    );
};

EditAttachment.propTypes = {
    attachmentId: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
    onUpdated: PropTypes.func.isRequired,
};

export default EditAttachment;
