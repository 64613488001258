import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';

import { uploadAndCreateImage } from '@src/services/api/images';
import { formatBytes } from '@src/utils/number-helpers';
import Dropzone from './image_uploader_dropzone';
import Actions from './image_uploader_actions';

const useStyles = makeStyles({
    media: {
        backgroundSize: 'contain',
        objectFit: 'cover',
        width: '100%',
    },
});

const ImageUploader = (props) => {
    const { folderName, onCancel, onImageUploaded } = props;

    const classes = useStyles();

    const [imageBlob, setImageBlob] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [error, setError] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const handleFileSelection = useCallback((imageFile) => {
        setImageFile(imageFile);

        const reader = new FileReader();
        reader.onabort = () => {
            setImageBlob(null);
            setError('file reading was aborted');
        };
        reader.onerror = () => {
            setImageBlob(null);
            setError('file reading has failed');
        };
        reader.onload = () => {
            setImageBlob(reader.result);
            setError(null);
        };
        reader.readAsDataURL(imageFile);
    }, []);

    const handleImageUploadError = useCallback((error) => {
        let errorMessage = error.messsage;

        const responseMessage = get(error, ['response', 'data', 'error', 'message']);
        if (responseMessage) {
            errorMessage = responseMessage.replace(
                /(\d+(?:((?=\.))\d*))/gm,
                (match) => formatBytes(match),
            );
        }

        setIsUploading(false);
        setImageBlob(null);
        setError(errorMessage);
    }, [setIsUploading, setImageBlob, setError]);

    const handleImageUploadSuccess = useCallback((image) => {
        setIsUploading(false);
        setError(null);

        onImageUploaded(image);
    }, [setIsUploading, setError, onImageUploaded]);

    const handleImageSave = useCallback((file) => {
        setIsUploading(true);
        uploadAndCreateImage(file, folderName)
            .then((image) => {
                handleImageUploadSuccess(image);
            }).catch((error) => {
                handleImageUploadError(error);
            });
    }, [folderName, setIsUploading, handleImageUploadSuccess, handleImageUploadError]);

    const handleSaveClick = useCallback((e) => {
        e.preventDefault();

        if (imageFile) {
            handleImageSave(imageFile);
        } else {
            // eslint-disable-next-line no-alert
            alert('No Image Selected');
        }
    }, [imageFile, handleImageSave]);

    return (
        <Card>
            { imageBlob ? null : <Dropzone onFileSelected={handleFileSelection} /> }

            <Grid container className="" justify="center" alignItems="center">
                <Grid item xs={6}>
                    { imageBlob && <Paper><img className={classes.media} src={imageBlob} alt="Selected image" /></Paper> }
                    { error && <Typography variation="caption" align="center"color="error">{error}</Typography> }
                </Grid>
            </Grid>

            <CardActions>
                <Actions
                    isFileSelected={Boolean(imageBlob)}
                    isUploading={isUploading}
                    onSave={handleSaveClick}
                    onCancel={onCancel}
                />
            </CardActions>
        </Card>
    );
};

ImageUploader.propTypes = {
    folderName: PropTypes.oneOf(['featured', 'thumbnail', 'content']).isRequired,
    onCancel: PropTypes.func.isRequired,
    onImageUploaded: PropTypes.func,
};

export default React.memo(ImageUploader);
