import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { ImageField } from 'react-admin';

const useStyles = makeStyles({
    root: {
        '& img': {
            maxHeight: '3rem',
            maxWidth: '9rem',
            margin: 0,
        },
    },
});

const ThumbnailField = (props) => {
    const classes = useStyles();

    return (
        <ImageField className={classes.root} {...props} />
    );
};

export default ThumbnailField;
