import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        overflowY: 'auto',
        maxHeight: '500px',
    },
    table: {
        minWidth: 700,
    },
});

const ImportResult = ({ classes, result }) => (
    <Paper className={classes.root}>
        <Table className={classes.table}>
            <TableBody>
                {
                    result.split('\n').map((row, i) => {
                        if (!row) {
                            return null;
                        }

                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <TableRow key={i}>
                                <TableCell>
                                    { row }
                                </TableCell>
                            </TableRow>
                        );
                    })
                }
            </TableBody>
        </Table>
    </Paper>
);

ImportResult.propTypes = {
    classes: PropTypes.object.isRequired,
    result: PropTypes.string.isRequired,
};

export default withStyles(styles)(ImportResult);
