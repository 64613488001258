import React from 'react';
import PropTypes from 'prop-types';

import {
    CloneButton,
    TopToolbar,
    ListButton,
    DeleteButton,
} from 'react-admin';

import ReassignButton from './reassing-button';

const EditAdminActions = (props) => {
    const { basePath, data, resource, className, hasList } = props;
    const clonedData = data ?
        {
            ...data,
            status: 'draft',
            title: `${data.title} CLONED`,
            slug: `${data.slug}-cloned`,
        } :
        {};
    return (
        <TopToolbar className={className}>
            <>
                {
                    data &&
                    <>
                        <DeleteButton
                            basePath={basePath}
                            undoable={false}
                            record={data}
                            resource={resource}
                        />
                        <CloneButton basePath={basePath} record={clonedData} />
                        <ReassignButton user={data} />
                    </>
                }
                { hasList && <ListButton basePath={basePath} />}
            </>
        </TopToolbar>
    );
};

EditAdminActions.propTypes = {
    basePath: PropTypes.string.isRequired,
    className: PropTypes.string,
    hasList: PropTypes.bool,
    data: PropTypes.object,
    resource: PropTypes.string,
};

export default EditAdminActions;
