import get from 'lodash/get';

import { addError, validateDescriptionLength } from '@src/validations';
import { source } from './data';

function hasProductOrState(values) {
    return (
        (values[source.products] && values[source.products].length > 0) ||
        (values[source.states] && values[source.states].length > 0)
    );
}

function validatePublished(values, errors) {
    if (!get(values, source.categories)) {
        addError(errors, source.categories, 'A Category is required');
    }

    if (!get(values, source.content)) {
        addError(errors, source.content, 'Article Content is required');
    }

    if (!get(values, source.datePublishedAt)) {
        addError(errors, source.datePublishedAt, 'A Publication Date is required');
    }

    if (!values[source.image]) {
        errors[source.image] = ['An Image is required'];
    }

    if (!values[source.title]) {
        errors[source.title] = ['A Title is required'];
    }

    if (!values[source.description]) {
        errors[source.description] = ['A Description is required'];
    }

    if (!values[source.salesChannels] || values[source.salesChannels].length <= 0) {
        errors[source.salesChannels] = ['A Sales Channel is required'];
    }

    if (!hasProductOrState(values)) {
        errors[source.products] = ['A Product or State is required'];
        errors[source.states] = ['A Product or State is required'];
    }
}

export const validate = (values) => {
    const errors = {};

    if (!values[source.slug]) {
        errors[source.slug] = ['An URL is required'];
    }

    addError(
        errors,
        source.description,
        validateDescriptionLength(values[source.description]),
    );

    if (values[source.status] === 'published') {
        validatePublished(values, errors);
    }

    return errors;
};
