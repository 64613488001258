import React from 'react';
import PropTypes from 'prop-types';

import { FormDataConsumer, BooleanInput } from 'react-admin';

import Grid from '@material-ui/core/Grid';

import withStyles from '@material-ui/core/styles/withStyles';

const formatRoleType = (value) => (value === 'manager');
const parseRoleType = (value) => (value ? 'manager' : 'agent');

const RoleTypeInput = ({ classes, source, options, ...rest }) => {
    // const sanatizedRestProps = delete rest.displayLeft;

    return null;

    /*
    return (
        <Grid item>
            <FormDataConsumer>
                {({ formData }) => (
                    <BooleanInput
                        label="Manager only"
                        className={classes.root}
                        {...sanatizedRestProps}
                        options={{
                            ...options,
                            checked: formatRoleType(formData[source]),
                        }}
                        format={formatRoleType}
                        parse={parseRoleType}
                        source={source}
                    />
                )}
            </FormDataConsumer>
        </Grid>
    );
    */
};

RoleTypeInput.propTypes = {
    classes: PropTypes.object,
    options: PropTypes.object,
    source: PropTypes.string.isRequired,
};

RoleTypeInput.defaultProps = {
    options: {},
};

const styles = {
    root: {
        marginLeft: '16px',
    },
};

export default withStyles(styles)(RoleTypeInput);
