import axios from 'axios';

import { BASE_API_URL } from './helpers';

const AUTH_TOKEN_PATH = '/auth_token';

export const postAuthToken = (values) => {
    return axios({
        method: 'post',
        url: `${BASE_API_URL}${AUTH_TOKEN_PATH}`,
        data: { auth: values },
    }).then((res) => {
        if (!res) {
            throw new Error('Invalid login or password');
        }

        if (res.status < 200 || res.status >= 300) {
            throw new Error('Invalid login or password');
        }

        return res.data;
    }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error on auth token request: ', error);
        throw new Error('Invalid login or password');
    });
};
