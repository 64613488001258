import React from 'react';
import PropTypes from 'prop-types';

import { Permissions } from '@src/global/permissions';

import Grid from '@material-ui/core/Grid';
import {
    BooleanInput,
} from 'react-admin';

const AddToHomepageField = (props) => {
    const { source, resource } = props;

    if (Permissions(resource, 'set_homepage') === false) {
        return null;
    }

    return (
        <Grid item>
            <BooleanInput
                label="Add to home page"
                source={source.isHomeArticle}
            />
        </Grid>
    );
};

AddToHomepageField.propTypes = {
    resource: PropTypes.string,
    source: PropTypes.string,
};

export default AddToHomepageField;
