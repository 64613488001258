import has from 'lodash/has';
import get from 'lodash/get';
import set from 'lodash/set';

const addError = (errors, field, error) => {
    if (!error) {
        return;
    }

    if (!has(errors, field)) {
        set(errors, field, []);
    }
    const fieldErrors = get(errors, field);

    fieldErrors.push(error);
};

export { addError };
