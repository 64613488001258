import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { CancelButton } from '@src/custom';

import {
    Toolbar,
    SaveButton,
    TextInput,
} from 'react-admin';

const EditWithNewVersionToolbar = (props) => {
    const [open, setOpen] = React.useState(false);
    const handleClick = () => setOpen(true);

    const handleCancel = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleSave = useCallback((e) => {
        e.preventDefault();
        props.handleSubmitWithRedirect('edit');
    }, [props]);

    return (
        <Toolbar {...props} alwaysEnableSaveButton>
            <SaveButton
                redirect={false}
                submitOnEnter={false}
                handleSubmitWithRedirect={handleClick}
                handleSubmit={handleClick}
            />
            <Dialog
                fullWidth
                open={open}
                onClose={handleCancel}
            >
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1} >Version Notes</Box>
                        <Box>
                            <IconButton onClick={handleCancel}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <TextInput
                        fullWidth
                        multiline
                        label="Version Description (optional)"
                        source="version_description"
                        resource={props.resource}
                    />
                    <Box display="flex" mb={2}>
                        <Box mr="1rem">
                            <Button
                                autoFocus
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                            >
                                Save Changes
                            </Button>
                        </Box>
                        <Box>
                            <CancelButton title="Cancel Changes" onClick={handleCancel} />
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </Toolbar>
    );
};

EditWithNewVersionToolbar.propTypes = {
    resource: PropTypes.string,
    handleSubmitWithRedirect: PropTypes.func,
};

export default EditWithNewVersionToolbar;
