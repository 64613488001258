import React from 'react';

import Grid from '@material-ui/core/Grid';
import {
    DateInput,
    SelectInput,
    TextInput,
} from 'react-admin';

import { status } from '@src/global/choices';

import { FirstRow } from '@src/components/FormHeader';
import {
    ContentImagesInput,
    RichTextInput,
    SlugInput,
} from '@src/custom';

import { source } from './data';

const slugValue = (slug) => slug.replace('rsm-agent360-', '');

const RSMAgent360Form = (props) => (
    <>
        <FirstRow key="first-row" {...props} source={source}>
            <DateInput
                label="Expiration Date"
                source={source.expiredAt}
                inputProps={{ readOnly: true }}
            />
            <DateInput
                isRequired
                label="Publication Date"
                source={source.publishedAt}
            />
            <SelectInput
                source={source.status}
                choices={status}
            />
        </FirstRow>
        <Grid container >
            <Grid item xs={12}>
                <TextInput
                    fullWidth
                    label="Title"
                    source={source.title}
                />
            </Grid>
        </Grid>
        <Grid container >
            <Grid item xs={12}>
                <SlugInput
                    fullWidth
                    label="URL"
                    source={source.slug}
                    pageUrlPart="field-management/agent360"
                    format={slugValue}
                />
            </Grid>
        </Grid>
        <Grid container >
            <Grid item xs={12}>
                <RichTextInput
                    label="Content"
                    source={source.content}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <DateInput
                    label="Content Expiration Date"
                    source={source.contentExpiredAt}
                />
            </Grid>
            <Grid item md xs={12}>
                <ContentImagesInput
                    label="Expiration dates for embedded content images"
                    source={source.contentImages}
                />
            </Grid>
        </Grid>
    </>
);

export default RSMAgent360Form;
