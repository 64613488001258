/* eslint-disable camelcase */
import {
    cleanCommonParams,
    cleanTimestampsParams,
    mapAttachmentParams,
    mapFeedItemParams,
    mapProductsParams,
    mapStatesParams,
} from './common';

const mapGuideResourceParams = (isCreate, resource) => {
    if (!resource) {
        return {
            resource_attributes: {},
        };
    }

    const { id, writing_companies, ...params } = resource;

    return {
        resource_attributes: {
            ...cleanTimestampsParams(params),
            writing_company_ids: writing_companies,
        },
    };
};

const mapGuideParams = (
    isCreate,
    {
        attachment,
        // eslint-disable-next-line camelcase
        feed_item,
        products,
        resource,
        resource_id,
        states,
        ...params
    },
) => {
    return new Promise((resolve) => {
        resolve({
            guide: {
                ...cleanCommonParams(params),
                ...mapAttachmentParams(isCreate, attachment),
                ...mapFeedItemParams(isCreate, feed_item),
                ...mapProductsParams(isCreate, products),
                ...mapGuideResourceParams(isCreate, resource),
                ...mapStatesParams(isCreate, states),
            },
        });
    });
};

export { mapGuideParams };
