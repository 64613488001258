import {
    cleanCommonParams,
    mapVideoRequestBrandsParams,
} from './common';

const mapCoBrandedVideoRequestParams = (
    isCreate,
    {
        // eslint-disable-next-line camelcase
        co_branded_video_request_brands,
        ...params
    },
) => {
    return new Promise((resolve) => {
        resolve({
            // eslint-disable-next-line camelcase
            co_branded_video_request: {
                ...cleanCommonParams(params),
                ...mapVideoRequestBrandsParams(isCreate, co_branded_video_request_brands),
            },
        });
    });
};

export { mapCoBrandedVideoRequestParams };
