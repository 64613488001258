import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Create } from 'react-admin';

const styles = {
    root: {
        maxWidth: '1200px',
    },
};

const MaxWidthCreate = ({ classes, ...props }) => {
    return (
        <Create
            className={classes.root}
            {...props}
        />
    );
};

MaxWidthCreate.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MaxWidthCreate);
