const source = {
    authorName: 'authorName',
    authorEmail: 'authorEmail',
    authorId: 'author_id',
    expiredAt: 'expired_at',
    slug: 'slug',
    status: 'status',
    title: 'title',
    position: 'position',
    publishedAt: 'published_at',
    content: 'content.content',
    contentExpiredAt: 'content.expired_at',
    contentImages: 'content_images',
    updatedAt: 'updated_at',
};

export { source };
