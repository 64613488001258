import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';

import ExpirationDate from '@src/custom/expiration-date';
import CopyURLButton from './copy-attachment-url-button';
import RemoveButton from './remove-attachment-button';
import EditButton from './edit-attachment-button';

const useStyles = makeStyles((theme) => ({
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    thumbnailContainer: {
        position: 'relative',
    },
    thumbnail: {
        height: '100%',
        width: '100%',
        minHeight: '8rem',
    },
    thumbnailExpiration: {
        backgroundColor: '#ffffffCC',
        fontSize: '0.8rem',
        position: 'absolute',
        textAlign: 'center',
        width: '100%',
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

const AttachmentItem = ({
    attachment,
    hideThumbnail,
    onAttachmentRemoved,
    onAttachmentUpdated,
}) => {
    const classes = useStyles();

    const thumbnailURL = get(attachment, 'thumbnail.source');

    return (
        <Card variant="outlined">
            <Grid container spacing={0}>
                {
                    thumbnailURL && !hideThumbnail &&
                        <Grid item className={classes.thumbnailContainer} xs={12} sm={3} md={2}>
                            <ExpirationDate
                                className={classes.thumbnailExpiration}
                                expiredAt={get(attachment, ['thumbnail', 'expired_at'])}
                            />
                            <CardMedia
                                className={classes.thumbnail}
                                image={thumbnailURL}
                                title={get(attachment, 'thumbnail.alt')}
                            />
                        </Grid>
                }
                <Grid item className={classes.details} xs={12} sm={hideThumbnail ? 12 : 9} md={hideThumbnail ? 12 : 10}>
                    <CardContent className={classes.content}>
                        <Typography component="h5" variant="h5">
                            { get(attachment, 'display_name') }
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            { get(attachment, 'filename') }
                        </Typography>
                        <ExpirationDate expiredAt={get(attachment, 'expired_at')} />
                    </CardContent>
                    <CardActions className={classes.actions}>
                        <CopyURLButton attachment={attachment} />
                        <EditButton attachment={attachment} onUpdated={onAttachmentUpdated} />
                        <RemoveButton
                            attachment={attachment}
                            onRemove={onAttachmentRemoved}
                        />
                    </CardActions>
                </Grid>
            </Grid>
        </Card>
    );
};

AttachmentItem.propTypes = {
    attachment: PropTypes.object.isRequired,
    hideThumbnail: PropTypes.bool,
    onAttachmentRemoved: PropTypes.func.isRequired,
    onAttachmentUpdated: PropTypes.func.isRequired,
};

export default AttachmentItem;
