import {
    cleanCommonParams,
    mapAttachmentsParams,
    mapContentParams,
    mapFeedItemParams,
    mapProductsParams,
    mapSalesChannelsParams,
    mapStatesParams,
} from './common';

const mapAlertParams = (
    isCreate,
    {
        attachments,
        categories,
        content,
        // eslint-disable-next-line camelcase
        feed_item,
        products,
        // eslint-disable-next-line camelcase
        sales_channels,
        states,
        ...params
    },
) => {
    return new Promise((resolve) => {
        resolve({
            alert: {
                ...cleanCommonParams(params),
                ...mapAttachmentsParams(isCreate, attachments),
                ...mapContentParams(isCreate, content),
                ...mapFeedItemParams(isCreate, feed_item),
                ...mapProductsParams(isCreate, products),
                ...mapSalesChannelsParams(isCreate, sales_channels),
                ...mapStatesParams(isCreate, states),
            },
        });
    });
};

export { mapAlertParams };
