import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-admin';

import ImageChooser from './image_chooser';
import Modal from '@src/custom/modal-window';

const ImageChooserButton = (props) => {
    const { folderName, onImageChoosed } = props;
    const [modalOpen, setModalOpen] = useState(false);

    const handleButtonClick = React.useCallback(() => {
        setModalOpen(true);
    }, [setModalOpen]);

    const handleClose = React.useCallback(() => {
        setModalOpen(false);
    }, [setModalOpen]);

    const handleImageChoosed = useCallback((image) => {
        setModalOpen(false);
        onImageChoosed(image);
    }, [setModalOpen, onImageChoosed]);

    return (
        <>
            <Button color="primary" label="Choose Image From Library" onClick={handleButtonClick} />
            <Modal
                open={modalOpen}
                title="Choose an image from the library"
                onClose={handleClose}
            >
                <ImageChooser
                    folderName={folderName}
                    onImageChoosed={handleImageChoosed}
                />
            </Modal>
        </>
    );
};

ImageChooserButton.propTypes = {
    folderName: PropTypes.string,
    onImageChoosed: PropTypes.func.isRequired,
};

export default ImageChooserButton;
