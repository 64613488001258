import React from 'react';

import {
    Filter,
    SelectInput,
} from 'react-admin';

import { formTypes, source } from './data';

function ContactFormsFilter(props) {
    return (
        <Filter {...props}>
            <SelectInput
                label="Form type"
                source={source.type}
                choices={formTypes}
            />
        </Filter>
    );
}

export default ContactFormsFilter;
