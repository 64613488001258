import {
    cleanCommonParams,
    mapProductStatesParams,
} from './common';

const mapProductParams = (
    isCreate,
    {
        productStates,
        ...params
    },
) => {
    return new Promise((resolve) => {
        resolve({
            product: {
                ...cleanCommonParams(params),
                ...mapProductStatesParams(isCreate, productStates),
            },
        });
    });
};

export { mapProductParams };
