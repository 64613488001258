import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import get from 'lodash/get';
import { linkToRecord, useInput } from 'react-admin';

import { classToResource, resourceHumanName } from '@src/utils/model-helpers';
import { resourceSource as source } from './data';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '1em',
    },
    resource: {
        color: theme.palette.text.secondary,
    },
    discarded: {
        textDecoration: 'line-through',
    },
}));

const ResourceInput = (props) => {
    const { input } = useInput(props);

    const classes = useStyles();

    if (!input.value) return null;

    const type = get(input.value, source.type);
    const id = get(input.value, source.id);
    const title = get(input.value, source.title);
    const resource = classToResource(type);
    const humanName = resourceHumanName(resource);
    const isDiscarded = get(input.value, source.discardedAt) !== null;

    const linkToResource = linkToRecord(`/${resource}`, id, 'edit');

    return (
        <Typography className={classes.root}>
            <span className={classes.resource}>{ humanName } &gt;</span>
            { ' ' }
            <Typography
                className={isDiscarded ? classes.discarded : null}
                component="span"
            >
                { title }
            </Typography>
            {
                !isDiscarded &&
                <>
                    { ' ' }
                    <Button
                        color="primary"
                        endIcon={<OpenInNewIcon />}
                        href={linkToResource}
                        rel="noopener noreferrer"
                        size="small"
                        target="_blank"
                    >
                        Edit
                    </Button>
                </>
            }
        </Typography>
    );
};

export default ResourceInput;

