import React from 'react';

import {
    ArrayField,
    Datagrid,
    DateField,
    EditButton,
    List,
    TextField,
} from 'react-admin';

import { source } from './data';

import {
    BulkActionButtons,
    ExpirationDateField,
    CapitalizedTextField,
    SingleFieldListCustom,
} from '@src/custom';

import FilterForm from './FilterForm';

const FieldManagementNewsList = (props) => (
    <List
        {...props}
        title="Field Management News"
        filters={<FilterForm />}
        sort={{ field: source.dateUpdatedAt, order: 'DESC' }}
        bulkActionButtons={<BulkActionButtons {...props} />}
    >
        <Datagrid>
            <TextField
                label="Title"
                source={source.title}
            />
            <ArrayField
                label="Category"
                source={source.categories}
                sortable={false}
            >
                <SingleFieldListCustom linkType={false}>
                    <TextField source="name" />
                </SingleFieldListCustom>
            </ArrayField>
            <DateField
                label="Creation Date"
                source={source.dateCreatedAt}
            />
            <DateField
                label="Last Updated"
                source={source.dateUpdatedAt}
            />
            <ExpirationDateField
                label="Expiration Date"
                source={source.expiredAt}
            />
            <CapitalizedTextField
                label="Status"
                source={source.status}
            />
            <CapitalizedTextField
                label="Featured"
                source={source.isFeatured}
            />
            <EditButton />
        </Datagrid>
    </List>
);

export default FieldManagementNewsList;
