import React from 'react';
import PropTypes from 'prop-types';

import { SimpleForm } from 'react-admin';

import { editCategoryTitle } from './utils';

import { EditActions, MaxWidthEdit } from '@src/custom';
import Form from './Form';

const EditCategory = (props) => {
    return (
        <MaxWidthEdit
            {...props}
            title={editCategoryTitle(props.basePath)}
            actions={<EditActions />}
        >
            <SimpleForm submitOnEnter={false}>
                <Form {...props} />
            </SimpleForm>
        </MaxWidthEdit>
    );
};

EditCategory.propTypes = {
    basePath: PropTypes.string,
};

export default EditCategory;
