import { API_HOST, apiGet, apiPost } from './helpers';
import { uploadToCloudinary } from './cloudinary-helpers';
import { removeFileExtenstion } from '@src/utils/filename';

const getImages = (params) => {
    return apiGet('/images', { params })
        .then((res) => {
            return {
                images: res.data.images,
                page: Number(res.headers.page),
                total: Number(res.headers.total),
                perPage: Number(res.headers['per-page']),
            };
        });
};

const getApiSignedUpload = (publicId, folder) => {
    const params = {
        'upload_params[public_id]': publicId,
        'upload_params[folder]': folder,
    };

    return apiGet('/images/new', { params: params })
        .then((response) => response.data.signed_upload);
};

const getImage = (id) => {
    return apiGet(`/images/${id}`).then((res) => res.data.image);
};

const createImage = (imageData) => {
    return apiPost('/images', { image: imageData })
        .then((response) => {
            return response.data.image;
        });
};

const uploadImage = (file, folderName) => {
    return getApiSignedUpload(removeFileExtenstion(file.name), folderName)
        .then((signedUpload) => uploadToCloudinary(file, signedUpload.upload_url, signedUpload.post_body))
        .then((response) => {
            return {
                source: response.secure_url,
                alt: response.public_id,
                format: response.format,
                folder: folderName,
                width: response.width,
                height: response.height,
                bytes: response.bytes,
            };
        });
};

const uploadAndCreateImage = (file, folderName) => {
    return uploadImage(file, folderName)
        .then((imageData) => createImage(imageData));
};

const imageURL = (image) => `/i/${image.id}`;

export {
    getImages,
    uploadImage,
    uploadAndCreateImage,
    getImage,
    imageURL,
};
