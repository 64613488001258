const source = {
    name: 'name',
    language: 'language',
    coBrandedVideoCategoryId: 'co_branded_video_category_id',
    templateProducts: 'template_products',
    transitionTimeStr: 'transition_time_str',
    disclaimer: 'disclaimer',
    previewUrl: 'preview_url',
    video: 'video',
    allProductsReference: 'all_products',
    products: 'products',
    stateIds: 'state_ids',
    states: 'states',
    brandsReference: 'brands',
    brands: 'brands',
    createdAt: 'created_at',
    updatedAt: 'updated_at',
};

const templateLanguages = [
    {
        id: 'English',
        name: 'English',
    },
    {
        id: 'Spanish',
        name: 'Spanish',
    },
];

export {
    source,
    templateLanguages,
};
