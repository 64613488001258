import React from 'react';

import {
    ArrayField,
    Datagrid,
    DateField,
    EditButton,
    List,
    TextField,
} from 'react-admin';

import {
    BulkActionButtons,
    CapitalizedTextField,
    ExpirationDateField,
    SingleFieldListCustom,
} from '@src/custom';

import FilterForm from './FilterForm';

import { source } from './data';

const SocialSharingsList = (props) => (
    <List
        {...props}
        title="Social Media Content"
        filters={<FilterForm />}
        sort={{ field: source.updatedAt, order: 'DESC' }}
        bulkActionButtons={<BulkActionButtons {...props} />}
    >
        <Datagrid>
            <TextField
                label="Title"
                source={source.title}
            />
            <TextField
                label="Social Content"
                source={source.content}
            />
            <ArrayField
                label="Category"
                source={source.categories}
                sortable={false}
            >
                <SingleFieldListCustom linkType={false}>
                    <TextField source="name" />
                </SingleFieldListCustom>
            </ArrayField>
            <DateField
                label="Last Updated"
                source={source.updatedAt}
            />
            <ExpirationDateField
                label="Expiration Date"
                source={source.expiredAt}
            />
            <CapitalizedTextField
                label="Status"
                source={source.status}
            />
            <EditButton />
        </Datagrid>
    </List>
);

export default SocialSharingsList;
