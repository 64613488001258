import React, { useEffect, useState, useCallback } from 'react';

import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { logOut } from '@src/global/logOut';
import { DEFAULT_INACTIVITY_TIMEOUT } from '@src/global/login';
import { refreshAuthToken } from '@src/requests';
import { apiGet } from '@src/services/api/helpers';

export const SessionInactivityCheck = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [secondsRemaining, setSecondsRemaining] = useState(DEFAULT_INACTIVITY_TIMEOUT);
    const [logoutTimer, setLogoutTimer] = React.useState(DEFAULT_INACTIVITY_TIMEOUT);
    const returnTo = window.location.href;

    React.useEffect(() => {
        apiGet('/settings/session_timeouts').then((response) => {
            const values = response.data.sessions_setting.setting_values;
            setLogoutTimer(values.logout_timer_seconds);
        }).catch((error) => {
            setLogoutTimer(DEFAULT_INACTIVITY_TIMEOUT);
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }, [setLogoutTimer]);

    useEffect(() => {
        const allSecondsRemaining = setInterval(() => {
            const jwt = window.localStorage.getItem('jwt');

            if (!jwt) {
                logOut(returnTo);
                return;
            }

            const decodedJWT = jwtDecode(jwt);
            const expiredAt = new Date(decodedJWT.exp * 1000).getTime();
            const remaining = Math.round((expiredAt - Date.now()) / 1000);

            setSecondsRemaining(remaining);

            if (remaining === null) {
                return;
            }

            if (remaining > logoutTimer) {
                setShowModal(false);
            }

            if (remaining <= 0) {
                logOut(returnTo);
                return;
            }

            if (remaining <= logoutTimer) {
                setShowModal(true);
            }
        }, 1000);

        return () => {
            setShowModal(false);
            clearInterval(allSecondsRemaining);
        };
    }, [logoutTimer, returnTo]);

    const handleContinue = useCallback(() => {
        refreshAuthToken().then((data) => {
            setShowModal(false);
            window.localStorage.setItem('jwt', data.jwt);
        }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(`Error on refreshing token: ${error.message}`, 'error');
        });
    }, [setShowModal]);

    const handleLogout = useCallback(() => {
        setShowModal(false);
        logOut(returnTo);
    }, [setShowModal, returnTo]);

    return (
        <>
            <Dialog
                fullWidth
                open={showModal}
                className="logout-modal"
                onClose={handleContinue}
            >
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1} >Inactive Session</Box>
                        <Box>
                            <IconButton onClick={handleContinue}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>

                    <DialogContentText id="alert-dialog-description">
                        Your session is about to expire due to inactivity.
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        You will be logged out in <b aria-atomic="true">{secondsRemaining}</b> seconds
                    </DialogContentText>
                    <Box display="flex" mb={2}>
                        <Box mr="1rem">
                            <Button
                                color="primary"
                                onClick={handleContinue}
                            >
                                Continue Session
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                color="secondary"
                                onClick={handleLogout}
                            >
                                Log Out
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            { props.children }
        </>
    );
};

SessionInactivityCheck.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SessionInactivityCheck;
