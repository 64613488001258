import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { DataGrid } from '@material-ui/data-grid';

import { formatTimestampToDate } from '@src/utils/dateHelpers';

import ExpirationDateField from '@src/custom/expiration-date-field';

const columns = [
    {
        field: 'thumbnail',
        headerName: 'Preview',
        width: 100,
        sortable: false,
        // eslint-disable-next-line react/prop-types
        renderCell: ({ value }) => {
            if (!value) return null;

            return (
                // eslint-disable-next-line react/prop-types
                <img src={value.source} alt={value.alt} style={{ maxHeight: '3rem', maxWidth: '100px', margin: 0 }} />
            );
        },
    },
    {
        field: 'display_name',
        type: 'string',
        headerName: 'Title',
        flex: 1,
        sortable: false,
    },
    {
        field: 'created_at',
        type: 'date',
        headerName: 'Uploaded On',
        width: 200,
        sortable: false,
        valueFormatter: ({ value }) => formatTimestampToDate(value),
    },
    {
        field: 'expired_at',
        type: 'date',
        headerName: 'Expiration Date',
        width: 200,
        sortable: false,
        // eslint-disable-next-line react/prop-types
        renderCell: ({ value }) => <ExpirationDateField value={value} />,
    },
];

const AttachmentSelectorModal = (props) => {
    const {
        attachmentsData,
        loading,
        onAttachmentSelected,
        onPageChange,
    } = props;

    const handlePageChange = useCallback((page) => {
        onPageChange(page + 1);
    }, [onPageChange]);

    const handleRowClick = useCallback(({ row }) => {
        onAttachmentSelected(row);
    }, [onAttachmentSelected]);

    return (
        <DataGrid
            autoHeight
            disableColumnMenu
            columns={columns}
            loading={loading}
            page={attachmentsData.page - 1}
            pageSize={attachmentsData.perPage}
            paginationMode="server"
            rowCount={attachmentsData.total}
            rows={attachmentsData.attachments}
            onPageChange={handlePageChange}
            onRowClick={handleRowClick}
        />
    );
};

AttachmentSelectorModal.propTypes = {
    attachmentsData: PropTypes.shape({
        attachments: PropTypes.array.isRequired,
        page: PropTypes.number,
        pageSize: PropTypes.number,
        perPage: PropTypes.number,
        total: PropTypes.number,
    }).isRequired,
    loading: PropTypes.bool,
    onPageChange: PropTypes.func.isRequired,
    onAttachmentSelected: PropTypes.func.isRequired,
};

export default React.memo(AttachmentSelectorModal);
