import {
    cleanCommonParams,
    mapBrandsParams,
} from './common';

const mapCoBrandedVideoCategoryParams = (
    isCreate,
    {
        brands,
        ...params
    },
) => {
    return new Promise((resolve) => {
        resolve({
            // eslint-disable-next-line camelcase
            co_branded_video_category: {
                ...cleanCommonParams(params),
                ...mapBrandsParams(isCreate, brands),
            },
        });
    });
};

export { mapCoBrandedVideoCategoryParams };
