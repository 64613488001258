import React from 'react';

import {
    Datagrid,
    DateField,
    List,
    ShowButton,
    TextField,
} from 'react-admin';

import { BulkActionButtons } from '@src/custom';

import { source } from './data';

const QuoteInquiriesList = (props) => {
    return (
        <List
            {...props}
            title="Quote inquiries"
            sort={{ field: source.createdAt, order: 'DESC' }}
            bulkActionButtons={<BulkActionButtons {...props} />}
        >
            <Datagrid rowClick="show">
                <TextField
                    label="Contact Name"
                    source={source.contactName}
                />
                <TextField
                    label="Contact Email"
                    source={source.contactEmail}
                />
                <DateField
                    label="Created At"
                    source={source.createdAt}
                />
                <ShowButton />
            </Datagrid>
        </List>
    );
};

export default QuoteInquiriesList;
