import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import EditIcon from '@material-ui/icons/Edit';
import { Button } from 'react-admin';

import Modal from '@src/custom/modal-window';
import Edit from './edit-attachment';

const EditAttachmentButton = (props) => {
    const { attachment, onUpdated } = props;

    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenButtonClick = useCallback(() => {
        setModalOpen(true);
    }, [setModalOpen]);

    const handleClose = useCallback(() => {
        setModalOpen(false);
    }, [setModalOpen]);

    const handleUpdated = useCallback((attachment) => {
        setModalOpen(false);
        onUpdated(attachment);
    }, [onUpdated, setModalOpen]);

    return (
        <React.Fragment>
            <Button
                label="Edit"
                onClick={handleOpenButtonClick}
            >
                <EditIcon />
            </Button>

            {
                modalOpen &&
                    <Modal
                        open={modalOpen}
                        title="Edit attachment"
                        onClose={handleClose}
                    >
                        <Edit
                            attachmentId={attachment.id}
                            onCancel={handleClose}
                            onUpdated={handleUpdated}
                        />
                    </Modal>

            }
        </React.Fragment>
    );
};

EditAttachmentButton.propTypes = {
    attachment: PropTypes.object.isRequired,
    onUpdated: PropTypes.func.isRequired,
};

export default EditAttachmentButton;
