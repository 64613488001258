import React from 'react';
import PropTypes from 'prop-types';

import { Permissions } from '@src/global/permissions';

import { makeStyles } from '@material-ui/core/styles';
import {
    DateInput,
    FunctionField,
    useRecordContext,
    Labeled,
} from 'react-admin';

import Moment from 'moment';

const useStyles = makeStyles((_theme) => ({
    label: {
        paddingTop: '6px',
        paddingBottom: 0,
    },
    field: {
        top: '-8px',
        position: 'relative',
        fontSize: '1em',
    },
}));

const ExpirationDateInput = (props) => {
    const { source, resource } = props;
    const record = useRecordContext();
    const classes = useStyles();

    let res = resource;
    let readOnly = true;
    if (res === 'attachments' || res === 'images') {
        res = 'images';
        readOnly = false;
    }

    if (Permissions(res, 'set_expiration') === false) {
        if (record && record.expired_at) {
            return (
                <Labeled label="Expiration Date" className={classes.label}>
                    <FunctionField
                        className={classes.field}
                        source={source.expiredAt}
                        render={(record) => Moment(record.expired_at).format('MM/DD/YYYY')}
                    />
                </Labeled>
            );
        }

        return null;
    }

    return (
        <DateInput
            label="Expiration Date"
            source={source.expiredAt}
            inputProps={{ readOnly }}
        />
    );
};

ExpirationDateInput.propTypes = {
    resource: PropTypes.string,
    source: PropTypes.string,
};

export default ExpirationDateInput;
