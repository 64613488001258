import {
    mapAttachmentsForOne,
    mapAuthorForOne,
    mapFeedItem,
    mapProductsForList,
    mapProductsForOne,
    mapSalesChannelsForOne,
    mapStatesForList,
    mapStatesForOne,
} from './common';

function mapCategoriesforOne(responseData) {
    return {
        category: typeof responseData.category === 'string' ? responseData.category : '',
    };
}

function mapCategoriesForList(data) {
    return {
        category: typeof data.category === 'string' ? data.category : '',
    };
}

function mapGetOneAlertData(responseData) {
    return {
        ...responseData,
        ...mapCategoriesforOne(responseData),
        ...mapSalesChannelsForOne(responseData),
        ...mapProductsForOne(responseData),
        ...mapStatesForOne(responseData),
        ...mapFeedItem(responseData),
        ...mapAttachmentsForOne(responseData),
        ...mapAuthorForOne(responseData),
    };
}

function mapGetListAlertsData(responseData) {
    return responseData.map((data) => ({
        ...data,
        ...mapCategoriesForList(data),
        ...mapProductsForList(data),
        ...mapStatesForList(data),
    }));
}

export { mapGetListAlertsData, mapGetOneAlertData };
