import get from 'lodash/get';

import { addError, validateDescriptionLength } from '@src/validations';
import { source } from './data';

function hasProductOrState(values) {
    const products = get(values, source.products, []);
    const states = get(values, source.states, []);

    return products.length > 0 || states.length > 0;
}

function validatePublished(values, errors) {
    if (!get(values, source.publishedAt)) {
        addError(errors, source.publishedAt, 'A Publication Date is required');
    }

    if (!get(values, source.content)) {
        addError(errors, source.content, 'Product News and Updates Content is required');
    }

    if (!values[source.title]) {
        errors[source.title] = ['A Title is required'];
    }

    if (!values[source.description]) {
        errors[source.description] = ['A Description is required'];
    }

    if (!values[source.salesChannels] || values[source.salesChannels].length <= 0) {
        errors[source.salesChannels] = ['A Sales Channel is required'];
    }

    if (!hasProductOrState(values)) {
        addError(errors, source.products, 'A Product or State is required');
        addError(errors, source.states, 'A Product or State is required');
    }
}

export const validate = (values) => {
    const errors = {};

    if (!values[source.slug]) {
        errors[source.slug] = ['An URL is required'];
    }

    addError(
        errors,
        source.description,
        validateDescriptionLength(values[source.description]),
    );

    if (values[source.status] === 'published') {
        validatePublished(values, errors);
    }

    return errors;
};

