import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconCancel from '@material-ui/icons/Cancel';
import {
    Button,
    DateInput,
    SaveButton,
    TextInput,
    FormWithRedirect,
} from 'react-admin';

import { formatBytes } from '@src/utils/number-helpers';

import ImagePreviewInput from '@src/components/images/image-preview-input';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        justifyContent: 'flex-end',
    },
    button: {
        marginLeft: theme.spacing(2),
    },
}));

const EditImageForm = (props) => {
    const { image, updating, onCancel, onSubmit } = props;

    const classes = useStyles();

    return (
        <FormWithRedirect
            initialValues={image}
            resource="images"
            save={onSubmit}
            render={
                ({
                    handleSubmitWithRedirect,
                    pristine,
                    saving,
                }) => (
                    <>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextInput
                                    fullWidth
                                    required
                                    label="Alternate text"
                                    source="alt"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DateInput
                                    fullWidth
                                    label="Expiration Date"
                                    source="expired_at"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ImagePreviewInput source="source" />
                            </Grid>
                            <Grid container item xs={12} md={6}>
                                <Grid item xs={3}>
                                    <TextInput label="File type" source="format" inputProps={{ readOnly: true }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextInput source="width" inputProps={{ readOnly: true }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextInput source="height" inputProps={{ readOnly: true }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextInput label="Size" source="bytes" format={formatBytes} inputProps={{ readOnly: true }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Toolbar disableGutters className={classes.toolbar}>
                            <Button
                                className={classes.button}
                                label="ra.action.cancel"
                                disabled={saving || updating}
                                onClick={onCancel}
                            >
                                <IconCancel />
                            </Button>
                            <SaveButton
                                className={classes.button}
                                handleSubmitWithRedirect={handleSubmitWithRedirect}
                                pristine={pristine}
                                saving={saving || updating}
                            />
                        </Toolbar>
                    </>
                )
            }
        />
    );
};

EditImageForm.propTypes = {
    image: PropTypes.object.isRequired,
    updating: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default EditImageForm;
