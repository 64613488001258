import React from 'react';

import Grid from '@material-ui/core/Grid';

import { postAuthToken } from '@src/services/api/auth-token';

import CheckNotLogin from '@src/components/layouts/CheckNotLogin';
import { LoginForm } from '@src/components/login';

const Login = () => {
    const [values, setValues] = React.useState({ login: '', password: '' });
    const [error, setError] = React.useState(null);

    const handleSubmit = React.useCallback((e) => {
        e.preventDefault();
        postAuthToken(values).then((data) => {
            window.localStorage.setItem('jwt', data.jwt);
            window.location.pathname = '/';
        }).catch((error) => {
            setError(error.message);
        });
    }, [values, setError]);

    const handleFieldChange = React.useCallback((e) => {
        e.persist();
        setValues((prevValues) => ({
            ...prevValues,
            [e.target.name]: e.target.value,
        }));
    }, [setValues]);

    return (
        <CheckNotLogin>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} sm={9} md={6}>
                    <LoginForm
                        values={values}
                        error={error}
                        onSubmit={handleSubmit}
                        onFieldChange={handleFieldChange}
                    />
                </Grid>
            </Grid>
        </CheckNotLogin>
    );
};

export default Login;
