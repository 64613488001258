import {
    cleanCommonParams,
    mapAttachmentParams,
} from './common';

const mapImportParams = (
    isCreate,
    { attachment, ...params },
) => {
    return new Promise((resolve) => {
        resolve({
            import: {
                ...cleanCommonParams(params),
                ...mapAttachmentParams(isCreate, attachment),
            },
        });
    });
};

export { mapImportParams };
