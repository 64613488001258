import React from 'react';

import { SimpleForm } from 'react-admin';

import { validate } from './validate';

import { MaxWidthEdit } from '@src/custom';
import Form from './Form';

const EditProductAvailability = (props) => (
    <MaxWidthEdit
        title="Edit Product States Availability"
        {...props}
    >
        <SimpleForm
            redirect={false}
            submitOnEnter={false}
            validate={validate}
        >
            <Form {...props} />
        </SimpleForm>
    </MaxWidthEdit>
);

export default EditProductAvailability;
