import { source } from './data';

export function validate(values) {
    const errors = {};

    if (!values[source.sourceUrl]) {
        errors[source.sourceUrl] = ['An URL is required'];
    }

    if (!values[source.targetUrl]) {
        errors[source.targetUrl] = ['An URL is required'];
    }

    if (values[source.sourceUrl] && !values[source.sourceUrl].startsWith('/')) {
        errors[source.sourceUrl] = ['Required / in the beginning'];
    }

    if (values[source.targetUrl] && !values[source.targetUrl].startsWith('/') &&
        !values[source.targetUrl].startsWith('https://')
    ) {
        errors[source.targetUrl] = ['Required / OR https:// in the beginning'];
    }

    return errors;
}
