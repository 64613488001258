import React from 'react';

import {
    SimpleForm,
} from 'react-admin';

import { validate } from './validate';

import { CreateActions, MaxWidthCreate } from '@src/custom';
import Form from './form';

const CreateCoBrandedVideoLogo = (props) => (
    <MaxWidthCreate
        title="Create Co-branded Logo"
        actions={<CreateActions />}
        {...props}
    >
        <SimpleForm
            redirect="edit"
            submitOnEnter={false}
            validate={validate}
        >
            <Form
                isCreate
                {...props}
            />
        </SimpleForm>
    </MaxWidthCreate>
);

export default CreateCoBrandedVideoLogo;
