import React from 'react';

import { SimpleForm } from 'react-admin';

import { MaxWidthEdit } from '@src/custom';
import EditActions from './edit-actions';
import Form from './Form';
import { validate } from './validate';

const EditAdmin = (props) => (
    <MaxWidthEdit
        {...props}
        title="Edit Administrator"
        actions={<EditActions />}
    >
        <SimpleForm
            redirect={false}
            submitOnEnter={false}
            validate={validate}
        >
            <Form {...props} />
        </SimpleForm>
    </MaxWidthEdit>
);

export default React.memo(EditAdmin);
