import {
    mapProductsForList,
    mapProductsForOne,
    mapStatesForList,
    mapStatesForOne,
} from './common';

function mapGetOneCollateralTemplateData(responseData) {
    return {
        ...responseData,
        ...mapProductsForOne(responseData),
        ...mapStatesForOne(responseData),
    };
}

function mapGetListCollateralTemplatesData(responseData) {
    return responseData.map((data) => ({
        ...data,
        ...mapProductsForList(data),
        ...mapStatesForList(data),
    }));
}

export { mapGetOneCollateralTemplateData, mapGetListCollateralTemplatesData };
