import React from 'react';

import { SimpleForm } from 'react-admin';

import { CreateActions, MaxWidthCreate } from '@src/custom';
import { source } from './data';
import Form from './Form';
import { validate } from './validate';

const CreateProductUpdate = (props) => (
    <MaxWidthCreate
        {...props}
        title="Create Product News and Updates"
        actions={<CreateActions />}
    >
        <SimpleForm
            initialValues={{ [source.status]: 'draft', [source.publishedAt]: new Date() }}
            redirect="edit"
            submitOnEnter={false}
            validate={validate}
        >
            <Form {...props} isCreate />
        </SimpleForm>
    </MaxWidthCreate>
);

export default CreateProductUpdate;
