import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { Loading, Error } from 'react-admin';

import { getImages } from '@src/services/api/images';

import Item from './image_chooser_item';
import SearchBar from '@src/custom/search-bar';

const useStyles = makeStyles((theme) => ({
    pagination: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

const ImageChooser = ({ folderName, showCopy = true, onImageChoosed }) => {
    const classes = useStyles();

    const [status, setStatus] = React.useState('loading');
    const [error, setError] = React.useState(null);
    const [imagesData, setImagesData] = React.useState({ images: [], page: 0, total: 0, perPage: 0 });
    const [page, setPage] = React.useState(1);
    const [searchTerm, setSearchTerm] = React.useState(null);

    React.useEffect(() => {
        const params = {
            folder: folderName, page,
        };
        if (searchTerm) {
            params.query = searchTerm;
        }

        getImages(params).then((data) => {
            setImagesData(data);
            setStatus('success');
        }).catch((e) => {
            setError(e);
            setStatus('failed');
        });
    }, [page, setImagesData, setStatus, setError, searchTerm, folderName]);

    const handlePageChange = React.useCallback((e, page) => {
        setPage(page);
    }, [setPage]);

    const handleOnChange = React.useCallback((searchTerm) => {
        setSearchTerm(searchTerm);
    }, [setSearchTerm]);

    if (status === 'loading') {
        return <Loading />;
    }

    if (status === 'failed') {
        return <Error error={error} errorInfo={{ componentStack: null }} />;
    }

    if (!imagesData.images) return null;

    return (
        <>
            <SearchBar onChange={handleOnChange} />
            <Grid container spacing={2}>
                {
                    imagesData.images.map((image) => (
                        <Item
                            key={image.id}
                            image={image}
                            showCopy={showCopy}
                            onClick={onImageChoosed}
                        />
                    ))
                }
            </Grid>
            <Pagination
                showFirstButton
                showLastButton
                className={classes.pagination}
                count={Math.floor(imagesData.total / imagesData.perPage)}
                page={imagesData.page}
                onChange={handlePageChange}
            />
        </>
    );
};

ImageChooser.propTypes = {
    folderName: PropTypes.string,
    showCopy: PropTypes.bool,
    onImageChoosed: PropTypes.func.isRequired,
    searchTerm: PropTypes.string,
};

export default React.memo(ImageChooser);
