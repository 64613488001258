import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

const INPUT_TIMEOUT = 500;

const SearchBar = ({ onChange }) => {
    const [searchTerm, setSearchTerm] = useState();

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            onChange(searchTerm);
        }, INPUT_TIMEOUT);
        return () => clearTimeout(timeOutId);
    }, [searchTerm, onChange]);

    const handleChange = useCallback((event) => {
        setSearchTerm(event.target.value);
    }, [setSearchTerm]);

    return (
        <Grid container justify="flex-end" spacing={2}>
            <Grid item>
                <TextField
                    placeholder="Search…"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    onChange={handleChange}
                />
            </Grid>
        </Grid>
    );
};

SearchBar.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default SearchBar;
