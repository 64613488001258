import React from 'react';

import {
    Datagrid,
    DateField,
    List,
    SelectField,
    ShowButton,
    TextField,
} from 'react-admin';

import { BulkActionButtons } from '@src/custom';

import Filter from './Filter';
import { formTypes, source } from './data';

const ContactFormsList = (props) => {
    return (
        <List
            {...props}
            title="Contact Forms"
            filters={<Filter />}
            sort={{ field: source.createdAt, order: 'DESC' }}
            hasCreate={false}
            bulkActionButtons={<BulkActionButtons resource="form_entries" {...props} />}
        >
            <Datagrid rowClick="show">
                <SelectField
                    label="Type"
                    source={source.type}
                    choices={formTypes}
                />
                <TextField
                    label="Contact Name"
                    source={source.contactName}
                />
                <TextField
                    label="Agent ID"
                    source={source.agentCode}
                />
                <TextField
                    label="Contact Number"
                    source={source.contactNumber}
                />
                <TextField
                    label="Contact Email"
                    source={source.contactEmail}
                />
                <DateField
                    label="Created At"
                    source={source.createdAt}
                />
                <ShowButton />
            </Datagrid>
        </List>
    );
};

export default ContactFormsList;
