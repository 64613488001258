import { cleanCommonParams } from './common';

const mapRedirectParams = (isCreate, params) => {
    return new Promise((resolve) => {
        resolve({
            // eslint-disable-next-line camelcase
            redirect_record: {
                ...cleanCommonParams(params),
            },
        });
    });
};

export { mapRedirectParams };
