import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-admin';

import VideoChooser from './video_chooser';
import Modal from '@src/custom/modal-window';

const VideoChooserButton = (props) => {
    const { folderName, onVideoChoosed } = props;
    const [modalOpen, setModalOpen] = useState(false);

    const handleButtonClick = React.useCallback(() => {
        setModalOpen(true);
    }, [setModalOpen]);

    const handleClose = React.useCallback(() => {
        setModalOpen(false);
    }, [setModalOpen]);

    const handleVideoChoosed = useCallback((video) => {
        setModalOpen(false);
        onVideoChoosed(video);
    }, [setModalOpen, onVideoChoosed]);

    return (
        <>
            <Button color="primary" label="Choose Video From Library" onClick={handleButtonClick} />
            <Modal
                open={modalOpen}
                title="Choose a video from the library"
                onClose={handleClose}
            >
                <VideoChooser
                    folderName={folderName}
                    onVideoChoosed={handleVideoChoosed}
                />
            </Modal>
        </>
    );
};

VideoChooserButton.propTypes = {
    folderName: PropTypes.string,
    onVideoChoosed: PropTypes.func.isRequired,
};

export default VideoChooserButton;
