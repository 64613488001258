import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

import Modal from '@src/custom/modal-window';
import Data from './attachment-selector-data';

const useStyles = makeStyles({
    root: {
        textAlign: 'center',
    },
});

const AttachmentSelector = (props) => {
    const { onAttachmentSelected } = props;

    const classes = useStyles();
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenButtonClick = useCallback(() => {
        setModalOpen(true);
    }, [setModalOpen]);

    const handleClose = useCallback(() => {
        setModalOpen(false);
    }, [setModalOpen]);

    const handleAttachmentSelected = useCallback((attachment) => {
        setModalOpen(false);
        onAttachmentSelected(attachment);
    }, [setModalOpen, onAttachmentSelected]);

    return (
        <div className={classes.root}>
            <Button
                label="Choose file from library"
                size="large"
                onClick={handleOpenButtonClick}
            />
            <Modal
                open={modalOpen}
                title="Choose file"
                onClose={handleClose}
            >
                <Data onAttachmentSelected={handleAttachmentSelected} />
            </Modal>
        </div>
    );
};

AttachmentSelector.propTypes = {
    onAttachmentSelected: PropTypes.func.isRequired,
};

export default AttachmentSelector;
