import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import { Labeled, useInput } from 'react-admin';

import ContentImageItem from './content-image-item';

const ContentImagesInput = ({
    label = 'Expiration dates for embedded content images',
    ...props
}) => {
    const { input, meta } = useInput(props);

    if (!input.value) return null;
    if (input.value.length === 0) return null;

    return (
        <Labeled
            fullWidth
            label={label}
            meta={meta}
            input={input}
            resource={props.resource}
            source={props.source}
        >
            <Grid container spacing={1}>
                {
                    input.value.map((image) => (
                        <Grid key={image.id} item xs={12}>
                            <ContentImageItem image={image} />
                        </Grid>
                    ))
                }
            </Grid>
        </Labeled>
    );
};

ContentImagesInput.propTypes = {
    label: PropTypes.string,
    resource: PropTypes.string,
    source: PropTypes.string.isRequired,
};

export default ContentImagesInput;
