import React from 'react';

import { SimpleForm } from 'react-admin';

import { validate } from './validate';

import { CreateActions, MaxWidthCreate } from '@src/custom';
import Form from './Form';

const CreateRedirect = (props) => (
    <MaxWidthCreate
        title="Add Redirect"
        actions={<CreateActions />}
        {...props}
    >
        <SimpleForm
            redirect="edit"
            submitOnEnter={false}
            validate={validate}
        >
            <Form isCreate {...props} />
        </SimpleForm>
    </MaxWidthCreate>
);

export default CreateRedirect;
