export const source = {
    id: 'id',
    associationsCount: 'associations_count',
    createdAt: 'created_at',
    displayName: 'display_name',
    expiredAt: 'expired_at',
    filename: 'filename',
    resources: 'resources',
    thumbnail: 'thumbnail',
    thumbnailAlt: 'thumbnail.alt',
    thumbnailSource: 'thumbnail.source',
    updatedAt: 'updated_at',
};

export const resourceSource = {
    id: 'id',
    discardedAt: 'discarded_at',
    type: 'type',
    title: 'title',
};

export const attachOptions = [
    {
        id: 'attached',
        name: 'Attached',
    },
    {
        id: 'not_attached',
        name: 'Not Attached',
    },
];
