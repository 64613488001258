import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Checkbox, Select, MenuItem } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { callApi } from '@src/services/api/helpers';

import Result from './ImportResult';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 70,
        width: '100%',
    },
    card: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
}));

const title = (accessLevel) => {
    let scope = null;
    switch (accessLevel) {
    case 'admin':
        scope = 'Administrators';
        break;
    case 'home_office_employee':
        scope = 'Employee Users';
        break;
    default:
        scope = 'Users';
        break;
    }

    return `Import ${scope}`;
};

const ImportUsers = ({ accessLevel }) => {
    const classes = useStyles();

    const csvData = [
        ['Email', 'Employee ID', 'First Name', 'Last Name'],
        ['dev2@xzito.com', 'Z701111112', 'Xzito', 'Dev 2'],
    ];

    const [onlyTest, setOnlyTest] = React.useState(true);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [isImporting, setIsImporting] = React.useState(false);
    const [importResult, setImportResult] = React.useState(null);
    const [separator, setSeparator] = React.useState(',');

    const handleOnlyTestChange = React.useCallback((e) => {
        setOnlyTest(e.target.checked);
    }, [setOnlyTest]);

    const handleSeparatorChange = React.useCallback((e) => {
        setSeparator(e.target.value);
    }, [setSeparator]);

    const handleFileSelection = React.useCallback((e) => {
        if (!e && !e.target) {
            return;
        }

        setSelectedFile(e.target.files[0]);
    }, [setSelectedFile]);

    const handleSubmit = React.useCallback(() => {
        setIsImporting(true);

        const reader = new FileReader();

        reader.onload = () => {
            const csv = reader.result;
            return callApi('/users/csv', {
                method: 'POST',
                data: {
                    csv: {
                        content: csv,
                        // eslint-disable-next-line camelcase
                        access_level: accessLevel,
                        // eslint-disable-next-line camelcase
                        only_test: onlyTest,
                        separator,
                    },
                },
            }).then((res) => {
                setImportResult(res.data.import_results);
                setIsImporting(false);
            }).catch((e) => {
                setImportResult(`Error on users import: ${e.message}`);
                setIsImporting(false);
            });
        };

        reader.readAsText(selectedFile);
    }, [accessLevel, onlyTest, separator, selectedFile, setImportResult, setIsImporting]);

    return (
        <Grid container className={classes.root}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography
                        gutterBottom
                        component="h1"
                        display="inline"
                        variant="title"
                    >
                        { title(accessLevel) }
                    </Typography>
                    <FormControl className={classes.formControl}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={onlyTest} onChange={handleOnlyTestChange} />
                            }
                            label="Only test (no users will imported)"
                        />
                    </FormControl>
                    <Grid container>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                                <Button variant="contained" color="secondary" component="label">
                                    Select CSV File
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        accept="text/csv"
                                        onChange={handleFileSelection}
                                    />
                                </Button>
                                { selectedFile && <span>&nbsp;{ selectedFile.name }</span> }
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            Choose separator
                            <br />
                            <Select
                                fullWidth
                                value={separator}
                                onChange={handleSeparatorChange}
                            >
                                <MenuItem value=",">Comma (,)</MenuItem>
                                <MenuItem value=";">Semicolon (;)</MenuItem>
                                <MenuItem value={'\t'}>Tab</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={4}>
                            Import CSV Template
                            <br />
                            <CSVLink
                                data={csvData}
                                separator={separator}
                                filename="import-template.csv"
                                target="_blank"
                            >
                                Download
                            </CSVLink>
                        </Grid>
                    </Grid>
                    { importResult && <Result result={importResult} /> }
                </CardContent>
                <CardActions>
                    <Button
                        color="primary"
                        disabled={!selectedFile || isImporting}
                        onClick={handleSubmit}
                    >
                        Import
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    );
};

ImportUsers.propTypes = {
    accessLevel: PropTypes.oneOf(['admin', 'home_office_employee']),
    classes: PropTypes.object.isRequired,
};

export default React.memo(ImportUsers);
