import React from 'react';

import { Layout } from 'react-admin';

import MenuCustom from './MenuCustom';
import AppBar from './AppBarCustom';

const LayoutCustom = (props) => (
    <Layout
        {...props}
        appBar={AppBar}
        menu={MenuCustom}
    />
);

export default LayoutCustom;
