import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import get from 'lodash/get';
import { useInput } from 'react-admin';
import { useForm } from 'react-final-form';

import classnames from 'classnames';

import { source } from '../data';
import ImageUpload from './image-upload';

const useStyles = makeStyles({
    root: {
        position: 'relative',
    },
    img: {
        width: '100%',
    },
});

const LargeImagePreview = ({ className, record, ...props }) => {
    const classes = useStyles();

    const { input } = useInput(props);
    const { batch, change } = useForm();

    const handleImageUploaded = useCallback((imageData) => {
        batch(() => {
            change(source.source, imageData.source);
            change(source.format, imageData.format);
            change(source.width, imageData.width);
            change(source.height, imageData.height);
            change(source.bytes, imageData.bytes);
        });
    }, [batch, change]);

    if (!input.value) return null;

    const imgClasses = classnames(classes.img, className);

    return (
        <Card className={classes.root} variant="outlined">
            <ImageUpload
                folder={get(record, source.folder)}
                onImageUploaded={handleImageUploaded}
            >
                <CardActionArea>
                    <Tooltip title="Drop an image to replace, or click to select it">
                        <CardMedia
                            className={imgClasses}
                            component="img"
                            image={input.value}
                        />
                    </Tooltip>
                </CardActionArea>
            </ImageUpload>
        </Card>
    );
};

LargeImagePreview.propTypes = {
    className: PropTypes.string,
    record: PropTypes.object.isRequired,
};

export default LargeImagePreview;
