import React from 'react';

import { SimpleForm } from 'react-admin';

import { CreateActions, MaxWidthCreate } from '@src/custom';
import { source } from './data';
import Form from './Form';
import { validate } from './validate';

const CreateUnderwritingGuideline = (props) => {
    const initialValues = {
        [source.resourceType]: 'UnderwritingGuideline',
        [source.status]: 'draft',
        [source.publishedAt]: new Date(),
    };

    return (
        <MaxWidthCreate
            {...props}
            title="Create Underwriting Guideline"
            actions={<CreateActions />}
        >
            <SimpleForm
                initialValues={initialValues}
                redirect="edit"
                submitOnEnter={false}
                validate={validate}
            >
                <Form {...props} isCreate />
            </SimpleForm>
        </MaxWidthCreate>
    );
};

export default CreateUnderwritingGuideline;

