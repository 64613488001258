import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.error.main,
    },
}));

const AttachmentRemoveButton = (props) => {
    const { attachment, onRemove } = props;

    const classes = useStyles();

    const handleClick = useCallback(() => {
        onRemove(attachment);
    }, [onRemove, attachment]);

    return (
        <Button
            className={classes.root}
            label="Remove attachment"
            onClick={handleClick}
        >
            <DeleteIcon />
        </Button>
    );
};

AttachmentRemoveButton.propTypes = {
    attachment: PropTypes.object.isRequired,
    onRemove: PropTypes.func.isRequired,
};

export default AttachmentRemoveButton;
