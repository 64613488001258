const source = {
    attachment: 'attachment',
    createdAt: 'created_at',
    error: 'error',
    log: 'log',
    status: 'status',
    updatedAt: 'updated_at',
};

export { source };
