import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconCancel from '@material-ui/icons/Cancel';
import {
    Button,
    ReferenceInput,
    SaveButton,
    SelectInput,
    FormWithRedirect,
} from 'react-admin';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        justifyContent: 'flex-end',
    },
    button: {
        marginLeft: theme.spacing(2),
    },
}));

const userName = (user) => `${user.first_name} ${user.last_name} (${user.email})`;

const ReassignForm = (props) => {
    const { creating, onCancel, onSubmit } = props;

    const classes = useStyles();

    return (
        <FormWithRedirect
            initialValues={{}}
            resource="reassignment"
            save={onSubmit}
            render={
                ({
                    handleSubmitWithRedirect,
                    pristine,
                    saving,
                }) => (
                    <>
                        <Grid container>
                            <Grid item xs={12}>
                                <ReferenceInput
                                    required
                                    label="Reassign content to"
                                    source="new_owner_id"
                                    reference="admins"
                                >
                                    <SelectInput
                                        fullWidth
                                        emptyText="Select new admin"
                                        optionText={userName}
                                    />
                                </ReferenceInput>
                            </Grid>
                        </Grid>
                        <Toolbar disableGutters className={classes.toolbar}>
                            <Button
                                className={classes.button}
                                label="ra.action.cancel"
                                disabled={saving || creating}
                                onClick={onCancel}
                            >
                                <IconCancel />
                            </Button>
                            <SaveButton
                                className={classes.button}
                                handleSubmitWithRedirect={handleSubmitWithRedirect}
                                pristine={pristine}
                                saving={saving || creating}
                            />
                        </Toolbar>
                    </>
                )
            }
        />
    );
};

ReassignForm.propTypes = {
    creating: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default ReassignForm;
