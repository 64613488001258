import React from 'react';
import PropTypes from 'prop-types';

import {
    TextInput,
    NumberInput,
} from 'react-admin';

import Grid from '@material-ui/core/Grid';

import { source } from './data';

const CollateralCategoryForm = () => {
    return (
        <>
            <Grid container >
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        required
                        label="Name"
                        source={source.name}
                    />
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        label="Note"
                        source={source.note}
                    />
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={12}>
                    <NumberInput
                        fullWidth
                        min={0}
                        step={1}
                        label="Position"
                        source={source.position}
                    />
                </Grid>
            </Grid>
        </>
    );
};

CollateralCategoryForm.propTypes = {
    resource: PropTypes.string.isRequired,
    record: PropTypes.object,
};

export default CollateralCategoryForm;
