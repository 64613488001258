import React from 'react';
import PropTypes from 'prop-types';

import Collapse from '@material-ui/core/Collapse';
import { ExpandMore } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import {
    BooleanInput,
    TextInput,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';

import {
    source,
    resources,
    permissionsMatrix,
} from './data';

const useStyles = makeStyles((theme) => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    main: {
        paddingLeft: '16px',
        paddingBottom: '16px',
    },
    collapse: {
        width: '100%',
    },
    collapseHover: {
        cursor: 'pointer',
    },
    title: {
        fontSize: '18px',
        lineHeight: '2.5',
        fontWeight: 'bold',
    },
    expandIcon: {
        position: 'relative',
        float: 'right',
        top: '10px',
    },
    row: {
        borderTop: '1px solid #fff',
        alignText: 'center',
        backgroundColor: '#eee',
    },
    checkbox: {
        textAlign: 'right',
        display: 'block',
    },
}));

const RoleForm = (props) => {
    // eslint-disable-next-line no-unused-vars
    const { resource } = props;
    const classes = useStyles();
    const form = useForm();
    const [currentKey, setCurrentKey] = React.useState('home_articles');

    const handleClick = React.useCallback((key) => {
        setCurrentKey(key);
    }, [setCurrentKey]);

    const handleReadSwitched = React.useCallback((key, value) => {
        if (!value) {
            form.change(`${key}_delete`, false);
            form.change(`${key}_create`, false);
            form.change(`${key}_update`, false);
            form.change(`${key}_set_expiration`, false);
            form.change(`${key}_set_published`, false);
            form.change(`${key}_set_homepage`, false);
            form.change(`${key}_set_rss`, false);
        }
    }, [form]);

    const handleWriteSwitched = React.useCallback((key, value) => {
        if (value) {
            form.change(`${key}_read`, true);
        }
    }, [form]);

    return (
        <>
            <Grid container spacing={0.5}>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        required
                        label="Access Group"
                        source={source.name}
                    />
                </Grid>
            </Grid>
            <h2>Permission Access</h2>
            {
                Object.entries(resources).map(([key, value]) => {
                    const isCurrent = currentKey === key;
                    const classNames = isCurrent ? '' : classes.collapseHover;
                    const actions = permissionsMatrix[key];

                    return (
                        <Grid key={key} container className={classes.root}>
                            <Grid item xs={12} md={12} className={classNames} onClick={() => handleClick(key)}>
                                <a className={classes.title}>{value.toString()}</a>
                                { !isCurrent && <ExpandMore className={classes.expandIcon} /> }
                            </Grid>
                            <Collapse unmountOnExit className={classes.collapse} in={isCurrent} timeout="auto">
                                <Grid container xs={12} md={12} className={classes.main}>
                                    <Grid item xs={12} md={4} className={classes.row}>
                                        <b>Content Permission</b>
                                        <p>Let users read, edit, create and delete</p>
                                    </Grid>
                                    <Grid item xs={3} md={2} className={classes.row}>
                                        <BooleanInput
                                            label="Read"
                                            source={`${key}_read`}
                                            className={classes.checkbox}
                                            disabled={!(actions.indexOf('read') > -1)}
                                            onChange={(v) => handleReadSwitched(key, v)}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={2} className={classes.row}>
                                        <BooleanInput
                                            label="Delete"
                                            source={`${key}_delete`}
                                            className={classes.checkbox}
                                            disabled={!(actions.indexOf('delete') > -1)}
                                            onChange={(v) => handleWriteSwitched(key, v)}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={2} className={classes.row}>
                                        <BooleanInput
                                            label="Create"
                                            source={`${key}_create`}
                                            className={classes.checkbox}
                                            disabled={!(actions.indexOf('create') > -1)}
                                            onChange={(v) => handleWriteSwitched(key, v)}
                                        />
                                    </Grid>

                                    <Grid item xs={3} md={2} className={classes.row}>
                                        <BooleanInput
                                            label="Edit"
                                            source={`${key}_update`}
                                            className={classes.checkbox}
                                            disabled={!(actions.indexOf('update') > -1)}
                                            onChange={(v) => handleWriteSwitched(key, v)}
                                        />
                                    </Grid>
                                    {
                                        actions.indexOf('set_expiration') > -1 &&
                                        <>
                                            <Grid item xs={12} md={6} className={classes.row}>
                                                <b>Set Expiration Date</b>
                                                <p>Let users update expiration date</p>
                                            </Grid>
                                            <Grid item xs={12} md={6} className={classes.row}>
                                                <BooleanInput
                                                    label={false}
                                                    source={`${key}_set_expiration`}
                                                    className={classes.checkbox}
                                                    onChange={(v) => handleWriteSwitched(key, v)}
                                                />
                                            </Grid>
                                        </>
                                    }
                                    {
                                        actions.indexOf('set_published') > -1 &&
                                        <>
                                            <Grid item xs={12} md={6} className={classes.row}>
                                                <b>Modify Publication Date</b>
                                                <p>Let users update publication date</p>
                                            </Grid>
                                            <Grid item xs={12} md={6} className={classes.row}>
                                                <BooleanInput
                                                    label=""
                                                    source={`${key}_set_published`}
                                                    className={classes.checkbox}
                                                    onChange={(v) => handleWriteSwitched(key, v)}
                                                />
                                            </Grid>
                                        </>
                                    }
                                    {
                                        actions.indexOf('set_homepage') > -1 &&
                                        <>
                                            <Grid item xs={12} md={6} className={classes.row}>
                                                <b>Add to home page</b>
                                                <p>Let users add article to home page</p>
                                            </Grid>
                                            <Grid item xs={12} md={6} className={classes.row}>
                                                <BooleanInput
                                                    label=""
                                                    source={`${key}_set_homepage`}
                                                    className={classes.checkbox}
                                                    onChange={(v) => handleWriteSwitched(key, v)}
                                                />
                                            </Grid>
                                        </>
                                    }
                                    {
                                        actions.indexOf('set_rss') > -1 &&
                                        <>
                                            <Grid item xs={12} md={6} className={classes.row}>
                                                <b>Add to RSS feed</b>
                                                <p>Let users add to RSS feed</p>
                                            </Grid>
                                            <Grid item xs={12} md={6} className={classes.row}>
                                                <BooleanInput
                                                    label=""
                                                    source={`${key}_set_rss`}
                                                    className={classes.checkbox}
                                                    onChange={(v) => handleWriteSwitched(key, v)}
                                                />
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </Collapse>
                        </Grid>
                    );
                })
            }
        </>
    );
};

RoleForm.propTypes = {
    isCreate: PropTypes.bool,
    resource: PropTypes.string.isRequired,
};

export default RoleForm;
