import {
    mapAttachmentsForOne,
    mapAuthorForOne,
    mapFeedItem,
    mapProductsForList,
    mapProductsForOne,
    mapSalesChannelsForOne,
    mapStatesForList,
    mapStatesForOne,
} from './common';

function mapCategoriesforOne(responseData) {
    return {
        categories: responseData.categories.map((c) => c.slug),
    };
}

function mapCategoriesForList(data) {
    return {
        categories: data.categories.map((category) => ({
            id: category.slug,
            name: category.name,
        })),
    };
}

function mapGetOneFieldManagementNewsData(responseData) {
    return {
        ...responseData,
        ...mapCategoriesforOne(responseData),
        ...mapSalesChannelsForOne(responseData),
        ...mapProductsForOne(responseData),
        ...mapStatesForOne(responseData),
        ...mapFeedItem(responseData),
        ...mapAttachmentsForOne(responseData),
        ...mapAuthorForOne(responseData),
    };
}

function mapGetListFieldManagementNewsData(responseData) {
    return responseData.map((data) => ({
        ...data,
        ...mapCategoriesForList(data),
        ...mapProductsForList(data),
        ...mapStatesForList(data),
    }));
}

export { mapGetOneFieldManagementNewsData, mapGetListFieldManagementNewsData };
