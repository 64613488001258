import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useInput, useNotify } from 'react-admin';

import { uploadFile } from '@src/services/api/attachments';

import Dropzone from '@src/custom/attachment-input/attachment-dropzone';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    backdrop: {
        color: '#fff',
        zIndex: theme.zIndex.drawer + 1,
        position: 'absolute',
    },
}));

const ReplaceFileInput = (props) => {
    const { accept, ...restProps } = props;

    const { input } = useInput(restProps);
    const [uploading, setUploading] = useState(false);
    const notify = useNotify();
    const classes = useStyles();

    const handleFileSelection = useCallback((selectedFiles) => {
        setUploading(true);
        const file = selectedFiles[0];

        uploadFile(file)
            .then(() => {
                input.onChange(file.name);
                input.onBlur();
                setUploading(false);
            }).catch((e) => {
                notify(`Error on uploading files: ${e.message}`, 'error');
                setUploading(false);
            });
    }, [setUploading, input, notify]);

    return (
        <div className={classes.root}>
            <Dropzone
                accept={accept}
                multiple={false}
                title="To replace this file, drop a file here or click to browse."
                onFileSelected={handleFileSelection}
            />
            <Backdrop className={classes.backdrop} open={uploading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

ReplaceFileInput.propTypes = {
    accept: PropTypes.string,
};

export default ReplaceFileInput;
