import {
    cleanCommonParams,
} from './common';

const mapReassignmentParams = (
    isCreate,
    {
        ...params
    },
) => {
    return new Promise((resolve) => {
        resolve({
            reassignment: {
                ...cleanCommonParams(params),
            },
        });
    });
};

export { mapReassignmentParams };
