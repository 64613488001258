import React from 'react';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Button } from 'react-admin';
import { useFormState } from 'react-final-form';

import { frontendAttachmentPathname } from '@src/custom/attachment-input/utils';
import { useCopyStringToClipboard } from '@src/hooks/use-copy-string-to-clipboard';
import { frontendUrl } from '@src/services/api/helpers';

const CopyURLButtonInput = () => {
    const { values: attachment } = useFormState();

    const copyCallback = useCopyStringToClipboard(frontendUrl(frontendAttachmentPathname(attachment)));

    if (!attachment || !attachment.id) return null;

    return (
        <Button
            label="Copy URL"
            onClick={copyCallback}
        >
            <FileCopyIcon />
        </Button>
    );
};

export default React.memo(CopyURLButtonInput);
