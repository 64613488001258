import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useNotify } from 'react-admin';
import { apiPost, apiGet } from '@src/services/api/helpers';

import { Permissions } from '@src/global/permissions';

import styles from './sessions_setting.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 70,
    },
    formControl: {
        width: '100%',
        minWidth: 120,
        paddingTop: 10,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const SessionsSettingEdit = () => {
    const notify = useNotify();
    const classes = useStyles();

    const [isLoading, setLoading] = React.useState(true);
    const [agentsTimeout, setAgentsTimeout] = React.useState('14400');
    const [adminsTimeout, setAdminsTimeout] = React.useState('1800');
    const [employeesTimeout, setEmployeesTimeout] = React.useState('14400');
    const [logoutTimer, setLogoutTimer] = React.useState('180');

    React.useEffect(() => {
        apiGet('/settings/session_timeouts').then((response) => {
            const values = response.data.sessions_setting.setting_values;

            setAgentsTimeout(values.agents_timeout_seconds);
            setAdminsTimeout(values.admins_timeout_seconds);
            setEmployeesTimeout(values.employees_timeout_seconds);
            setLogoutTimer(values.logout_timer_seconds);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }, [setLoading]);

    const handleAgentsTimeoutChange = React.useCallback((e) => {
        setAgentsTimeout(e.target.value);
    }, [setAgentsTimeout]);

    const handleAdminsTimeoutChange = React.useCallback((e) => {
        setAdminsTimeout(e.target.value);
    }, [setAdminsTimeout]);

    const handleEmployeesTimeoutChange = React.useCallback((e) => {
        setEmployeesTimeout(e.target.value);
    }, [setEmployeesTimeout]);

    const handleLogoutTimerChange = React.useCallback((e) => {
        setLogoutTimer(e.target.value);
    }, [setLogoutTimer]);

    const handleSubmit = React.useCallback(() => {
        return apiPost('/settings/session_timeouts', {
            // eslint-disable-next-line camelcase
            sessions_setting: {
                // eslint-disable-next-line camelcase
                agents_timeout_seconds: agentsTimeout,
                // eslint-disable-next-line camelcase
                admins_timeout_seconds: adminsTimeout,
                // eslint-disable-next-line camelcase
                employees_timeout_seconds: employeesTimeout,
                // eslint-disable-next-line camelcase
                logout_timer_seconds: logoutTimer,
            },
        }).then((_res) => {
            notify('Successfully updated.');
        }).catch((error) => {
            notify(`Error: ${error.message}`, 'error');
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }, [agentsTimeout, adminsTimeout, employeesTimeout, logoutTimer, notify]);

    return (
        <Grid container className={classes.root}>
            <Card >
                <CardContent>{
                    isLoading ? <p>Loading ...</p> : (
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography
                                    gutterBottom
                                    component="h2"
                                    display="inline"
                                    variant="title"
                                >
                                    Sessions
                                </Typography>
                                <p className="MuiTypography-body2">
                                    Security by managing user session inactivity. Specify idle timeout periods for different user types by adjusting the settings below to automatically log users out after a period of inactivity.
                                </p>
                            </Grid>
                            <Grid item xs={4}>
                                <strong>Session Timeout for Agents</strong>
                                <br />
                                <FormControl variant="filled" className={classes.formControl}>
                                    <InputLabel
                                        shrink
                                        required
                                        id="session-timeouts-for-agents-label"
                                        className={styles.select_label}
                                    >
                                        Select timeout
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="session-timeouts-for-agents-label"
                                        value={agentsTimeout}
                                        disabled={Permissions('settings', 'update') !== true}
                                        onChange={handleAgentsTimeoutChange}
                                    >
                                        <MenuItem value="900">15 minutes</MenuItem>
                                        <MenuItem value="1800">30 minutes</MenuItem>
                                        <MenuItem value="2700">45 minutes</MenuItem>
                                        <MenuItem value="3600">60 minutes</MenuItem>
                                        <MenuItem value="5400">90 minutes</MenuItem>
                                        <MenuItem value="7200">120 minutes</MenuItem>
                                        <MenuItem value="10800">180 minutes</MenuItem>
                                        <MenuItem value="14400">240 minutes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <strong>Session Timeout for Admins</strong>
                                <br />
                                <FormControl variant="filled" className={classes.formControl}>
                                    <InputLabel
                                        shrink
                                        required
                                        id="session-timeouts-for-admins-label"
                                        className={styles.select_label}
                                    >
                                        Select timeout
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="session-timeouts-for-admins-label"
                                        value={adminsTimeout}
                                        disabled={Permissions('settings', 'update') !== true}
                                        onChange={handleAdminsTimeoutChange}
                                    >
                                        <MenuItem value="900">15 minutes</MenuItem>
                                        <MenuItem value="1800">30 minutes</MenuItem>
                                        <MenuItem value="2700">45 minutes</MenuItem>
                                        <MenuItem value="3600">60 minutes</MenuItem>
                                        <MenuItem value="5400">90 minutes</MenuItem>
                                        <MenuItem value="7200">120 minutes</MenuItem>
                                        <MenuItem value="10800">180 minutes</MenuItem>
                                        <MenuItem value="14400">240 minutes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <strong>Session Timeout for Employees</strong>
                                <br />
                                <FormControl variant="filled" className={classes.formControl}>
                                    <InputLabel
                                        shrink
                                        required
                                        id="session-timeouts-for-employees-label"
                                        className={styles.select_label}
                                    >
                                        Select timeout
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="session-timeouts-for-employees-label"
                                        value={employeesTimeout}
                                        disabled={Permissions('settings', 'update') !== true}
                                        onChange={handleEmployeesTimeoutChange}
                                    >
                                        <MenuItem value="900">15 minutes</MenuItem>
                                        <MenuItem value="1800">30 minutes</MenuItem>
                                        <MenuItem value="2700">45 minutes</MenuItem>
                                        <MenuItem value="3600">60 minutes</MenuItem>
                                        <MenuItem value="5400">90 minutes</MenuItem>
                                        <MenuItem value="7200">120 minutes</MenuItem>
                                        <MenuItem value="10800">180 minutes</MenuItem>
                                        <MenuItem value="14400">240 minutes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className={styles.my_3}>
                                <Typography
                                    gutterBottom
                                    component="h2"
                                    display="inline"
                                    variant="title"
                                >
                                    Logout Timer
                                </Typography>
                                <p className="MuiTypography-body2">
                                    Set the duration, in seconds, for the idle session notification countdown timer. Users will be prompted to choose whether to continue their session or log out when this timer reaches zero.
                                </p>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="filled" className={classes.formControl}>
                                    <InputLabel
                                        shrink
                                        required
                                        id="logout-timer-label"
                                        className={styles.select_label}
                                        disabled={Permissions('settings', 'update') !== true}
                                    >
                                        Time
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="logout-timer-label"
                                        value={logoutTimer}
                                        disabled={Permissions('settings', 'update') !== true}
                                        onChange={handleLogoutTimerChange}
                                    >
                                        <MenuItem value="30">30 seconds</MenuItem>
                                        <MenuItem value="60">60 seconds</MenuItem>
                                        <MenuItem value="90">90 seconds</MenuItem>
                                        <MenuItem value="120">120 seconds</MenuItem>
                                        <MenuItem value="180">180 seconds</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    )
                }
                </CardContent>
                <CardActions className={styles.toolbar}>
                    {
                        Permissions('settings', 'update') === true &&
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    }
                </CardActions>
            </Card>
        </Grid>
    );
};

SessionsSettingEdit.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default React.memo(SessionsSettingEdit);
