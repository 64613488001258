import React from 'react';

import {
    Datagrid,
    DateField,
    EditButton,
    List,
    TextField,
} from 'react-admin';

import {
    BulkActionButtons,
    CapitalizedTextField,
    ExpirationDateField,
} from '@src/custom';

import { source } from './data';
import FilterForm from './filter-form';

const RSMAgent360List = (props) => (
    <List
        {...props}
        title="Agent360"
        sort={{ field: source.position, order: 'DESC' }}
        filters={<FilterForm />}
        exporter={false}
        bulkActionButtons={<BulkActionButtons {...props} />}
    >
        <Datagrid>
            <TextField
                label="Title"
                source={source.title}
                sortable={false}
            />
            <TextField
                label="Position"
                source={source.position}
                sortable={false}
            />
            <DateField
                label="Last Updated"
                source={source.updatedAt}
                sortable={false}
            />
            <CapitalizedTextField
                label="Status"
                source={source.status}
            />
            <ExpirationDateField
                label="Expiration Date"
                source={source.expiredAt}
            />
            <EditButton />
        </Datagrid>
    </List>
);

export default RSMAgent360List;
