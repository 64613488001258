import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { linkToRecord } from 'react-admin';

import { Permissions } from '@src/global/permissions';

import { classToResource } from '@src/utils/model-helpers';

const EditResourceButton = ({ record }) => {
    const resource = classToResource(record.record_type);
    const linkToResource = linkToRecord(`/${resource}`, record.record_id, 'edit');

    if (resource === 'social_sharings' || resource === 'field_management_news' || resource === 'articles') {
        return null;
    }

    if (Permissions(resource, 'update') === false) {
        return null;
    }

    return (
        <Button
            color="primary"
            endIcon={<OpenInNewIcon />}
            href={linkToResource}
            rel="noopener noreferrer"
            startIcon={<EditIcon />}
            target="_blank"
        >
            Edit
        </Button>
    );
};

EditResourceButton.propTypes = {
    record: PropTypes.shape({
        // eslint-disable-next-line camelcase
        record_type: PropTypes.string.isRequired,
        // eslint-disable-next-line camelcase
        record_id: PropTypes.number.isRequired,
    }).isRequired,
};

export default EditResourceButton;
