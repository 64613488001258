import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';

import { formatTimestampToDate } from '@src/utils/dateHelpers';

const ExpirationDateField = ({ record = {}, source, value }) => {
    const expiredAt = value ? value : get(record, source);

    if (!expiredAt) return null;

    const expiredAtDate = new Date(expiredAt);
    const color = expiredAtDate < new Date() ? 'error' : 'textPrimary';
    const dateString = formatTimestampToDate(expiredAtDate);

    return (
        <Typography component="span" variant="body2" color={color}>
            { dateString }
        </Typography>
    );
};

ExpirationDateField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string,
    value: PropTypes.string,
};

export default ExpirationDateField;
