import axios from 'axios';

import { apiPost, apiGet } from './helpers';

const getApiUploadURL = (file) => {
    return apiGet('/attachments/new', {
        params: { filename: file.name },
    }).then((response) => response.data.attachment.upload_url);
};

const uploadFileByURL = (uploadURL, file) => {
    return axios.put(
        uploadURL,
        file,
        {
            headers: {
                'Content-Type': file.type,
            },
        }
    );
};

const uploadFile = (file) => {
    return getApiUploadURL(file)
        .then((uploadURL) => uploadFileByURL(uploadURL, file));
};

const createAttachment = (attachmentData) => {
    return apiPost('/attachments', { attachment: attachmentData })
        .then((response) => response.data.attachment);
};

const uploadAndCreateAttachment = ({ file, ...attachmentData }) => {
    return uploadFile(file).then(() => createAttachment(attachmentData));
};

export {
    uploadFile,
    createAttachment,
    uploadAndCreateAttachment,
};
