import React from 'react';
import PropTypes from 'prop-types';

import {
    BooleanInput,
    DateInput,
    DateTimeInput,
    ReferenceArrayInput,
    SelectInput,
    TextInput,
} from 'react-admin';

import Grid from '@material-ui/core/Grid';

import { FirstRow, SecondRow } from '@src/components/FormHeader';

import {
    ContentImagesInput,
    AddToHomepageField,
    ExpirationDateInput,
    PublicationDateInput,
    FeedInputs,
    ImageInput,
    ProductStatesSelect,
    RichTextInput,
    RoleTypeInput,
    StyledSelectArrayInput,
    TitleSlug,
} from '@src/custom';

import { status } from '@src/global/choices';
import { source } from './data';
import { useForm, useFormState } from 'react-final-form';

import { addHours, roundToNearestMinutes } from 'date-fns';

import { helperText } from '@src/global/helperText';

const WebinarForm = (props) => {
    const { isCreate, resource } = props;
    const form = useForm();
    const { dirtyFields, values } = useFormState();

    const handleOnChange = React.useCallback((e) => {
        // eslint-disable-next-line prefer-destructuring
        const value = e.currentTarget.value;
        if (dirtyFields[source.startsAt] || values[source.startsAt]) {
            form.change(source.startsAt, new Date(value));
        } else {
            form.change(source.startsAt, roundToNearestMinutes(new Date(value), { nearestTo: 15 }));
            form.change(source.endsAt, addHours(roundToNearestMinutes(new Date(value), { nearestTo: 15 }), 1));
        }
    }, [dirtyFields, form.change, values]);

    return (
        <>
            <FirstRow key="first-row" {...props} source={source}>
                <ExpirationDateInput resource={resource} source={source} />
                <PublicationDateInput resource={resource} source={source} />
                <SelectInput
                    source={source.status}
                    choices={status}
                />
            </FirstRow>
            <SecondRow key="second-row">
                <RoleTypeInput displayLeft source={source.roleType} />
                <AddToHomepageField resource={resource} source={source} />
                <FeedInputs resource={resource} />
            </SecondRow>
            <TitleSlug
                required
                isCreate={isCreate}
                source={source}
                pageUrlPart="training-and-job-aids/webinars"
            />

            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        required
                        label="Description"
                        source={source.description}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        label="Internal Note"
                        source={source.note}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <RichTextInput
                        label="Content"
                        source={source.content}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DateInput
                        label="Content Expiration Date"
                        source={source.contentExpiredAt}
                    />
                </Grid>
                <Grid item md xs={12}>
                    <ContentImagesInput
                        label="Expiration dates for embedded content images"
                        source={source.contentImages}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        required
                        label="External Webinar Registration URL"
                        source={source.externalUrl}
                        helperText={helperText.URL}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={6}>
                    <DateTimeInput
                        fullWidth
                        required
                        source={source.startsAt}
                        label="Start Date / Time"
                        helperText={helperText.TIMEZONE}
                        onChange={handleOnChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DateTimeInput
                        fullWidth
                        required
                        source={source.endsAt}
                        helperText={helperText.TIMEZONE}
                        label="End Date / Time"
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <ImageInput source={source.image} />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <BooleanInput
                        label="This webinar is a Reimagine Foremost Virtual Events"
                        source={source.isTake20}
                    />
                </Grid>
            </Grid>

            {
                /*
            <Grid container>
                <Grid item xs={12}>
                    <ReferenceArrayInput
                        required
                        label="Sales &amp; Marketing Category"
                        source={source.salesAndMarketingCategories}
                        reference={source.salesAndMarketingCategoriesReference}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
            </Grid>
                */
            }

            <Grid container>
                <Grid item xs={12}>
                    <ReferenceArrayInput
                        required
                        label="System Category"
                        source={source.systemCategories}
                        reference={source.systemCategoriesReference}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <ReferenceArrayInput
                        required
                        label="Product Category"
                        source={source.productCategories}
                        reference={source.productCategoriesReference}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <ReferenceArrayInput
                        required
                        label="Sales Channel"
                        source={source.salesChannels}
                        reference={source.salesChannelsReference}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={6}>
                    <ReferenceArrayInput
                        required
                        label="Applicable Products"
                        reference={source.productsReference}
                        source={source.products}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth groupBy="business_unit_name" />
                    </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ProductStatesSelect
                        fullWidth
                        label="Applicable States"
                        source={source.states}
                        productsSource={source.products}
                        resource={resource}
                    />
                </Grid>
            </Grid>
        </>
    );
};

WebinarForm.propTypes = {
    isCreate: PropTypes.bool,
    resource: PropTypes.string.isRequired,
};

export default WebinarForm;
