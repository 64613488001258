const colors = {
    primary: [
        {
            id: 'black',
            cssClass: 'ml-black',
            color: '000000',
        },
        {
            id: 'white',
            cssClass: 'ml-white',
            color: 'ffffff',
        },
        {
            id: 'metLifeBlue1',
            cssClass: 'ml-blue-1',
            color: '0090da',
        },
        {
            id: 'metLifeBlue2',
            cssClass: 'ml-blue-2',
            color: '007abc',
        },
        {
            id: 'metLifeDarkBlue',
            cssClass: 'ml-dark-blue',
            color: '0061a0',
        },
        {
            id: 'metLifeGreen',
            cssClass: 'ml-green',
            color: 'a4ce4e',
        },
    ],
    secondary: [
        {
            id: 'metLifeDarkestGray',
            cssClass: 'ml-darkest-gray',
            color: '333333',
        },
        {
            id: 'metLifeDarkGray',
            cssClass: 'ml-dark-gray',
            color: '75787b',
        },
        {
            id: 'metLifeMediumGray',
            cssClass: 'ml-medium-gray',
            color: 'a7a8aa',
        },
        {
            id: 'metLifeGray',
            cssClass: 'ml-gray',
            color: 'd9d9d6',
        },
        {
            id: 'metLifeLightGray',
            cssClass: 'ml-light-gray',
            color: 'f2f2f2',
        },
        {
            id: 'metLifeBerry',
            cssClass: 'ml-berry',
            color: 'db0a5b',
        },
        {
            id: 'metLifePurple',
            cssClass: 'ml-purple',
            color: '5f259f',
        },
        {
            id: 'metLifeYellow',
            cssClass: 'ml-yellow',
            color: 'ffc600',
        },
        {
            id: 'metLifeTeal',
            cssClass: 'ml-teal',
            color: '00aca0',
        },
        {
            id: 'metLifeAlertRed',
            cssClass: 'ml-alert-red',
            color: 'db1818',
        },
    ],
};

export const getColorOptions = () => {
    let formattedColorOptions = [];
    for (const key in colors) {
        if (Object.prototype.hasOwnProperty.call(colors, key)) {
            Array.prototype.push.apply(formattedColorOptions, colors[key].map((color) => {
                return `${color.cssClass}/${color.color}`;
            }));
        }
    }
    return formattedColorOptions.toString();
};
