const source = {
    firstName: 'first_name',
    lastName: 'last_name',
    requesterEmail: 'requester_email',
    foremostProducerCode: 'foremost_producer_code',
    bristolProducerCode: 'bristol_producer_code',
    status: 'status',
    stateId: 'state_id',
    state: 'state',
    termsAccepted: 'terms_accepted',
    createdAt: 'created_at',
    updatedAt: 'updated_at',
    requestedAt: 'requested_at',
    completedAt: 'completed_at',
    generating: 'generating',
    brands: 'brands',
    coBrandedVideoRequestBrands: 'co_branded_video_request_brands',
    coBrandedVideoTemplateIds: 'co_branded_video_template_ids',
};

const requestStatuses = [
    {
        id: 'created',
        name: 'New',
    },
    {
        id: 'pending',
        name: 'Pending',
    },
    {
        id: 'approved',
        name: 'Approved',
    },
    {
        id: 'rejected',
        name: 'Rejected',
    },
];

const requestLogoIncludedOptions = [
    {
        id: true,
        name: 'Yes, I want to include my agency logo',
    },
    {
        id: false,
        name: 'No',
    },
];

const requestShowFontOptions = [
    {
        id: true,
        name: 'Show',
    },
    {
        id: false,
        name: 'Hide',
    },
];

const approvalEmailText = '<p>Hello [NAME],</p>' +
    '<p>Thanks again for requesting a Foremost and/or Bristol West co-branded video! Your co-branded videos are ready! Follow the links to access your videos:</p>' +
    '[AUTO INSERT LINKS]' +
    '<p>After clicking the link, hit the download button to save the file to your computer. Then share with your customers on your agency\'s website, social media or as a digital advertisement in your office.</p>' +
    '<p>If video isn\'t typically included in your marketing mix, we\'ve <a target="_blank" href="https://www.socialmediasuitcase.com/pdf/Effectively-Using-CoBranded-Videos.pdf">attached a job aid</a> with a few ideas on how to make the most of your co-branded content. Check it out!</p>' +
    '<p>Please let us know if you have any questions.</p>' +
    '<p>Thanks again,</p>' +
    '<p>The Foremost Marketing Team<br />' +
    '<a href="https://foremostagent.com/marketing">www.ForemostAgent.com/Marketing</a></p>' +
    '<p>(P.S. For more great content from Foremost, check out the <a target="_blank" href="https://www.socialmediasuitcase.com/">Social Media Suitcase</a> and follow us on ' +
    '<a target="_blank" href="https://www.facebook.com/TheForemostInsuranceGuy">Facebook</a>, ' +
    '<a target="_blank" href="https://www.instagram.com/foremostinsurance">Instagram</a>, ' +
    '<a target="_blank" href="https://twitter.com/foremost">Twitter</a>, ' +
    '<a target="_blank" href="https://www.linkedin.com/company/foremost-insurance">LinkedIn</a> and ' +
    '<a target="_blank" href="https://youtube.com/user/foremostinsurance">YouTube</a>!)</p>';

const invalidProducerCodeEmailText = '<p>Hello [NAME],</p>' +
    '<p>Thank you for your interest in Foremost and/or Bristol West co-branded videos. Unfortunately, according to the producer code you submitted, it appears you may not be appointed with Foremost or Bristol West. Please provide a different producer code if you have one.</p>' +
    '<p>If you\'re not appointed with Foremost or Bristol West, please visit <a href="https://foremostagent.com/get-appointed">ForemostAgent.com/get-appointed</a> to get started.</p>' +
    '<p>Thanks again,</p>' +
    '<p>The Foremost Marketing Team</p>';

export {
    source,
    requestStatuses,
    requestLogoIncludedOptions,
    requestShowFontOptions,
    approvalEmailText,
    invalidProducerCodeEmailText,
};
