import React from 'react';

import {
    Filter,
    SearchInput,
} from 'react-admin';

const PageExpirationsFilter = (props) => (
    <Filter {...props}>
        <SearchInput alwaysOn source="query" />
    </Filter>
);

export default PageExpirationsFilter;
