import React from 'react';

import {
    Datagrid,
    List,
    TextField,
    DateField,
    ReferenceField,
    FunctionField,
    ArrayField,
} from 'react-admin';

import { Permissions } from '@src/global/permissions';

import {
    BulkActionButtons,
    EditButtonCustom,
    SingleFieldListCustom,
} from '@src/custom';

import { source } from './data';

const CoBrandedVideoLogosList = (props) => (
    <List
        {...props}
        title="Co-branded Logos"
        sort={{ field: source.updatedAt, order: 'DESC' }}
        hasCreate={Permissions('co_branded_videos', 'create') === true}
        bulkActionButtons={<BulkActionButtons resource="co_branded_videos" {...props} />}
    >
        <Datagrid>
            <FunctionField
                label="Preview"
                source={source.image}
                render={(record) => {
                    if (!record.image) {
                        return null;
                    }

                    return (
                        <img src={`${record.image.source}`} width="140" />
                    );
                }}
            />
            <TextField
                label="Logo"
                source={source.name}
            />
            <ReferenceField
                label="Brand"
                source={source.brandId}
                reference="brands"
                link={false}
            >
                <TextField source="name" />
            </ReferenceField>
            <ArrayField
                label="Available States"
                source={source.states}
                sortable={false}
            >
                <SingleFieldListCustom linkType={false}>
                    <TextField source="name" />
                </SingleFieldListCustom>
            </ArrayField>
            <DateField
                label="Upload Date"
                source={source.createdAt}
            />
            <EditButtonCustom resource="co_branded_videos" {...props} />
        </Datagrid>
    </List>
);

export default CoBrandedVideoLogosList;
