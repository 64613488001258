import get from 'lodash/get';
import set from 'lodash/set';

import { source } from './data';

function validatePublished(values, errors) {
    if (!get(values, source.content)) {
        set(errors, source.content, ['A Content is required']);
    }

    if (!values[source.publishedAt]) {
        errors[source.publishedAt] = ['A Publication Date is required'];
    }
}

export const validate = (values) => {
    const errors = {};

    if (!get(values, source.title)) {
        set(errors, source.title, ['A Title is required']);
    }

    if (values[source.status] === 'published') {
        validatePublished(values, errors);
    }

    return errors;
};

