import { source } from './data';

function hasProductOrState(values) {
    return (
        (values[source.products] && values[source.products].length > 0) ||
        (values[source.states] && values[source.states].length > 0)
    );
}

function validatePublished(values, errors) {
    if (!values[source.effectiveFrom]) {
        errors[source.effectiveFrom] = ['An Effective From date is required'];
    }

    if (!values[source.title]) {
        errors[source.title] = ['A Title is required'];
    }

    if (!values[source.category]) {
        errors[source.category] = ['A Category is required'];
    }

    if (!values[source.salesChannels] || values[source.salesChannels].length <= 0) {
        errors[source.salesChannels] = ['A Sales Channel is required'];
    }

    if (!hasProductOrState(values)) {
        errors[source.products] = ['A Product or State is required'];
        errors[source.states] = ['A Product or State is required'];
    }
}

export function validate(values) {
    const errors = {};

    if (!values[source.slug]) {
        errors[source.slug] = ['An URL is required'];
    }

    if (values[source.effectiveFrom] && values[source.effectiveUntil] &&
        values[source.effectiveUntil] < values[source.effectiveFrom]) {
        errors[source.effectiveUntil] =
            ['An Effective Until date could not be earlier then the Effective From date'];
    }

    if (values[source.effectiveUntil] && values[source.archivedAt] &&
        values[source.archivedAt] <= values[source.effectiveUntil]) {
        errors[source.archivedAt] =
            ['An Archived At date could not be earlier then or equal to the Effective Until date'];
    }

    if (values[source.status] !== 'draft') {
        validatePublished(values, errors);
    }

    return errors;
}
