import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';

import ExpirationDate from '@src/custom/expiration-date';
import CopyImageURLButton from './copy-image-url-button';

/* eslint-disable quote-props */
const useStyles = makeStyles({
    item: {
        display: 'flext',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        transition: 'transform .2s',
        '&:hover': {
            transform: 'scale(1.025)',
        },
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        '&:hover': {
            cursor: 'pointer',
        },
    },
    link: {
        wordWrap: 'break-word',
    },
});
/* eslint-enable */

const ImageChooserItem = (props) => {
    const { image, showCopy, onClick } = props;

    const classes = useStyles();

    const handleImageClick = useCallback((e) => {
        e.preventDefault();
        onClick(image);
    }, [onClick, image]);

    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className={classes.item}
        >
            <Card className={classes.card}>
                <CardMedia
                    className={classes.media}
                    height="140"
                    image={image.source}
                    title={image.alt}
                    onClick={handleImageClick}
                />
                <CardContent>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        className={classes.link}
                    >
                        {image.alt}
                    </Typography>
                    <ExpirationDate expiredAt={get(image, 'expired_at')} />
                </CardContent>
                {
                    showCopy &&
                        <CardActions>
                            <CopyImageURLButton image={image} />
                        </CardActions>
                }
            </Card>
        </Grid>
    );
};

ImageChooserItem.propTypes = {
    image: PropTypes.shape({
        id: PropTypes.number.isRequired,
        source: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
    }).isRequired,
    showCopy: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

export default React.memo(ImageChooserItem);
