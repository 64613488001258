const source = {
    name: 'name',
    note: 'note',
    position: 'position',
    brands: 'brands',
    brandsReference: 'brands',
    createdAt: 'created_at',
    updatedAt: 'updated_at',
};

export {
    source,
};
