export const source = {
    contactName: 'contact_name',
    agentCode: 'agent_code',
    additionalInformation: 'additional_information',
    agencyName: 'agency_name',
    claimNumber: 'claim_number',
    contactEmail: 'contact_email',
    contactNumber: 'contact_number',
    policyNumber: 'policy_number',
    rsmName: 'rsm_name',
    type: 'type',
    userId: 'user_id',
    createdAt: 'created_at',
};

export const formTypes = [
    { id: 'FormEntry::AgencyOfRecord', name: 'Agency of Record Change of Broker' },
    { id: 'FormEntry::AgentRealTime', name: 'Independent Agent Real Time Support' },
    { id: 'FormEntry::ClaimAssistance', name: 'Claim Assistance' },
    { id: 'FormEntry::CommissionSupport', name: 'Commission Support' },
    { id: 'FormEntry::FieldSalesAdministration', name: 'Field Sales Administration' },
    { id: 'FormEntry::GeneralRetailSales', name: 'General Retail Sales' },
    { id: 'FormEntry::PolicyFormProcessing', name: 'Policy Form Processing' },
    { id: 'FormEntry::ProducerNewsSupport', name: 'Producer News Support' },
    { id: 'FormEntry::UnderwritingSupport', name: 'UW Guideline Update Communication Request Form' },
    { id: 'FormEntry::QuoteInquiry', name: 'Quote Inquiry' },
    { id: 'FormEntry::AccessibilitySupport', name: 'Digital Accessibility Support' },
];

export const formFields = {
    'FormEntry::AgencyOfRecord': [
        {
            key: source.contactName,
            title: 'Name',
        },
        {
            key: 'agent_code',
            title: 'Agent ID',
        },
        {
            key: 'rsm_name',
            title: 'RSM Name',
        },
        {
            key: 'contact_number',
            title: 'Phone',
        },
        {
            key: 'policy_number',
            title: 'Policy Number',
        },
        {
            key: 'type_of_request',
            title: 'Type of request',
        },
        {
            key: 'contact_email',
            title: 'Email',
        },
        {
            key: 'additional_information',
            title: 'Additional Information',
        },
    ],
    'FormEntry::AgentRealTime': [
        {
            key: 'contact_name',
            title: 'Name',
        },
        {
            key: 'agent_code',
            title: 'Agent ID',
        },
        {
            key: 'rsm_name',
            title: 'RSM Name',
        },
        {
            key: 'contact_number',
            title: 'Phone',
        },
        {
            key: 'contact_email',
            title: 'Email',
        },
        {
            key: 'type_of_request',
            title: 'What is this regarding?',
        },
        {
            key: 'comparative_rater',
            title: 'What comparative rater are you using?',
        },
        {
            key: 'line_of_business',
            title: 'Line of business quoting',
        },
        {
            key: 'error_message',
            title: 'Error Message',
        },
        {
            key: 'additional_information',
            title: 'Additional Information',
        },
    ],
    'FormEntry::ClaimAssistance': [
        {
            key: 'contact_name',
            title: 'Name',
        },
        {
            key: 'agent_code',
            title: 'Agent ID',
        },
        {
            key: 'rsm_name',
            title: 'RSM Name',
        },
        {
            key: 'contact_number',
            title: 'Phone',
        },
        {
            key: 'contact_email',
            title: 'Email',
        },
        {
            key: 'claim_number',
            title: 'Claim Number',
        },
        {
            key: 'policy_number',
            title: 'Policy Number',
        },
        {
            key: 'type_of_request',
            title: 'Type of claim',
        },
        {
            key: 'additional_information',
            title: 'Additional Information',
        },
    ],
    'FormEntry::CommissionSupport': [
        {
            key: 'contact_name',
            title: 'Name',
        },
        {
            key: 'agent_code',
            title: 'Agent ID',
        },
        {
            key: 'rsm_name',
            title: 'RSM Name',
        },
        {
            key: 'contact_number',
            title: 'Phone',
        },
        {
            key: 'contact_email',
            title: 'Email',
        },
        {
            key: 'policy_number',
            title: 'Policy Number',
        },
        {
            key: 'type_of_request',
            title: 'Request Type',
        },
        {
            key: 'additional_information',
            title: 'Additional Information',
        },
    ],
    'FormEntry::FieldSalesAdministration': [
        {
            key: 'contact_name',
            title: 'Name',
        },
        {
            key: 'agent_code',
            title: 'Agent ID',
        },
        {
            key: 'rsm_name',
            title: 'RSM Name',
        },
        {
            key: 'contact_number',
            title: 'Phone',
        },
        {
            key: 'contact_email',
            title: 'Email',
        },
        {
            key: 'type_of_request',
            title: 'Request Type',
        },
        {
            key: 'additional_information',
            title: 'Additional Information',
        },
    ],
    'FormEntry::GeneralRetailSales': [
        {
            key: 'contact_name',
            title: 'Name',
        },
        {
            key: 'agent_code',
            title: 'Agent ID',
        },
        {
            key: 'rsm_name',
            title: 'RSM Name',
        },
        {
            key: 'agency_name',
            title: 'Agency Name',
        },
        {
            key: 'contact_number',
            title: 'Phone',
        },
        {
            key: 'contact_email',
            title: 'Email',
        },
        {
            key: 'topic',
            title: 'Topic/Questions',
        },
        {
            key: 'additional_information',
            title: 'Additional Information',
        },
    ],
    'FormEntry::PolicyFormProcessing': [
        {
            key: 'contact_name',
            title: 'Name',
        },
        {
            key: 'agent_code',
            title: 'Agent ID',
        },
        {
            key: 'rsm_name',
            title: 'RSM Name',
        },
        {
            key: 'contact_number',
            title: 'Phone',
        },
        {
            key: 'state',
            title: 'State',
        },
        {
            key: 'policy_number',
            title: 'Policy Number',
        },
        {
            key: 'contact_email',
            title: 'Email',
        },
        {
            key: 'additional_information',
            title: 'Additional Information',
        },
    ],
    'FormEntry::ProducerNewsSupport': [
        {
            key: 'contact_name',
            title: 'Name',
        },
        {
            key: 'agent_code',
            title: 'Agent ID',
        },
        {
            key: 'rsm_name',
            title: 'RSM Name',
        },
        {
            key: 'contact_number',
            title: 'Phone',
        },
        {
            key: 'contact_email',
            title: 'Email',
        },
        {
            key: 'additional_information',
            title: 'Additional Information',
        },
    ],
    'FormEntry::UnderwritingSupport': [
        {
            key: 'contact_name',
            title: 'Name',
        },
        {
            key: 'agent_code',
            title: 'Agent ID',
        },
        {
            key: 'rsm_name',
            title: 'RSM Name',
        },
        {
            key: 'contact_number',
            title: 'Phone',
        },
        {
            key: 'state',
            title: 'State',
        },
        {
            key: 'contact_email',
            title: 'Email',
        },
        {
            key: 'type_of_request',
            title: 'Type of request',
        },
        {
            key: 'region',
            title: 'Which region is this regarding?',
        },
        {
            key: 'additional_information',
            title: 'Additional Information',
        },
    ],
    'FormEntry::QuoteInquiry': [
        {
            key: 'contact_name',
            title: 'Your name',
        },
        {
            key: 'contact_email',
            title: 'Your email address',
        },
        {
            key: 'data.customer_name',
            title: 'Name',
        },
        {
            key: 'data.customer_address',
            title: 'Address',
        },
        {
            key: 'data.customer_city',
            title: 'City',
        },
        {
            key: 'data.customer_state',
            title: 'State',
        },
        {
            key: 'data.customer_zip_code',
            title: 'Zip code',
        },
        {
            key: 'data.customer_email',
            title: 'Email',
        },
        {
            key: 'data.customer_phone',
            title: 'Phone',
        },
        {
            key: 'data.contact_type',
            title: 'Who should we contact?',
        },
        {
            key: 'data.product_type',
            title: 'Product type',
        },
        {
            key: 'data.personal_products',
            title: 'Personal products',
        },
        {
            key: 'data.commercial_products',
            title: 'Commercial Products',
        },
        {
            key: 'additional_information',
            title: 'Comments',
        },
    ],
    'FormEntry::AccessibilitySupport': [
        {
            key: 'contact_name',
            title: 'Your name',
        },
        {
            key: 'contact_email',
            title: 'Your email address',
        },
        {
            key: 'additional_information',
            title: 'Comments',
        },
    ],
};
