import get from 'lodash/get';

const validateFeedChannels = (value, allValues) => {
    const enabled = get(allValues, 'feed_item.enabled', false);
    const channels = get(allValues, 'feed_item.channels', []);

    if (!value || !enabled || (channels && channels.length >= 1)) {
        return null;
    }

    return 'At least one feed channel is required';
};

export { validateFeedChannels };
