import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Error, useCreate, useNotify } from 'react-admin';

import Form from './reassign-form';

const Reassign = (props) => {
    const { userId, onCreated, onCancel } = props;

    const [create, { loading: creating, error }] = useCreate('reassignment');
    const notify = useNotify();

    const handleSubmit = useCallback((values) => {
        const data = {
            ...values,
            /* eslint-disable camelcase */
            target_type: 'User',
            target_id: userId,
            /* eslint-enable camelcase */
        };

        create(
            { payload: { data } },
            {
                onSuccess: ({ data }) => {
                    notify('Request complete. Please allow a few minutes for your request to process.');
                    onCreated(data);
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                },
            },
        );
    }, [userId, create, notify, onCreated]);

    if (error) return <Error error={error} errorInfo={{ componentStack: null }} />;

    return (
        <Form creating={creating} onCancel={onCancel} onSubmit={handleSubmit} />
    );
};

Reassign.propTypes = {
    userId: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
    onCreated: PropTypes.func.isRequired,
};

export default Reassign;
