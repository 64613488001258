import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import get from 'lodash/get';

import { uploadImage } from '@src/services/api/images';
import { formatBytes } from '@src/utils/number-helpers';

import Dropzone from './image-dropzone';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        color: '#fff',
        zIndex: theme.zIndex.drawer + 1,
        position: 'absolute',
    },
}));

const ImageUpload = (props) => {
    const { children, folder, onImageUploaded } = props;

    const classes = useStyles();

    const [error, setError] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const handleImageUploadError = useCallback((error) => {
        let errorMessage = error.messsage || 'Unknown error';

        const responseMessage = get(error, ['response', 'data', 'error', 'message']);
        if (responseMessage) {
            errorMessage = responseMessage.replace(
                /(\d+(?:((?=\.))\d*))/gm,
                (match) => formatBytes(match),
            );
        }

        setIsUploading(false);
        setError(errorMessage);
    }, [setIsUploading, setError]);

    const handleImageUploadSuccess = useCallback((imageData) => {
        setIsUploading(false);
        setError(null);

        onImageUploaded(imageData);
    }, [setIsUploading, setError, onImageUploaded]);

    const handleFileSelection = useCallback((imageFile) => {
        setIsUploading(true);
        uploadImage(imageFile, folder)
            .then((imageData) => {
                handleImageUploadSuccess(imageData);
            }).catch((error) => {
                handleImageUploadError(error);
            });
    }, [folder, setIsUploading, handleImageUploadSuccess, handleImageUploadError]);

    const handleAlertClose = useCallback(() => {
        setError(null);
    }, [setError]);

    if (isUploading) {
        return (
            <React.Fragment>
                <Backdrop open className={classes.backdrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                { children }
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Dropzone onFileSelected={handleFileSelection}>
                { children }
            </Dropzone>
            {
                <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={handleAlertClose}>
                    <Alert severity="error" onClose={handleAlertClose} >
                        { error }
                    </Alert>
                </Snackbar>
            }
        </React.Fragment>
    );
};

ImageUpload.propTypes = {
    children: PropTypes.node,
    folder: PropTypes.string.isRequired,
    onImageUploaded: PropTypes.func.isRequired,
};

export default ImageUpload;
