const RSMTraining = [
    {
        id: 'monthly_training_call',
        name: 'Monthly training call',
    },
    {
        id: 'prelaunch_training_material',
        name: 'Pre-launch training material',
    },
];

export default RSMTraining;
