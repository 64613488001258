import {
    mapBrandsForOne,
    mapBrandsForList,
} from './common';

function mapGetOneCoBrandedVideoCategoryData(responseData) {
    return {
        ...responseData,
        ...mapBrandsForOne(responseData),
    };
}

function mapGetListCoBrandedVideoCategoriesData(responseData) {
    return responseData.map((data) => ({
        ...data,
        ...mapBrandsForList(data),
    }));
}

export { mapGetOneCoBrandedVideoCategoryData, mapGetListCoBrandedVideoCategoriesData };
