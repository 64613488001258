import React from 'react';
import PropTypes from 'prop-types';

import { BulkDeleteButton } from 'react-admin';

import { Permissions } from '@src/global/permissions';

const BulkActionButtons = (props) => {
    const { resource } = props;

    let res = resource;
    if (res === 'admins' || res === 'employees' || res === 'agents') {
        res = 'users';
    }

    if (res === 'contact_forms') {
        res = 'form_entries';
    }

    if (Permissions(res, 'delete') === false) {
        return null;
    }

    return (
        <BulkDeleteButton {...props} undoable={false} />
    );
};

BulkActionButtons.propTypes = {
    resource: PropTypes.string,
};

export default BulkActionButtons;
