import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
    DateField,
    SimpleShowLayout,
    TextField,
    SelectField,
    ShowView,
    useShowController,
} from 'react-admin';

import { EditActions } from '@src/custom';
import { formTypes, formFields, source } from './data';

const useStyles = makeStyles({
    root: {
        maxWidth: '1200px',
    },
});

const ShowContactForm = (props) => {
    const classes = useStyles();
    const controllerProps = useShowController(props);

    return (
        <ShowView
            {...props}
            {...controllerProps}
            className={classes.root}
            title="Contact Forms"
            actions={<EditActions resource="form_entries" />}
        >
            <SimpleShowLayout>
                <SelectField
                    label="Type"
                    source={source.type}
                    choices={formTypes}
                />
                {
                    controllerProps.record && (formFields[controllerProps.record.type] || []).map((formField) => (
                        <TextField
                            key={formField.key}
                            label={formField.title}
                            source={formField.key}
                        />
                    ))
                }
                <DateField
                    label="Created At"
                    source={source.createdAt}
                />
            </SimpleShowLayout>
        </ShowView>
    );
};

export default ShowContactForm;
