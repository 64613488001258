import React from 'react';
import PropTypes from 'prop-types';

import {
    TextInput,
    SelectInput,
    ReferenceInput,
    ReferenceArrayInput,
} from 'react-admin';

import Grid from '@material-ui/core/Grid';

import { source, templateLanguages } from './data';

import {
    ProductStatesSelect,
    StyledSelectArrayInput,
    VideoInput,
} from '@src/custom';

const CoBrandedVideoTemplateForm = (props) => {
    const { resource, record } = props;

    return (
        <>
            <Grid container >
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        required
                        label="Video Template Name"
                        source={source.name}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12} md={6}>
                    <ReferenceInput
                        required
                        label="Category"
                        source={source.coBrandedVideoCategoryId}
                        resource={resource}
                        record={record}
                        reference="co_branded_video_categories"
                    >
                        <SelectInput
                            fullWidth
                            emptyText="Select category"
                            optionText="name"
                        />
                    </ReferenceInput>
                </Grid>

                <Grid item xs={12} md={6}>
                    <ReferenceArrayInput
                        key="select-array-input-brands"
                        required
                        label="Brands"
                        reference={source.brandsReference}
                        source={source.brands}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        required
                        label="Enter Co-branded Transition Time (mm:ss)"
                        source={source.transitionTimeStr}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SelectInput
                        fullWidth
                        source={source.language}
                        choices={templateLanguages}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={6}>
                    <ReferenceArrayInput
                        required
                        label="Applicable Products"
                        reference={source.allProductsReference}
                        source={source.products}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth groupBy="business_unit_name" />
                    </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ProductStatesSelect
                        key="select-array-input-states"
                        required
                        fullWidth
                        label="Applicable States"
                        source={source.states}
                        productsSource={source.products}
                        resource={resource}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12} md={12}>
                    <TextInput
                        fullWidth
                        label="Disclaimer"
                        source={source.disclaimer}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12}>
                    <VideoInput isRequired source={source.video} />
                </Grid>
            </Grid>
        </>
    );
};

CoBrandedVideoTemplateForm.propTypes = {
    resource: PropTypes.string.isRequired,
    record: PropTypes.object,
};

export default CoBrandedVideoTemplateForm;
