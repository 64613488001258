import React, { Component } from 'react';
import styles from './error.scss';
import {
    history,
} from '../../containers/app';

class Error401 extends Component {
    setLocalStorageToNoneAndRefreshPage = () => {
        window.localStorage.setItem('jwt', '');
        history.replace('/');
        window.location.reload();
    }

    render() {
        return (
            <div id="home" className={styles.error_page_container}>
                <div className={styles.error_container}>
                    <div className="row">
                        <div className="col text-center">
                            <img
                                src="/img/Computer-Lock-Icon.png"
                                alt="Computer screen with a Lock Pad on the screen"
                            />
                            <h1>We&#39;re Sorry</h1>
                            <h2>Due to inactivity, the system has timed out for your security.</h2>
                            <div>
                                Please,
                                { ' ' }
                                <a
                                    className={styles['ml-link_error']}
                                    onClick={this.setLocalStorageToNoneAndRefreshPage}
                                >
                                    log in again
                                </a>
                                { ' ' }
                                to continue.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Error401;
