import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import { TextInput } from 'react-admin';
import { Field, useForm } from 'react-final-form';

import { slugify } from './utils';

import SlugInput from './SlugInput';

const TitleSlug = ({
    isCreate,
    isSlugHidden,
    pageUrlPart,
    slugLabel,
    source,
    titleLabel,
    required,
}) => {
    const form = useForm();
    const handleOnChange = React.useCallback((e) => {
        if (!isCreate) {
            return;
        }

        form.change(source.slug, slugify(e.nativeEvent.target.value));
    }, [isCreate, form.change]);

    if (isSlugHidden) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        label={titleLabel}
                        source={source.title}
                        required={required}
                        onChange={handleOnChange}
                    />
                    <Field
                        type="hidden"
                        name={source.slug}
                        component="input"
                    />
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        label={titleLabel}
                        required={required}
                        source={source.title}
                        onInput={handleOnChange}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <SlugInput
                        fullWidth
                        label={slugLabel}
                        required={required}
                        source={source.slug}
                        pageUrlPart={pageUrlPart}
                        options={{ fullWidth: true }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

TitleSlug.propTypes = {
    dispatch: PropTypes.func,
    isCreate: PropTypes.bool,
    isSlugHidden: PropTypes.bool,
    pageUrlPart: PropTypes.string.isRequired,
    source: PropTypes.shape({
        title: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
    }).isRequired,
    titleLabel: PropTypes.string,
    slugLabel: PropTypes.string,
    required: PropTypes.bool,
};

TitleSlug.defaultProps = {
    titleLabel: 'Title',
    slugLabel: 'URL',
};

export default TitleSlug;
