import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import { TextInput } from 'react-admin';
import { useField } from 'react-final-form';

import { normalizer } from './utils';

const useStyles = makeStyles((theme) => ({
    prefix: {
        display: 'block',
        marginRight: 0,
        color: theme.palette.text.disabled,
    },
}));

const HOSTNAME = process.env.FRONTEND_HOST || `${window.location.protocol}//${window.location.hostname}`;

const SlugInput = ({ pageUrlPart, source, ...props }) => {
    const urlPrefix = `${HOSTNAME}/${pageUrlPart}/`;
    const field = useField(source);
    const classes = useStyles();

    const prefix = (
        <InputAdornment
            disableTypography
            disablePointerEvents
            position="start"
            variant="standard"
            className={classes.prefix}
        >
            { urlPrefix }
        </InputAdornment>
    );

    const { meta: { dirty }, input: { value } } = field;

    let suffix = null;

    if (!dirty && value) {
        suffix = (
            <InputAdornment position="end">
                <Button
                    href={`${urlPrefix}${value}`}
                    target="_blank"
                    size="small"
                    color="primary"
                >
                    View URL
                </Button>
            </InputAdornment>
        );
    }

    const inputProps = {
        startAdornment: prefix,
        endAdornment: suffix,
    };

    return (
        <TextInput
            {...props}
            source={source}
            InputProps={inputProps}
            parse={normalizer}
        />
    );
};

SlugInput.propTypes = {
    pageUrlPart: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
};

export default React.memo(SlugInput);
