import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

// Disable missing translation warnings
// https://marmelab.com/react-admin/Translation.html#silencing-translation-warnings
const i18nProvider = polyglotI18nProvider(
    () => englishMessages,
    'en',
    {
        allowMissing: true,
        onMissingKey: (v) => v,
    },
);

export default i18nProvider;
