import React from 'react';

import Grid from '@material-ui/core/Grid';

import { AttachmentInput } from '@src/custom';

import { source } from './data';

const CarrierCommissionImportForm = () => {
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <AttachmentInput
                        fullWidth
                        hideThumbnail
                        source={source.attachment}
                        label="CSV file"
                        itemLabel="Import file"
                        accept="text/csv"
                        helperText="CVS file should include the following columns (order is not important): CARRIER, PRODUCT, STATE, NB PREM (DIRECT), REN (DIRECT), NB PREM (REFERRAL), REN (REFERRAL)"
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default CarrierCommissionImportForm;
