import React from 'react';

import {
    Filter,
    SelectInput,
} from 'react-admin';

import expirationFilters from '@src/global/choices/expiration-filters';

const RSMAgent360FilterForm = (props) => {
    return (
        <Filter {...props}>
            <SelectInput
                source="expiration"
                choices={expirationFilters}
            />
        </Filter>
    );
};

export default RSMAgent360FilterForm;
