import React from 'react';

import styles from './error.scss';

const Error404 = () => (
    <div id="home" className={styles.error_page_container}>
        <div className={styles.error_container}>
            <div className={styles.row}>
                <div className={`${styles.col} ${styles['text-center']}`}>
                    <div className={styles.error_container}>
                        <img
                            src="/img/Magnifying-Glass-Icon.png"
                            alt="Magnifying Glass"
                        />
                        <h1>Oops!</h1>
                        <h2>That page could not be found.</h2>
                        <div>Try another URL or <a className={styles['ml-link_error']} href="/">go to the Dashboard</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default React.memo(Error404);
