import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { Loading, Error } from 'react-admin';

import { getVideos } from '@src/services/api/videos';

import Item from './video_chooser_item';
import SearchBar from '@src/custom/search-bar';

const useStyles = makeStyles((theme) => ({
    pagination: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

const VideoChooser = ({ folderName, showCopy = true, onVideoChoosed }) => {
    const classes = useStyles();

    const [status, setStatus] = React.useState('loading');
    const [error, setError] = React.useState(null);
    const [videosData, setVideosData] = React.useState({ videos: [], page: 0, total: 0, perPage: 0 });
    const [page, setPage] = React.useState(1);
    const [searchTerm, setSearchTerm] = React.useState(null);

    React.useEffect(() => {
        const params = {
            folder: folderName, page,
        };
        if (searchTerm) {
            params.query = searchTerm;
        }

        getVideos(params).then((data) => {
            setVideosData(data);
            setStatus('success');
        }).catch((e) => {
            setError(e);
            setStatus('failed');
        });
    }, [page, setVideosData, setStatus, setError, searchTerm, folderName]);

    const handlePageChange = React.useCallback((e, page) => {
        setPage(page);
    }, [setPage]);

    const handleOnChange = React.useCallback((searchTerm) => {
        setSearchTerm(searchTerm);
    }, [setSearchTerm]);

    if (status === 'loading') {
        return <Loading />;
    }

    if (status === 'failed') {
        return <Error error={error} errorInfo={{ componentStack: null }} />;
    }

    if (!videosData.videos) return null;

    return (
        <>
            <SearchBar onChange={handleOnChange} />
            <Grid container spacing={2}>
                {
                    videosData.videos.map((video) => (
                        <Item
                            key={video.id}
                            video={video}
                            showCopy={showCopy}
                            onClick={onVideoChoosed}
                        />
                    ))
                }
            </Grid>
            <Pagination
                showFirstButton
                showLastButton
                className={classes.pagination}
                count={Math.floor(videosData.total / videosData.perPage)}
                page={videosData.page}
                onChange={handlePageChange}
            />
        </>
    );
};

VideoChooser.propTypes = {
    folderName: PropTypes.string,
    showCopy: PropTypes.bool,
    onVideoChoosed: PropTypes.func.isRequired,
    searchTerm: PropTypes.string,
};

export default React.memo(VideoChooser);
