import get from 'lodash/get';

import { addError } from '@src/validations';

import { source } from './data';

export const validate = (values) => {
    const errors = {};

    if (!get(values, source.name)) {
        addError(errors, source.name, 'A Name is required');
    }

    if (!values[source.brands] || values[source.brands].length <= 0) {
        errors[source.brands] = ['A Brand is required'];
    }

    return errors;
};

