import {
    mapSalesChannelsForList,
} from './common';

function mapGetListHomeArticlesData(responseData) {
    return responseData.map((data) => ({
        ...data,
        ...mapSalesChannelsForList(data),
    }));
}

export { mapGetListHomeArticlesData };
