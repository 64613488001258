import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextInput } from 'react-admin';
import { useField } from 'react-final-form';

import { useCopyStringToClipboard } from '@src/hooks/use-copy-string-to-clipboard';

const TextCopyInput = ({ copyButtonTitle = 'Copy', ...props }) => {
    const { input } = useField(props.source);
    const copyCallback = useCopyStringToClipboard(input.value);

    let suffix = null;
    if (input.value) {
        suffix = (
            <InputAdornment position="end">
                <Button
                    size="small"
                    color="primary"
                    onClick={copyCallback}
                >
                    { copyButtonTitle }
                </Button>
            </InputAdornment>
        );
    }

    return (
        <TextInput
            {...props}
            InputProps={{ endAdornment: suffix }}
        />
    );
};

TextCopyInput.propTypes = {
    copyButtonTitle: PropTypes.string,
    source: PropTypes.string.isRequired,
};

export default React.memo(TextCopyInput);
