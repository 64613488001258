import {
    cleanCommonParams,
    mapContentParams,
} from './common';

const mapSimplePageParam = (
    isCreate,
    { content, ...params },
) => {
    return new Promise((resolve) => {
        resolve({
            // eslint-disable-next-line camelcase
            simple_page: {
                ...cleanCommonParams(params),
                ...mapContentParams(isCreate, content),
            },
        });
    });
};

export { mapSimplePageParam };
