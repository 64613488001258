import React from 'react';
import PropTypes from 'prop-types';

import {
    ListButton,
    SaveButton,
    SimpleForm,
    Toolbar,
    TopToolbar,
} from 'react-admin';

import { MaxWidthEdit } from '@src/custom';
import Form from './Form';
import { validate } from './validate';

const RSMAgent360EditActions = ({ className, hasList, basePath }) => (
    <TopToolbar className={className}>
        { hasList && <ListButton basePath={basePath} />}
    </TopToolbar>
);

RSMAgent360EditActions.propTypes = {
    className: PropTypes.string,
    hasList: PropTypes.bool,
    resource: PropTypes.string.isRequired,
    basePath: PropTypes.string.isRequired,
    data: PropTypes.object,
};

const EditRSMAgent360Toolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton
            disabled={props.pristine}
            label="Save"
            redirect={false}
        />
    </Toolbar>
);

EditRSMAgent360Toolbar.propTypes = {
    pristine: PropTypes.bool.isRequired,
};

const EditRSMAgent360 = (props) => (
    <MaxWidthEdit
        {...props}
        title="Edit"
        actions={<RSMAgent360EditActions />}
    >
        <SimpleForm
            redirect={false}
            submitOnEnter={false}
            toolbar={<EditRSMAgent360Toolbar />}
            validate={validate}
        >
            <Form {...props} />
        </SimpleForm>
    </MaxWidthEdit>
);

export default EditRSMAgent360;
