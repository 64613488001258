const source = {
    name: 'name',
    coBrandedVideoLogos: 'co_branded_video_logos',
    logosReference: 'co_branded_video_logos',
    createdAt: 'created_at',
    updatedAt: 'updated_at',
};

export {
    source,
};
