import React from 'react';

import {
    Datagrid,
    List,
    TextField,
    DateField,
} from 'react-admin';

import { Permissions } from '@src/global/permissions';

import {
    BulkActionButtons,
    EditButtonCustom,
} from '@src/custom';

import { source } from './data';
// import FilterForm from './filter-form';

const RolesList = (props) => (
    <List
        {...props}
        title="Acess Groups"
        sort={{ field: source.updatedAt, order: 'DESC' }}
        hasCreate={Permissions('roles', 'create') === true}
        bulkActionButtons={<BulkActionButtons resource="roles" {...props} />}
    >
        <Datagrid>
            <TextField
                label="Access Group"
                source={source.name}
            />
            <TextField
                label="# of Users"
                source={source.usersCount}
            />
            <DateField
                label="Last Updated"
                source={source.updatedAt}
            />
            <EditButtonCustom resource="roles" {...props} />
        </Datagrid>
    </List>
);

export default RolesList;
