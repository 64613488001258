import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.error.main,
    },
}));

const RemoveImageButton = (props) => {
    const { image, onRemove } = props;

    const classes = useStyles();

    const handleClick = useCallback(() => {
        onRemove(image);
    }, [onRemove, image]);

    return (
        <Button
            className={classes.root}
            label="Remove image"
            onClick={handleClick}
        >
            <DeleteIcon />
        </Button>
    );
};

RemoveImageButton.propTypes = {
    image: PropTypes.object.isRequired,
    onRemove: PropTypes.func.isRequired,
};

export default RemoveImageButton;
