import React from 'react';
import {
    ArrayField,
    Datagrid,
    DateField,
    List,
    TextField,
} from 'react-admin';

import { Permissions } from '@src/global/permissions';

import {
    BulkActionButtons,
    ExpirationDateField,
    CapitalizedTextField,
    SingleFieldListCustom,
    EditButtonCustom,
} from '@src/custom';

import { source } from './data';
import Filter from './Filter';

const RateAndRuleManualsList = (props) => {
    return (
        <List
            {...props}
            title="Rate Bulletins"
            filters={<Filter />}
            sort={{ field: source.updatedAt, order: 'DESC' }}
            hasCreate={Permissions('rate_bulletins', 'create') === true}
            bulkActionButtons={<BulkActionButtons resource="rate_bulletins" {...props} />}
        >
            <Datagrid>
                <TextField
                    label="Title"
                    source={source.title}
                />
                <ArrayField
                    label="Writing Co."
                    source={source.writingCompanies}
                    sortable={false}
                >
                    <SingleFieldListCustom linkType={false}>
                        <TextField source="name" />
                    </SingleFieldListCustom>
                </ArrayField>
                <ArrayField
                    label="Products"
                    source={source.products}
                    sortable={false}
                >
                    <SingleFieldListCustom linkType={false}>
                        <TextField source="name" />
                    </SingleFieldListCustom>
                </ArrayField>
                <ArrayField
                    label="States"
                    source={source.states}
                    sortable={false}
                >
                    <SingleFieldListCustom linkType={false}>
                        <TextField source="name" />
                    </SingleFieldListCustom>
                </ArrayField>
                <DateField
                    label="Creation Date"
                    source={source.createdAt}
                />
                <DateField
                    label="Last Updated"
                    source={source.updatedAt}
                />
                <ExpirationDateField
                    label="Expiration Date"
                    source={source.expiredAt}
                />
                <CapitalizedTextField
                    label="Status"
                    source={source.status}
                />
                <EditButtonCustom resource="rate_bulletins" {...props} />
            </Datagrid>
        </List>
    );
};

export default React.memo(RateAndRuleManualsList);
