import { Taxonomies } from '@src/global/taxonomies';
import {
    mapAttachmentsForOne,
    mapAuthorForOne,
    mapFeedItem,
    mapProductsForList,
    mapProductsForOne,
    mapSalesChannelsForOne,
    mapStatesForList,
    mapStatesForOne,
} from './common';

function mapCategories(responseData) {
    if (!responseData.categories) {
        return responseData.categories;
    }

    return {
        categories: responseData.categories.sort((a, b) => (a.name > b.name) ? 1 : -1),
        collateralCategories: responseData.categories
            .filter((category) => category.taxonomy === Taxonomies.collateral)
            .map((item) => item.slug),
    };
}

function mapGetOneCollateralData(responseData) {
    return {
        ...responseData,
        ...mapCategories(responseData),
        ...mapSalesChannelsForOne(responseData),
        ...mapProductsForOne(responseData),
        ...mapStatesForOne(responseData),
        ...mapAttachmentsForOne(responseData),
        ...mapFeedItem(responseData),
        ...mapAuthorForOne(responseData),
    };
}

function mapGetListCollateralsData(responseData) {
    return responseData.map((collateral) => ({
        ...collateral,
        ...mapCategories(collateral),
        ...mapProductsForList(collateral),
        ...mapStatesForList(collateral),
    }));
}

export { mapGetOneCollateralData, mapGetListCollateralsData };
