import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';

import ExpirationDate from '@src/custom/expiration-date';

const useStyles = makeStyles({
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    thumbnail: {
        height: '100%',
        width: '100%',
        minHeight: '6rem',
    },
});

const ContentImageItem = ({ image }) => {
    const classes = useStyles();

    return (
        <Card variant="outlined">
            <Grid container spacing={0}>
                {
                    <Grid item xs={12} sm={3} md={2}>
                        <CardMedia
                            className={classes.thumbnail}
                            image={get(image, 'source')}
                            title={get(image, 'alt')}
                        />
                    </Grid>
                }
                <Grid item className={classes.details} xs={12} sm={9} md={10}>
                    <CardContent className={classes.content}>
                        <Typography component="h5" variant="h5">
                            { get(image, 'alt') }
                        </Typography>
                        <ExpirationDate expiredAt={get(image, 'expired_at')} />
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    );
};

ContentImageItem.propTypes = {
    image: PropTypes.object.isRequired,
};

export default ContentImageItem;
