import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import {
    DateInput,
    ReferenceArrayInput,
    SelectInput,
    TextInput,
} from 'react-admin';

import {
    ImageInput,
    StyledSelectArrayInput,
} from '@src/custom';

import { FirstRow } from '@src/components/FormHeader';

import { status } from '@src/global/choices';
import { source } from './data';

const SocialSharingForm = (props) => {
    const { resource } = props;

    return (
        <>
            <FirstRow key="first-row" {...props} source={source}>
                <DateInput
                    label="Expiration Date"
                    source={source.expiredAt}
                    inputProps={{ readOnly: true }}
                />
                <DateInput
                    required
                    label="Publication Date"
                    source={source.publishedAt}
                />
                <SelectInput
                    source={source.status}
                    choices={status}
                />
            </FirstRow>
            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        required
                        source={source.title}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        multiline
                        required
                        label="Social Content"
                        helperText="Please note, there is a limit of 280 characters."
                        source={source.content}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <DateInput
                        label="Content Expiration Date"
                        source={source.contentExpiredAt}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <ImageInput source={source.image} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <ReferenceArrayInput
                        key="products"
                        required
                        label="Products"
                        reference={source.productsReference}
                        resource={resource}
                        source={source.products}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ReferenceArrayInput
                        required
                        label="Safety tips category"
                        source={source.safetyTipsCategories}
                        reference={source.safetyTipsCategoriesReference}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ReferenceArrayInput
                        required
                        label="Holiday and seasonal category"
                        source={source.holidayAndSeasonalCategories}
                        reference={source.holidayAndSeasonalCategoriesReference}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ReferenceArrayInput
                        required
                        label="Other categories"
                        source={source.socialSharingsCategories}
                        reference={source.socialSharingsCategoriesReference}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
            </Grid>
        </>
    );
};

SocialSharingForm.propTypes = {
    resource: PropTypes.string.isRequired,
};

export default SocialSharingForm;
