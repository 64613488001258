import {
    cleanCommonParams,
    mapProductsParams,
    mapStatesParams,
    mapBrandsParams,
    mapVideoParams,
} from './common';

const mapCoBrandedVideoTemplateParams = (
    isCreate,
    {
        products,
        states,
        brands,
        video,
        ...params
    },
) => {
    return new Promise((resolve) => {
        resolve({
            // eslint-disable-next-line camelcase
            co_branded_video_template: {
                ...cleanCommonParams(params),
                ...mapProductsParams(isCreate, products),
                ...mapStatesParams(isCreate, states),
                ...mapBrandsParams(isCreate, brands),
                ...mapVideoParams(isCreate, video),
            },
        });
    });
};

export { mapCoBrandedVideoTemplateParams };
