import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles((theme) => ({
    container: {
        /* eslint-disable quote-props */
        backgroundColor: theme.palette.action.hover,
        cursor: 'pointer',
        padding: '1rem',
        textAlign: 'center',
        color: '#999',
        borderRadius: theme.shape.borderRadius,
        transition: theme.transitions.create('background-color', {
            duration: theme.transitions.duration.shorter,
            easing: theme.transitions.easing.easeOut,
        }),
        '&:hover': {
            backgroundColor: theme.palette.action.disabledBackground,
        },
        /* eslint-enable quote-props */
    },
    dropzoneMessage: {
        fontSize: '16px',
    },
}));

const AttachmentDropzone = (props) => {
    const {
        accept,
        multiple = true,
        title = 'Drop a file to upload, or click to browse.',
        onFileSelected,
    } = props;
    const classes = useStyles();

    const {
        getRootProps,
        getInputProps,
    } = useDropzone({
        onDrop: onFileSelected,
        accept: accept,
        multiple: multiple,
    });

    return (
        <div className={classes.container} {...getRootProps({ id: 'file-input-dropzone' })}>
            <input {...getInputProps()} />
            <Typography className={classes.dropzoneMessage} variation="body1" align="center" color="textSecondary">
                { title }
            </Typography>
        </div>
    );
};

AttachmentDropzone.propTypes = {
    accept: PropTypes.string,
    multiple: PropTypes.bool,
    title: PropTypes.string,
    onFileSelected: PropTypes.func.isRequired,
};

AttachmentDropzone.defaultProps = {
    accept: 'application/pdf,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpeg,image/png,text/csv,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation',
};

export default AttachmentDropzone;
