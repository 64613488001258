import get from 'lodash/get';

import { addError } from '@src/validations';
import { source } from './data';

export const validate = (values) => {
    const errors = {};

    if (!get(values, source.name)) {
        addError(errors, source.name, 'Name is required');
    }

    return errors;
};

