export const source = {
    id: 'id',
    categoryId: 'category_id',
    discardedAt: 'discarded_at',
    recordId: 'record_id',
    recordType: 'record_type',
    title: 'title',
    status: 'status',
};

export const filterStatus = [
    { id: 'draft', name: 'Draft' },
    { id: 'published', name: 'Published' },
    { id: 'discarded', name: 'Discarded' },
];
