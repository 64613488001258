import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import {
    BooleanInput,
    SelectInput,
    TextInput,
} from 'react-admin';

import { source, availabilityMapStatuses } from './data';

const ProductAvailabilityForm = (props) => {
    const { record } = props;

    if (!record) {
        return null;
    }

    return (
        <>
            <TextInput
                name={source.name}
                component="input"
                inputProps={{ readOnly: true }}
            />
            <Grid container >
                {
                    record.productStates.map((productState, index) => {
                        return (
                            <Grid key={`product-state-${productState.id}`} container>
                                <Grid item>
                                    <TextInput
                                        name={`productStates[${index}].name`}
                                        component="input"
                                        inputProps={{ readOnly: true }}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <SelectInput
                                        fullWidth
                                        required
                                        label="Map Availability Status"
                                        source={`productStates[${index}].availabilityMapStatus`}
                                        choices={availabilityMapStatuses}
                                    />
                                </Grid>
                                <Grid item>
                                    <BooleanInput
                                        label="Product Available on State?"
                                        source={`productStates[${index}].productAvailable`}
                                    />
                                </Grid>
                            </Grid>
                        );
                    })
                }
            </Grid>
        </>
    );
};

ProductAvailabilityForm.propTypes = {
    resource: PropTypes.string.isRequired,
    record: PropTypes.object,
};

export default ProductAvailabilityForm;
