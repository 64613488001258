import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

import { classToResource, resourceHumanName } from '@src/utils/model-helpers';

const ResourceHumanNameField = ({ record = {}, source }) => {
    const recordType = get(record, source);

    if (!recordType) return null;

    const resource = classToResource(recordType);
    const humanName = resourceHumanName(resource);

    return (
        <span>{humanName}</span>
    );
};

ResourceHumanNameField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default ResourceHumanNameField;
