// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';

import jwtDecode from 'jwt-decode';

import { loginURL } from '@src/services/api/helpers.js';

const parseURLForKeyTForToken = (queryString) => {
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    pairs.forEach((pairStr) => {
        const pair = pairStr.split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    });
    return query;
};

const checkUserRole = () => {
    const decodedJWT = jwtDecode(window.localStorage.getItem('jwt'));
    return decodedJWT && decodedJWT.access_level === 'admin';
};

const Auth = ({ children }) => {
    let redirectURL = null;

    if (!window.localStorage.getItem('jwt')) {
        const parse = parseURLForKeyTForToken(window.location.search);
        const usersCurrentUrl = window.location.href;

        /**
        * Check if the user has a token:
        * apply that token to local storage
        * then refresh the page, removing token from URL
        * else redirect to login page
        **/
        if (parse.t) {
            const urlToReloadThePageTo = usersCurrentUrl.split('?')[0];
            window.localStorage.setItem('jwt', parse.t);
            if (checkUserRole()) {
                redirectURL = urlToReloadThePageTo;
            } else {
                redirectURL = '/forbidden';
            }
        } else {
            redirectURL = loginURL(window.location.href);
        }
    } else if (!checkUserRole()) {
        redirectURL = '/forbidden';
    }

    if (redirectURL) {
        window.location.replace(redirectURL);
    }

    return children;
};

Auth.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Auth;
