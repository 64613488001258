import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import {
    CreateButton,
    Datagrid,
    ExportButton,
    List,
    TextField,
    TopToolbar,
} from 'react-admin';

import { Permissions } from '@src/global/permissions';

import { Link } from 'react-router-dom';

import { BulkActionButtons, EditButtonCustom } from '@src/custom';

import Filter from './Filter';
import { source } from '../data';

const styles = (theme) => ({
    publishIcon: {
        marginRight: theme.spacing(),
        fontSize: 20,
    },
    button: {
        margin: theme.spacing(),
    },
});

const ActionsCustom = withStyles(styles)(({ classes, className, basePath }) => (
    <TopToolbar className={className}>
        {
            Permissions('users', 'create') === true &&
            <CreateButton basePath={basePath} />
        }
        <Button
            component={Link}
            to="/employees/import"
            color="primary"
            size="small"
        >
            <PublishIcon className={classes.publishIcon} />
            Import
        </Button>
        <ExportButton />
    </TopToolbar>
));

const HomeOfficeEmployeesList = (props) => (
    <List
        {...props}
        title="Employees"
        filters={<Filter />}
        actions={<ActionsCustom />}
        sort={{ field: source.lastName, order: 'ASC' }}
        bulkActionButtons={<BulkActionButtons resource="users" {...props} />}
    >
        <Datagrid>
            <TextField
                source={source.firstName}
            />
            <TextField
                source={source.lastName}
            />
            <TextField
                label="Employee ID"
                source={source.employeeId}
            />
            <TextField
                source={source.email}
            />
            <EditButtonCustom resource="users" {...props} />
        </Datagrid>
    </List>
);

export default HomeOfficeEmployeesList;
