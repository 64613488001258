import React from 'react';

import {
    Datagrid,
    List,
    ShowButton,
    TextField,
} from 'react-admin';

import { Permissions } from '@src/global/permissions';

import {
    BulkActionButtons,
    CapitalizedTextField,
    ImpersonateButton,
} from '@src/custom';

import Filter from './Filter';
import { source } from '../data';

const AgentsList = (props) => (
    <List
        {...props}
        title="VUE Bio Records"
        filters={<Filter />}
        sort={{ field: source.lastName, order: 'ASC' }}
        hasCreate={Permissions('users', 'create') === true}
        bulkActionButtons={<BulkActionButtons resource="users" {...props} />}
    >
        <Datagrid>
            <TextField
                source={source.firstName}
            />
            <TextField
                source={source.lastName}
            />
            <TextField
                label="Employee ID"
                source={source.employeeId}
            />
            <TextField
                label="Producer ID"
                source={source.producerId}
            />
            <TextField
                source={source.email}
            />
            <CapitalizedTextField
                source={source.accessLevel}
            />
            <ShowButton />
            <ImpersonateButton />
        </Datagrid>
    </List>
);

export default AgentsList;
