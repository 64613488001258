import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import { formatTimestampToDate } from '@src/utils/dateHelpers';

const ExpirationDate = (props) => {
    const { expiredAt, ...rest } = props;

    if (!expiredAt) return null;

    const expiredAtDate = new Date(expiredAt);
    const color = expiredAtDate < new Date() ? 'error' : 'textSecondary';
    const dateString = formatTimestampToDate(expiredAtDate);

    return (
        <Typography variant="subtitle1" color={color} {...rest}>
            Expiration date: { dateString }
        </Typography>
    );
};

ExpirationDate.propTypes = {
    expiredAt: PropTypes.string,
};

export default ExpirationDate;
