import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import CopyVideoURLButton from './copy-video-url-button';

/* eslint-disable quote-props */
const useStyles = makeStyles({
    item: {
        display: 'flext',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        transition: 'transform .2s',
        '&:hover': {
            transform: 'scale(1.025)',
        },
    },
    media: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    link: {
        wordWrap: 'break-word',
    },
});
/* eslint-enable */

const VideoChooserItem = (props) => {
    const { video, showCopy, onClick } = props;

    const classes = useStyles();

    const handleVideoClick = useCallback((e) => {
        e.preventDefault();
        onClick(video);
    }, [onClick, video]);

    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className={classes.item}
        >
            <Card className={classes.card}>
                <CardMedia
                    className={classes.media}
                    component="video"
                    // height="140"
                    src={video.source}
                    onClick={handleVideoClick}
                />
                {
                    showCopy &&
                        <CardActions>
                            <CopyVideoURLButton video={video} />
                        </CardActions>
                }
            </Card>
        </Grid>
    );
};

VideoChooserItem.propTypes = {
    video: PropTypes.shape({
        id: PropTypes.number.isRequired,
        source: PropTypes.string.isRequired,
    }).isRequired,
    showCopy: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

export default React.memo(VideoChooserItem);
