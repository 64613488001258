import snakeCase from 'lodash/snakeCase';

const HUMAN_NAMES = {
    /* eslint-disable camelcase */
    alerts: 'Alert',
    articles: 'Sales & Marketing',
    attachments: 'Attachment',
    eligibility_guides: 'Eligibility Guides',
    job_aids: 'Job Aid',
    field_management_news: 'Field Management News',
    guides: 'Guide',
    home_banners: 'Home Banner',
    product_updates: 'Product News and Updates',
    rate_bulletins: 'Rate Bulletins',
    rsm_agent360: 'Agent360',
    social_sharings: 'Social Media Content',
    trainings: 'eLearning',
    underwriting_guidelines: 'Underwriting Guidelines',
    webinars: 'Webinar',
    /* eslint-enable camelcase */
};

const classToResource = (klass) => {
    const type = snakeCase(klass);

    if (type === 'field_management_news') return type;
    if (type === 'simple_page') return 'rsm_agent360';
    if (type === 'rate_and_rule_manual') return 'rate_bulletins';

    return `${type}s`;
};

const resourceHumanName = (resource) => {
    const name = HUMAN_NAMES[resource];

    return name || resource;
};

export {
    classToResource,
    resourceHumanName,
};
