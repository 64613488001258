import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { MenuItemLink } from 'react-admin';

import { LOGOUT_URL } from '../../global/login';

class LogoutMenuItem extends React.Component {
    constructor(props) {
        super(props);
        // eslint-disable-next-line react/state-in-constructor
        this.state = {
            open: false,
        };
    }

    handleClick = (e) => {
        e.preventDefault();
        this.setState({ open: true });
    }

    handleLogout = () => {
        window.localStorage.removeItem('jwt');
        this.setState({ open: false });
        window.location.replace(LOGOUT_URL);
    }

    handleCancel = () => {
        this.setState({ open: false });
    }

    render() {
        return (
            <>
                <MenuItemLink
                    key="logout"
                    primaryText="Logout"
                    to="/logout"
                    onClick={this.handleClick}
                />
                <Dialog
                    open={this.state.open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    onClose={this.handleCancel}
                >
                    <DialogTitle id="alert-dialog-title">
                        Log out
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to Log Out?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" onClick={this.handleCancel}>
                            Cancel
                        </Button>
                        <Button autoFocus color="primary" onClick={this.handleLogout}>
                            Log Out
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default LogoutMenuItem;
