import React from 'react';
import PropTypes from 'prop-types';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Button } from 'react-admin';

import { useCopyStringToClipboard } from '@src/hooks/use-copy-string-to-clipboard';
import { frontendUrl } from '@src/services/api/helpers';
import { frontendAttachmentPathname } from './utils';

const CopyAttachmentURLButton = (props) => {
    const { attachment } = props;
    const copyCallback = useCopyStringToClipboard(frontendUrl(frontendAttachmentPathname(attachment)));

    if (!attachment || !attachment.id) return null;

    return (
        <Button
            label="Copy URL"
            onClick={copyCallback}
        >
            <FileCopyIcon />
        </Button>
    );
};

CopyAttachmentURLButton.propTypes = {
    attachment: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        src: PropTypes.string,
    }),
};

export default CopyAttachmentURLButton;
