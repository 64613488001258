import axios from 'axios';

import { callApi } from '@src/services/api/helpers';

/**
 * Turn a browser dropped file structure into expected structure
 * */
const file2Attachment = (file) => {
    if (!(file instanceof File)) {
        return file;
    }

    return {
        // eslint-disable-next-line
        file: file,
        // eslint-disable-next-line camelcase
        display_name: file.name,
        filename: file.name,
    };
};

const attachmentPathname = (attachment) => {
    if (!attachment) return null;

    return `/attachments/${attachment.id}/download`;
};

const frontendAttachmentPathname = (attachment) => {
    if (!attachment) return null;

    return `/attachments?name=${attachment.filename}&id=${attachment.id}`;
};

const uploadToCloud = (files) => {
    files.forEach((file) => {
        callApi('/attachments/new', {
            params: { filename: file.name },
        }).then((response) => {
            return axios.put(
                response.data.attachment.upload_url,
                file,
                {
                    headers: {
                        'Content-Type': file.type,
                    },
                }
            );
        }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
        });
    });
};

export {
    attachmentPathname,
    frontendAttachmentPathname,
    file2Attachment,
    uploadToCloud,
};
