import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-admin';

import ImageUploader from './image_uploader';
import Modal from '@src/custom/modal-window';

const ImageUploaderButton = (props) => {
    const { folderName, onImageUploaded } = props;
    const [modalOpen, setModalOpen] = useState(false);

    const handleButtonClick = React.useCallback(() => {
        setModalOpen(true);
    }, [setModalOpen]);

    const handleClose = React.useCallback(() => {
        setModalOpen(false);
    }, [setModalOpen]);

    const handleImageUploaded = useCallback((image) => {
        setModalOpen(false);
        onImageUploaded(image);
    }, [setModalOpen, onImageUploaded]);

    return (
        <React.Fragment>
            <Button color="primary" label="Upload a New Image" onClick={handleButtonClick} />
            <Modal
                open={modalOpen}
                title="Upload an image"
                onClose={handleClose}
            >
                <ImageUploader
                    folderName={folderName}
                    onImageUploaded={handleImageUploaded}
                    onCancel={handleClose}
                />
            </Modal>
        </React.Fragment>
    );
};

ImageUploaderButton.propTypes = {
    folderName: PropTypes.oneOf(['featured', 'thumbnail', 'content']).isRequired,
    onImageUploaded: PropTypes.func.isRequired,
};

export default ImageUploaderButton;
