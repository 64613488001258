import React from 'react';

import {
    ArrayField,
    Datagrid,
    DateField,
    List,
    TextField,
} from 'react-admin';

import { Permissions } from '@src/global/permissions';

import { source } from './data';

import {
    BulkActionButtons,
    EditButtonCustom,
    ExpirationDateField,
    CapitalizedTextField,
    SingleFieldListCustom,
} from '@src/custom';

import Filter from './Filter';

const UnderwritingGuidelinesList = (props) => {
    return (
        <List
            {...props}
            title="Underwriting Guidelines"
            filters={<Filter />}
            sort={{ field: source.updatedAt, order: 'DESC' }}
            hasCreate={Permissions('underwriting_guidelines', 'create') === true}
            bulkActionButtons={<BulkActionButtons resource="underwriting_guidelines" {...props} />}
        >
            <Datagrid>
                <TextField
                    label="Title"
                    source={source.title}
                />
                <ArrayField
                    label="Writing Co."
                    source={source.writingCompanies}
                    sortable={false}
                >
                    <SingleFieldListCustom linkType={false}>
                        <TextField source="name" />
                    </SingleFieldListCustom>
                </ArrayField>
                <ArrayField
                    label="Products"
                    source={source.products}
                    sortable={false}
                >
                    <SingleFieldListCustom linkType={false}>
                        <TextField source="name" />
                    </SingleFieldListCustom>
                </ArrayField>
                <ArrayField
                    label="States"
                    source={source.states}
                    sortable={false}
                >
                    <SingleFieldListCustom linkType={false}>
                        <TextField source="name" />
                    </SingleFieldListCustom>
                </ArrayField>
                <DateField
                    label="Edition Date"
                    source={source.editionDate}
                />
                <DateField
                    label="Creation Date"
                    source={source.createdAt}
                />
                <DateField
                    label="Last Updated"
                    source={source.updatedAt}
                />
                <ExpirationDateField
                    label="Expiration Date"
                    source={source.expiredAt}
                />
                <CapitalizedTextField
                    label="Status"
                    source={source.status}
                />
                <EditButtonCustom resource="underwriting_guidelines" {...props} />
            </Datagrid>
        </List>
    );
};

export default UnderwritingGuidelinesList;
