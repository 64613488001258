import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

function SecondRow(props) {
    const {
        children,
    } = props;

    const childCount = React.Children.count(children);
    const oneChild = childCount === 1;
    const manyChildren = childCount > 1;

    const renderChildren = () => {
        return (
            <Grid container>
                <Grid item xs={12} sm={4}>
                    <Grid container justify="flex-start">
                        {
                            React.Children.map(children, (child) => {
                                if (child.props.displayLeft) {
                                    return (
                                        <React.Fragment>
                                            { child }
                                        </React.Fragment>
                                    );
                                }
                                return null;
                            })
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Grid container justify="flex-end">
                        {
                            React.Children.map(children, (child) => {
                                if (!child.props.displayLeft) {
                                    return (
                                        <React.Fragment>
                                            { child }
                                        </React.Fragment>
                                    );
                                }
                                return null;
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid container justify="flex-end">
            {
                oneChild &&
                children
            }
            {
                manyChildren &&
                renderChildren()
            }
        </Grid>
    );
}

SecondRow.propTypes = {
    children: PropTypes.node,
};

export default SecondRow;
