import React from 'react';
import PropTypes from 'prop-types';

import {
    DateInput,
    ReferenceArrayInput,
    SelectInput,
    TextInput,
} from 'react-admin';

import { Field } from 'react-final-form';

import Grid from '@material-ui/core/Grid';

import { FirstRow, SecondRow } from '@src/components/FormHeader';

import {
    AttachmentInput,
    AddToHomepageField,
    ExpirationDateInput,
    PublicationDateInput,
    FeedInputs,
    ProductStatesSelect,
    StyledSelectArrayInput,
    TitleSlug,
} from '@src/custom';

import { status } from '@src/global/choices';

import { source } from './data';

const UnderwritingGuidelineForm = (props) => {
    const { isCreate, resource } = props;
    return (
        <>
            <Field
                type="hidden"
                name={source.resourceType}
                component="input"
            />
            <FirstRow key="first-row" {...props} source={source}>
                <ExpirationDateInput resource={resource} source={source} />
                <DateInput
                    required
                    label="Edition Date"
                    source={source.editionDate}
                />
                <PublicationDateInput resource={resource} source={source} />
                <SelectInput
                    source={source.status}
                    choices={status}
                />
            </FirstRow>
            <SecondRow key="second-row">
                <AddToHomepageField resource={resource} source={source} />
                <FeedInputs resource={resource} />
            </SecondRow>
            <TitleSlug
                required
                isCreate={isCreate}
                source={source}
                pageUrlPart="guides-and-rate-bulletins"
                titleLabel="Title"
            />
            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        required
                        label="Description"
                        source={source.description}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        label="Internal Note"
                        source={source.note}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} >
                    <AttachmentInput
                        fullWidth
                        hideThumbnail
                        isRequired
                        source={source.attachment}
                        label="Attachment"
                        accept="application/pdf"
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={4}>
                    <ReferenceArrayInput
                        required
                        label="Writing Companies"
                        source={source.writingCompanies}
                        reference={source.writingCompaniesReference}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} md={4}>
                    <ReferenceArrayInput
                        required
                        label="Applicable Products"
                        reference={source.productsReference}
                        source={source.products}
                        resource={resource}
                        // eslint-disable-next-line camelcase
                        filter={{ for_guides: true }}
                    >
                        <StyledSelectArrayInput fullWidth groupBy="business_unit_name" />
                    </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} md={4}>
                    <ProductStatesSelect
                        fullWidth
                        label="Applicable States"
                        source={source.states}
                        productsSource={source.products}
                        resource={resource}
                    />
                </Grid>
            </Grid>
        </>
    );
};

UnderwritingGuidelineForm.propTypes = {
    isCreate: PropTypes.bool,
    resource: PropTypes.string.isRequired,
};

export default UnderwritingGuidelineForm;
