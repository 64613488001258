import React from 'react';
import PropTypes from 'prop-types';

import { NumberInput } from 'react-admin';

const formatDuration = (duration) => duration ? Math.ceil(duration / 60) : '';
const parseDuration = (value) => value && value * 60;

const DurationInput = ({ source, ...props }) => (
    <NumberInput
        {...props}
        source={source}
        format={formatDuration}
        parse={parseDuration}
        helperText="Duration in minutes"
        inputProps={{
            step: 1,
            min: 1,
            // max: 20,
        }}
    />
);

DurationInput.propTypes = {
    source: PropTypes.string.isRequired,
};

export default DurationInput;
