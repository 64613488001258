import React from 'react';

import {
    Filter,
    ReferenceArrayInput,
    SelectInput,
    SearchInput,
} from 'react-admin';

import { StyledSelectArrayInput } from '@src/custom';
import expirationFilters from '@src/global/choices/expiration-filters';
import { expiredFilterTypes, source, staticCategory, staticStatus } from './data';

const AlertsFormFilter = (props) => {
    return (
        <Filter {...props}>
            <SearchInput alwaysOn source="query" />
            <SelectInput
                allowEmpty={false}
                source="expired"
                choices={expiredFilterTypes}
            />
            <SelectInput
                source="expiration"
                choices={expirationFilters}
            />
            <ReferenceArrayInput
                label="Products"
                reference={source.productsReference}
                source={source.productIds}
            >
                <StyledSelectArrayInput />
            </ReferenceArrayInput>
            <ReferenceArrayInput
                label="States"
                reference={source.statesReference}
                source={source.stateIds}
            >
                <StyledSelectArrayInput />
            </ReferenceArrayInput>
            <StyledSelectArrayInput
                label="Category"
                source={source.category}
                choices={staticCategory}
            />
            <SelectInput
                source={source.status}
                choices={staticStatus}
            />
            <ReferenceArrayInput
                label="Sales Channels"
                reference={source.salesChannelsReference}
                source={source.salesChannelIds}
            >
                <StyledSelectArrayInput />
            </ReferenceArrayInput>
        </Filter>
    );
};

export default AlertsFormFilter;
