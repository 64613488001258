// import 'core-js/stable';
// import 'regenerator-runtime/runtime';

import React from 'react';

import 'whatwg-fetch';
// import 'promise-polyfill/src/polyfill';
import 'nodep-date-input-polyfill';

import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ImageSelector from '@src/components/image-selector';
import Error403 from '@src/components/error/Error403';
import Login from '@src/containers/login';
import App from '@src/containers/app';
import { GetSignedUrlFile } from '@src/custom';

import './css/metlife_css/index.scss';
import './index.scss';

render(
    <Router>
        <Switch>
            <Route exact path="/attachments/:id/download" component={GetSignedUrlFile} />
            <Route exact path="/forbidden" component={Error403} />,
            <Route exact path="/login" component={Login} />,
            <Route exact path="/image-selector" component={ImageSelector} />,
            <Route component={App} />
        </Switch>
    </Router>,
    document.querySelector('#root')
);
