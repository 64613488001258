const source = {
    name: 'name',
    brandId: 'brand_id',
    image: 'image',
    secondImage: 'second_image',
    stateIds: 'state_ids',
    states: 'states',
    statesReference: 'states',
    createdAt: 'created_at',
    updatedAt: 'updated_at',
};

export {
    source,
};
