import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-admin';

import VideoUploader from './video_uploader';
import Modal from '@src/custom/modal-window';

const VideoUploaderButton = (props) => {
    const { folderName, onVideoUploaded } = props;
    const [modalOpen, setModalOpen] = useState(false);

    const handleButtonClick = React.useCallback(() => {
        setModalOpen(true);
    }, [setModalOpen]);

    const handleClose = React.useCallback(() => {
        setModalOpen(false);
    }, [setModalOpen]);

    const handleVideoUploaded = useCallback((video) => {
        setModalOpen(false);
        onVideoUploaded(video);
    }, [setModalOpen, onVideoUploaded]);

    return (
        <>
            <Button color="primary" label="Upload a New Video" onClick={handleButtonClick} />
            <Modal
                open={modalOpen}
                title="Upload a video"
                onClose={handleClose}
            >
                <VideoUploader
                    folderName={folderName}
                    onVideoUploaded={handleVideoUploaded}
                    onCancel={handleClose}
                />
            </Modal>
        </>
    );
};

VideoUploaderButton.propTypes = {
    folderName: PropTypes.oneOf(['co_branded_videos']).isRequired,
    onVideoUploaded: PropTypes.func.isRequired,
};

export default VideoUploaderButton;
