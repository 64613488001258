import React from 'react';

import {
    Filter,
    SearchInput,
} from 'react-admin';

const ArticlesFormFilter = (props) => {
    return (
        <Filter {...props}>
            <SearchInput alwaysOn source="query" />
        </Filter>
    );
};

export default ArticlesFormFilter;
