import React from 'react';

import GetAppIcon from '@material-ui/icons/GetApp';
import { Button } from 'react-admin';
import { useFormState } from 'react-final-form';

import { attachmentPathname } from '@src/custom/attachment-input/utils';

const DownloadButtonInput = () => {
    const { values: attachment } = useFormState();

    if (!attachment || !attachment.id) return null;

    const downloadURL = attachmentPathname(attachment);

    return (
        <Button
            label="Download"
            href={downloadURL}
            rel="noopener noreferrer"
            target="_blank"
        >
            <GetAppIcon />
        </Button>
    );
};

export default React.memo(DownloadButtonInput);
