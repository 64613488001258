export const source = {
    authorName: 'authorName',
    authorEmail: 'authorEmail',
    authorId: 'author_id',
    contentImages: 'content_images',
    status: 'status',
    effectiveFrom: 'effective_from',
    effectiveUntil: 'effective_until',
    expiredAt: 'expired_at',
    archivedAt: 'archived_at',
    title: 'title',
    slug: 'slug',
    message: 'message',
    note: 'note',
    roleType: 'role_type',
    content: 'content.content',
    contentExpiredAt: 'content.expired_at',
    category: 'category',
    salesChannelIds: 'sales_channel_ids',
    salesChannels: 'sales_channels',
    salesChannelsReference: 'sales_channels',
    productIds: 'product_ids',
    products: 'products',
    productsReference: 'products',
    stateIds: 'state_ids',
    states: 'states',
    statesReference: 'states',
    createdAt: 'created_at',
    updatedAt: 'updated_at',
    attachments: 'attachments',
    isHomeArticle: 'is_home_article',
    versionDescription: 'version_description',
    recordRevisions: 'record_revisions',
};

export const staticStatus = [
    {
        id: 'active',
        name: 'Active',
    },
    {
        id: 'draft',
        name: 'Draft',
    },
    {
        id: 'lifted',
        name: 'Lifted',
    },
    {
        id: 'updated',
        name: 'Updated',
    },
];

export const staticCategory = [
    {
        id: 'disaster_recovery',
        name: 'Disaster Recovery',
    },
    {
        id: 'highlighted',
        name: 'Business Updates',
    },
    {
        id: 'technology',
        name: 'Technology',
    },
];

export const expiredFilterTypes = [
    { id: 'not_expired', name: 'Not expired' },
    { id: 'expired', name: 'Expired' },
    { id: 'expired_and_archived', name: 'Expired and archived' },
    { id: 'archived', name: 'Archived' },
    { id: 'all', name: 'All' },
];

