import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { impersonate } from '@src/services/api/impersonate';

const ImpersonateButton = ({ record }) => {
    const handleClick = React.useCallback((e) => {
        e.preventDefault();
        impersonate(record.id).then((data) => {
            const a = document.createElement('a');
            a.href = `${process.env.FRONTEND_HOST}?admin_jwt=${data.admin_jwt}&agent_jwt=${data.agent_jwt}`;
            a.setAttribute('target', '_blank');
            a.click();
        }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }, [record]);

    return (
        <Button
            color="primary"
            endIcon={<OpenInNewIcon />}
            rel="noopener noreferrer"
            target="_blank"
            onClick={handleClick}
        >
            Masquerade
        </Button>
    );
};

ImpersonateButton.propTypes = {
    record: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
};

export default ImpersonateButton;
