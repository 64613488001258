export const source = {
    id: 'id',
    name: 'name',
    associationsCount: 'associations_count',
    resource: 'categorizations',
    note: 'note',
};

export const resourceSource = {
    id: 'id',
    discardedAt: 'discarded_at',
    type: 'type',
    title: 'title',
    status: 'status',
};

