import get from 'lodash/get';

import { addError } from '@src/validations';
import { isValidUrl } from '@src/utils/isValidUrl';

import { source } from './data';

function validatePublished(values, errors) {
    if (!get(values, source.publishedAt)) {
        addError(errors, source.publishedAt, 'A Publication Date is required');
    }
    if (get(values, source.salesChannels, []).length <= 0) {
        addError(errors, source.salesChannels, 'A Sales Channel is required');
    }
}

function validateCta(values, errors) {
    if (!get(values, source.ctaLabel)) {
        addError(errors, source.ctaLabel, 'A call to action label is required');
    }
    if (!get(values, source.ctaUrl)) {
        addError(errors, source.ctaUrl, 'A call to action URL is required');
    }
}

export const validate = (values) => {
    const errors = {};

    if (!get(values, source.title)) {
        addError(errors, source.title, 'A Title is required');
    }

    if (get(values, source.status) === 'published') {
        validatePublished(values, errors);
    }

    if (get(values, source.hasCta)) {
        validateCta(values, errors);
    }

    const ctaURL = get(values, source.ctaUrl);
    if (ctaURL && !isValidUrl(ctaURL, { relative: true })) {
        addError(errors, source.ctaUrl, 'Call to action URL must be an URL');
    }

    return errors;
};

