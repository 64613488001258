import React from 'react';
import PropTypes from 'prop-types';

import { Permissions } from '@src/global/permissions';

import Grid from '@material-ui/core/Grid';
import get from 'lodash/get';
import {
    BooleanInput,
    FormDataConsumer,
    SelectInput,
} from 'react-admin';
import { useForm } from 'react-final-form';

import { StyledSelectArrayInput } from '@src/custom';
import { validateFeedChannels } from '@src/validations';
import { FeedItemSource as source, FeedItemPriorities, FeedItemChannels } from './data';

const getEnabled = (formData) => get(formData, source.enabled);
const getPriority = (formData) => get(formData, source.priority);
const getChannels = (formData) => get(formData, source.channels);

const hasChannels = (formData) => {
    const channels = getChannels(formData) || [];
    return (channels && channels.length >= 1);
};

const channelNames = () => {
    return FeedItemChannels.map((channel) => channel.name);
};

const FeedInputs = (props) => {
    const { children, formData, resource, ...rest } = props;
    const form = useForm();

    const handleEnabledChange = React.useCallback((value) => {
        if (!value || getPriority(formData) || hasChannels(formData)) {
            return null;
        }

        form.change(source.priority, 'low');
        form.change(source.channels, channelNames());
    }, [formData, form]);

    if (Permissions(resource, 'set_rss') === false) {
        return null;
    }

    return (
        <>
            <Grid item>
                <BooleanInput
                    label="Add to RSS feed?"
                    source={source.enabled}
                    onChange={handleEnabledChange}
                    {...rest}
                />
            </Grid>
            <Grid item>
                <SelectInput
                    allowEmpty={false}
                    disabled={!getEnabled(formData)}
                    label="Feed Priority"
                    source={source.priority}
                    choices={FeedItemPriorities}
                    {...rest}
                />
            </Grid>
            <Grid item>
                <StyledSelectArrayInput
                    disabled={!getEnabled(formData)}
                    label="Feed Channels"
                    source={source.channels}
                    choices={FeedItemChannels}
                    validate={validateFeedChannels}
                    {...rest}
                />
            </Grid>
        </>
    );
};

FeedInputs.propTypes = {
    formData: PropTypes.object,
    children: PropTypes.node,
    resource: PropTypes.string,
};

const FeedInputsConsumer = (props) => {
    return (
        <FormDataConsumer>
            { (formDataProps) => <FeedInputs {...formDataProps} {...props} /> }
        </FormDataConsumer>
    );
};

export default FeedInputsConsumer;
