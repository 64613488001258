import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import Title from './modal-window-title';

const ImageInputModal = ({ children, open, title, onClose, ...rest }) => {
    return (
        <Dialog
            fullWidth
            maxWidth="lg"
            aria-labelledby="image-modal-title"
            {...rest}
            open={open}
        >
            <Title id="image-modal-title" onClose={onClose}>
                { title }
            </Title>
            <DialogContent>
                { children }
            </DialogContent>
        </Dialog>
    );
};

ImageInputModal.propTypes = {
    children: PropTypes.node,
    open: PropTypes.bool,
    title: PropTypes.string,
    onClose: PropTypes.func,
};

export default ImageInputModal;
