import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Show } from 'react-admin';

const useStyles = makeStyles({
    root: {
        maxWidth: '1200px',
    },
});

const MaxWidthShow = (props) => {
    const classes = useStyles();

    return (
        <Show
            className={classes.root}
            {...props}
        />
    );
};

export default MaxWidthShow;
