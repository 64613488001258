import React from 'react';
import PropTypes from 'prop-types';

import intersection from 'lodash/intersection';
import { useDataProvider, Loading, Error } from 'react-admin';
import { useForm } from 'react-final-form';

import { SelectAllArrayInput } from '@src/custom/SelectAllArrayInput';

const StatesSelect = ({ source, ...props }) => {
    const form = useForm();
    const [states, setStates] = React.useState(null);
    const dataProvider = useDataProvider();
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);

    // Load all states
    React.useEffect(() => {
        const params = {
            pagination: { page: 1, perPage: 51 },
        };

        dataProvider
            .getList('states', params)
            .then(({ data }) => {
                setStates(data);
                setLoading(false);
                setError(null);
            }).catch((error) => {
                setStates(null);
                setLoading(false);
                setError(error);
            });
    }, [setStates, dataProvider, setLoading, setError]);

    // Update selected states
    React.useEffect(() => {
        if (!states) return;
        if (!form.getFieldState(source)) return;

        const selectedStates = form.getFieldState(source).value;
        form.change(source, intersection(selectedStates, states.map((s) => s.id)));
    }, [states, form, source]);

    if (loading) return <Loading />;
    if (error) return <Error error={error} errorInfo={{ componentStack: null }} />;

    return (
        <SelectAllArrayInput
            {...props}
            source={source}
            choices={states}
        />
    );
};

StatesSelect.propTypes = {
    source: PropTypes.string.isRequired,
};

export default React.memo(StatesSelect);
