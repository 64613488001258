import React from 'react';

import {
    Filter,
    SearchInput,
    SelectInput,
} from 'react-admin';

import { accessLevelFilterTypes } from '../data';

const AgentsFilter = (props) => (
    <Filter {...props}>
        <SearchInput alwaysOn source="query" />
        <SelectInput
            label="Access level"
            allowEmpty={false}
            source="access_level"
            choices={accessLevelFilterTypes}
        />
    </Filter>
);

export default AgentsFilter;
