import { Taxonomies } from '@src/global/taxonomies';

const categoriesListTitle = (basePath) => {
    let title = 'Categories';

    if (basePath.indexOf(Taxonomies.salesAndMarketing) > 0) {
        title = 'Sales and Marketing Categories';
    } else if (basePath.indexOf(Taxonomies.productNewsAndUpdates) > 0) {
        title = 'Product Updates Categories';
    } else if (basePath.indexOf(Taxonomies.jobAids) > 0) {
        title = 'Job Aids Categories';
    } else if (basePath.indexOf(Taxonomies.fieldManagementUpdates) > 0) {
        title = 'Field Managment News Categories';
    } else if (basePath.indexOf(Taxonomies.systemAndTechnical) > 0) {
        title = 'System and Technical Categories';
    } else if (basePath.indexOf(Taxonomies.collateral) > 0) {
        title = 'Marketing Collateral Categories';
    }

    return title;
};

const createCategoryTitle = (basePath) => {
    let title = 'Create ';

    if (basePath.indexOf(Taxonomies.salesAndMarketing) > 0) {
        title += 'Sales and Marketing category';
    } else if (basePath.indexOf(Taxonomies.productNewsAndUpdates) > 0) {
        title += 'Product Updates category';
    } else if (basePath.indexOf(Taxonomies.jobAids) > 0) {
        title += 'Job Aids category';
    } else if (basePath.indexOf(Taxonomies.fieldManagementUpdates) > 0) {
        title += 'Field Managment News category';
    } else if (basePath.indexOf(Taxonomies.systemAndTechnical) > 0) {
        title += 'System and Technical category';
    } else if (basePath.indexOf(Taxonomies.collateral) > 0) {
        title += 'Marketing Collateral category';
    }

    return title;
};

const editCategoryTitle = () => {
    let title = 'Edit ';

    if (window.location.href.indexOf(Taxonomies.salesAndMarketing) > 0) {
        title += 'Sales and Marketing category';
    } else if (window.location.href.indexOf(Taxonomies.productNewsAndUpdates) > 0) {
        title += 'Product Updates category';
    } else if (window.location.href.indexOf(Taxonomies.jobAids) > 0) {
        title += 'Job Aids category';
    } else if (window.location.href.indexOf(Taxonomies.fieldManagementUpdates) > 0) {
        title += 'Field Managment News category';
    } else if (window.location.href.indexOf(Taxonomies.systemAndTechnical) > 0) {
        title += 'System and Technical category';
    } else if (window.location.href.indexOf(Taxonomies.collateral) > 0) {
        title += 'Marketing Collateral category';
    }

    return title;
};

export {
    categoriesListTitle,
    createCategoryTitle,
    editCategoryTitle,
};
