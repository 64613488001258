import {
    cleanCommonParams,
    mapProductsParams,
    mapSalesChannelsParams,
    mapStatesParams,
} from './common';

const mapHomeLinkParams = (
    isCreate,
    {
        products,
        // eslint-disable-next-line camelcase
        sales_channels,
        states,
        ...params
    },
) => {
    return new Promise((resolve) => {
        resolve({
            // eslint-disable-next-line camelcase
            home_link: {
                ...cleanCommonParams(params),
                ...mapProductsParams(isCreate, products),
                ...mapSalesChannelsParams(isCreate, sales_channels),
                ...mapStatesParams(isCreate, states),
            },
        });
    });
};

export { mapHomeLinkParams };
