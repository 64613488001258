import React from 'react';
import PropTypes from 'prop-types';

import {
    TopToolbar,
    ListButton,
} from 'react-admin';

const CreateActions = ({ basePath, className, hasList }) => {
    return (
        <TopToolbar className={className}>
            { hasList && <ListButton basePath={basePath} />}
        </TopToolbar>
    );
};

CreateActions.propTypes = {
    basePath: PropTypes.string.isRequired,
    className: PropTypes.string,
    hasList: PropTypes.bool,
};

export default CreateActions;
