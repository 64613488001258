import React from 'react';

import {
    Datagrid,
    List,
    TextField,
} from 'react-admin';

import { Permissions } from '@src/global/permissions';

import {
    BulkActionButtons,
    EditButtonCustom,
    CapitalizedTextField,
    ExpirationDateField,
} from '@src/custom';

import { source } from './data';
import FilterForm from './filter-form';

const HomeLinksList = (props) => (
    <List
        {...props}
        title="Custom Homepage Links"
        filters={<FilterForm />}
        sort={{ field: source.updatedAt, order: 'DESC' }}
        hasCreate={Permissions('home_links', 'create') === true}
        bulkActionButtons={<BulkActionButtons resource="home_links" {...props} />}
    >
        <Datagrid>
            <TextField
                source={source.title}
            />
            <TextField
                source={source.description}
            />
            <ExpirationDateField
                label="Expiration Date"
                source={source.expiredAt}
            />
            <CapitalizedTextField
                label="Status"
                source={source.status}
            />
            <EditButtonCustom resource="home_links" {...props} />
        </Datagrid>
    </List>
);

export default HomeLinksList;
