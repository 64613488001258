import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Error, useGetList } from 'react-admin';

import SearchBar from '@src/custom/search-bar';
import Table from './attachment-selector-table';

const PER_PAGE = 15;

const AttachmentSelectorModal = (props) => {
    const { onAttachmentSelected } = props;

    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState(null);

    const { data, error, ids, loading, total } = useGetList(
        'attachments',
        { page, perPage: PER_PAGE },
        undefined,
        searchTerm ? { query: searchTerm } : undefined,
    );

    const handlePageChange = useCallback((page) => {
        setPage(page);
    }, [setPage]);

    const handleSearchChange = useCallback((searchTerm) => {
        setSearchTerm(searchTerm);
    }, [setSearchTerm]);

    if (error) {
        return <Error error={error} errorInfo={{ componentStack: null }} />;
    }

    const attachmentsData = {
        attachments: ids.map((id) => data[id]),
        page,
        total,
        perPage: PER_PAGE,
    };

    return (
        <>
            <SearchBar onChange={handleSearchChange} />
            <Table
                attachmentsData={attachmentsData}
                loading={loading}
                onPageChange={handlePageChange}
                onAttachmentSelected={onAttachmentSelected}
            />
        </>
    );
};

AttachmentSelectorModal.propTypes = {
    onAttachmentSelected: PropTypes.func.isRequired,
};

export default React.memo(AttachmentSelectorModal);
