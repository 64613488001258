import {
    cleanCommonParams,
    mapContentParams,
    mapImageParams,
    mapProductsParams,
} from './common';

const mapSocialSharingParam = (
    isCreate,
    {
        categories,
        content,
        holidayAndSeasonalCategories,
        image,
        safetyTipsCategories,
        socialSharingsCategories,
        products,
        ...params
    },
) => {
    return new Promise((resolve) => {
        const categoryIds = [].concat(
            holidayAndSeasonalCategories,
            safetyTipsCategories,
            socialSharingsCategories,
        );

        resolve({
            // eslint-disable-next-line camelcase
            social_sharing: {
                ...cleanCommonParams(params),
                ...mapContentParams(isCreate, content),
                ...mapImageParams(isCreate, image),
                ...mapProductsParams(isCreate, products),
                // eslint-disable-next-line camelcase
                category_ids: categoryIds,
            },
        });
    });
};

export { mapSocialSharingParam };
