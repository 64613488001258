import React from 'react';

import {
    Datagrid,
    DateField,
    List,
    TextField,
} from 'react-admin';

import { ExpirationDateField, ThumbnailField, EditButtonCustom } from '@src/custom';

import { source } from './data';
import Filter from './filter';

const AttachmentsList = (props) => (
    <List
        {...props}
        title="Attachments"
        filters={<Filter />}
        sort={{ field: source.createdAt, order: 'DESC' }}
        bulkActionButtons={null}
    >
        <Datagrid>
            <ThumbnailField
                label="Preview"
                source={source.thumbnailSource}
                title={source.thumbnailAlt}
            />
            <TextField
                label="Title"
                source={source.displayName}
            />
            <TextField
                source={source.associationsCount}
            />
            <DateField
                label="Uploaded On"
                source={source.createdAt}
            />
            <ExpirationDateField
                label="Expiration Date"
                source={source.expiredAt}
            />
            <EditButtonCustom resource="images" {...props} />
        </Datagrid>
    </List>
);

export default AttachmentsList;
