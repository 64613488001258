import React from 'react';
import PropTypes from 'prop-types';

import {
    ArrayField,
    SimpleShowLayout,
    TextField,
} from 'react-admin';

import { MaxWidthShow, ShowActions, SingleFieldListCustom } from '@src/custom';
import { source } from '../data';

const UserTitle = ({ record: user }) => {
    let title = 'User ';
    if (user) {
        title += [
            user.first_name || '',
            user.last_name || '',
        ].join(' ');
    }

    return <span>{title}</span>;
};
UserTitle.propTypes = {
    record: PropTypes.object,
};

const ShowAgent = (props) => (
    <MaxWidthShow
        {...props}
        title={<UserTitle />}
        actions={<ShowActions />}
    >
        <SimpleShowLayout>
            <TextField
                source={source.firstName}
            />
            <TextField
                source={source.lastName}
            />
            <TextField
                source={source.email}
            />
            <TextField
                source={source.accessLevel}
            />
            <TextField
                label="Producer ID"
                source={source.producerId}
            />
            <TextField
                label="Employee ID"
                source={source.employeeId}
            />
            <ArrayField
                label="Appointed States"
                source={source.states}
            >
                <SingleFieldListCustom linkType={false}>
                    <TextField source="name" />
                </SingleFieldListCustom>
            </ArrayField>
        </SimpleShowLayout>
    </MaxWidthShow>
);

export default ShowAgent;
