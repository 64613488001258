function mapGetOneRedirectData(responseData) {
    return {
        ...responseData,
    };
}

function mapGetListRedirectsData(responseData) {
    return responseData.map((redirect) => ({
        ...redirect,
    }));
}

export { mapGetOneRedirectData, mapGetListRedirectsData };
