import { cleanCommonParams } from './common';

const mapRoleParams = (isCreate, params) => {
    return new Promise((resolve) => {
        resolve({
            role: {
                ...cleanCommonParams(params),
            },
        });
    });
};

export { mapRoleParams };
