import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((_theme) => ({
    root: {
        'backgroundColor': '#EA3223',
        'color': 'white',
        'padding': '6px 16px',
        'box-shadow': '0px 0px 1px rgba(0, 0, 0,0.41), 2px 2px 3px rgba(0, 0, 0, 0.15)',
        '&&:hover': {
            'backgroundColor': '#943220',
            'box-shadow': '0px 0px 4px rgba(0, 0, 0,0.41), 2px 2px 3px rgba(0, 0, 0, 0.15)',
        },
    },
}));

const CancelButton = (props) => {
    const classes = useStyles();
    const { title, onClick } = props;

    return (
        <Button
            color="primary"
            className={classes.root}
            onClick={onClick}
        >
            {title}
        </Button>
    );
};

CancelButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

export default CancelButton;
