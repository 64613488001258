import React from 'react';
import PropTypes from 'prop-types';

import {
    TextInput,
    ReferenceArrayInput,
} from 'react-admin';

import {
    StyledSelectArrayInput,
} from '@src/custom';

import Grid from '@material-ui/core/Grid';

import { source } from './data';

const BrandForm = (props) => {
    const { resource } = props;

    return (
        <Grid container >
            <Grid item xs={12}>
                <TextInput
                    fullWidth
                    required
                    label="Name"
                    source={source.name}
                />
            </Grid>
            <Grid item xs={12}>
                <ReferenceArrayInput
                    key="select-array-input-brands"
                    required
                    disabled
                    label="Associated Logos"
                    reference={source.logosReference}
                    source={source.coBrandedVideoLogos}
                    resource={resource}
                >
                    <StyledSelectArrayInput fullWidth />
                </ReferenceArrayInput>
            </Grid>
        </Grid>
    );
};

BrandForm.propTypes = {
    resource: PropTypes.string.isRequired,
};

export default BrandForm;
