import { Taxonomies } from '@src/global/taxonomies';

const source = {
    authorName: 'authorName',
    content: 'content.content',
    contentExpiredAt: 'content.expired_at',
    createdAt: 'created_at',
    categories: 'categories',
    expiredAt: 'expired_at',
    holidayAndSeasonalCategories: 'holidayAndSeasonalCategories',
    holidayAndSeasonalCategoriesReference: `taxonomies/${Taxonomies.holidayAndSeasonal}/categories`,
    image: 'image',
    products: 'products',
    productsReference: 'products',
    publishedAt: 'published_at',
    safetyTipsCategories: 'safetyTipsCategories',
    safetyTipsCategoriesReference: `taxonomies/${Taxonomies.safetyTips}/categories`,
    socialSharingsCategories: 'socialSharingsCategories',
    socialSharingsCategoriesReference: `taxonomies/${Taxonomies.socialSharings}/categories`,
    status: 'status',
    title: 'title',
    updatedAt: 'updated_at',
};

export {
    source,
};
