import React from 'react';

import {
    Filter,
    SelectInput,
} from 'react-admin';

import { filterStatus } from './data';

const CategorizationsFilter = (props) => (
    <Filter {...props}>
        <SelectInput
            source="status"
            choices={filterStatus}
        />
    </Filter>
);

export default CategorizationsFilter;
