const MAX_DESCRIPTION_LENGTH = 180;

const validateDescriptionLength = (value) => {
    if (!value || value.length <= MAX_DESCRIPTION_LENGTH) {
        return null;
    }

    return `A description must contain less than ${MAX_DESCRIPTION_LENGTH} characters`;
};

export { validateDescriptionLength };
