import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import {
    ArrayInput,
    DateInput,
    SimpleFormIterator,
    TextInput,
} from 'react-admin';

import { source } from './data';

import {
    ExpirationDateInput,
} from '@src/custom';
import ThumbnailInput from '@src/custom/image_input/thumbnail_input';
import CopyButtonInput from './copy-button-input';
import DownloadButtonInput from './download-button-input';
import ReplaceFileInput from './replace-file-input';
import ResourceInput from './resource-input';

const ImageForm = (props) => {
    const { resource } = props;

    return (
        <>
            <Grid container justify="flex-end">
                <Grid item>
                    <ExpirationDateInput resource={resource} source={source} />
                </Grid>
                <Grid item>
                    <DateInput
                        label="Uploaded On"
                        source={source.createdAt}
                        inputProps={{ readOnly: true }}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        required
                        label="Title"
                        source={source.displayName}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        source={source.filename}
                        inputProps={{ readOnly: true }}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <ReplaceFileInput
                        source={source.filename}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={4}>
                    <ThumbnailInput label="Thumbnail" source={source.thumbnail} />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container>
                        <Grid item>
                            <CopyButtonInput />
                        </Grid>
                        <Grid item>
                            <DownloadButtonInput />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <ArrayInput label="Attached to" source={source.resources}>
                                <SimpleFormIterator disableAdd disableRemove>
                                    <ResourceInput />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

ImageForm.propTypes = {
    resource: PropTypes.string.isRequired,
};

export default ImageForm;
