import React from 'react';
import PropTypes from 'prop-types';

import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import { useDataProvider, Loading, Error } from 'react-admin';
import { useField, useForm } from 'react-final-form';

import { getProductIds } from './utils';

import { SelectAllArrayInput } from '@src/custom/SelectAllArrayInput';

const ProductStatesSelect = ({ productsSource, source, ...props }) => {
    const form = useForm();
    const { input: { value: selectedProducts } } = useField(productsSource);
    const [states, setStates] = React.useState(null);
    const dataProvider = useDataProvider();
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);

    const ref = React.useRef();

    // Load states associated with selected products when selected products are changed
    React.useEffect(() => {
        const productIds = getProductIds(selectedProducts);
        // Sometimes there may be a new instance of `selectedProduct`
        // but values and ids stay same. In this case we don't want
        // to make new request
        if (isEqual(productIds, ref.current)) return;

        ref.current = productIds;

        const params = {
            pagination: { page: 1, perPage: 51 },
        };

        if (productIds) {
            // eslint-disable-next-line quote-props
            params.filter = { 'product_ids': productIds };
        }

        dataProvider
            .getList('states', params)
            .then(({ data }) => {
                setStates(data);
                setLoading(false);
                setError(null);
            }).catch((error) => {
                setStates(null);
                setLoading(false);
                setError(error);
            });
    }, [selectedProducts, setStates, dataProvider, setLoading, setError]);

    // Update selected states to include only states
    // that are associated with selected products
    React.useEffect(() => {
        if (!states) return;
        if (!form.getFieldState(source)) return;

        const selectedStates = form.getFieldState(source).value;
        form.change(source, intersection(selectedStates, states.map((s) => s.id)));
    }, [states, form, source]);

    if (loading) return <Loading />;
    if (error) return <Error error={error} errorInfo={{ componentStack: null }} />;

    return (
        <SelectAllArrayInput
            {...props}
            source={source}
            choices={states}
        />
    );
};

ProductStatesSelect.propTypes = {
    source: PropTypes.string.isRequired,
    productsSource: PropTypes.string.isRequired,
};

export default React.memo(ProductStatesSelect);
