import React from 'react';

import { SimpleForm } from 'react-admin';

import { validate } from './validate';

import { EditActions, MaxWidthEdit, EditWithNewVersionToolbar } from '@src/custom';
import Form from './Form';

const EditAlert = (props) => (
    <MaxWidthEdit
        title="Edit Alert"
        actions={<EditActions />}
        {...props}
    >
        <SimpleForm
            toolbar={<EditWithNewVersionToolbar />}
            redirect="edit"
            submitOnEnter={false}
            validate={validate}
        >
            <Form {...props} />
        </SimpleForm>
    </MaxWidthEdit>
);

export default EditAlert;
