import {
    mapProductsForList,
    mapProductsForOne,
    mapStatesForList,
    mapStatesForOne,
    mapBrandsForList,
    mapBrandsForOne,
} from './common';

function mapGetOneCoBrandedVideoTemplateData(responseData) {
    return {
        ...responseData,
        ...mapProductsForOne(responseData),
        ...mapStatesForOne(responseData),
        ...mapBrandsForOne(responseData),
    };
}

function mapGetListCoBrandedVideoTemplatesData(responseData) {
    return responseData.map((data) => ({
        ...data,
        ...mapProductsForList(data),
        ...mapStatesForList(data),
        ...mapBrandsForList(data),
    }));
}

export { mapGetOneCoBrandedVideoTemplateData, mapGetListCoBrandedVideoTemplatesData };
