import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Edit, useNotify, useRefresh } from 'react-admin';

const styles = {
    root: {
        maxWidth: '1200px',
    },
};

const MaxWidthEdit = ({ classes, ...props }) => {
    const notify = useNotify();
    const refresh = useRefresh();

    const handleSuccess = React.useCallback(() => {
        // eslint-disable-next-line camelcase
        notify('ra.notification.updated', 'info', { smart_count: 1 });
        refresh();
    }, [notify, refresh]);

    return (
        <Edit
            className={classes.root}
            mutationMode="pessimistic"
            onSuccess={handleSuccess}
            {...props}
        />
    );
};

MaxWidthEdit.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MaxWidthEdit);
