import React from 'react';
import PropTypes from 'prop-types';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Button } from 'react-admin';

import { useCopyStringToClipboard } from '@src/hooks/use-copy-string-to-clipboard';

const CopyImageURLButton = ({ image }) => {
    const copyCallback = useCopyStringToClipboard(image.source);

    return (
        <Button
            label="Copy URL"
            onClick={copyCallback}
        >
            <FileCopyIcon />
        </Button>
    );
};

CopyImageURLButton.propTypes = {
    image: PropTypes.shape({
        source: PropTypes.string.isRequired,
    }).isRequired,
};

export default CopyImageURLButton;
