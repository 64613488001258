import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import {
    DateInput,
    ReferenceArrayInput,
    SelectInput,
    TextInput,
} from 'react-admin';

import {
    source,
    staticStatus,
    staticCategory,
} from './data';

import { FirstRow, SecondRow } from '@src/components/FormHeader';

import {
    AttachmentsInput,
    ContentImagesInput,
    AddToHomepageField,
    ExpirationDateInput,
    FeedInputs,
    RichTextInput,
    ProductStatesSelect,
    StyledSelectArrayInput,
    TitleSlug,
    RoleTypeInput,
} from '@src/custom';

const AlertForm = (props) => {
    const { resource } = props;

    return (
        <>
            <FirstRow key="first-row" {...props} source={source}>
                <ExpirationDateInput resource={resource} source={source} />
                <DateInput
                    required
                    label="Effective From"
                    source={source.effectiveFrom}
                />
                <DateInput
                    label="Effective Until"
                    source={source.effectiveUntil}
                />
                <DateInput
                    label="Archived At"
                    source={source.archivedAt}
                />
                <SelectInput
                    label="Alert Status"
                    source={source.status}
                    choices={staticStatus}
                />
            </FirstRow>
            <SecondRow key="second-row">
                <RoleTypeInput displayLeft source={source.roleType} />
                <AddToHomepageField resource={resource} source={source} />
                <FeedInputs resource={resource} />
            </SecondRow>
            <TitleSlug
                key="title-slug"
                required
                source={source}
                pageUrlPart="alerts"
                {...props}
            />
            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        label="Message (optional)"
                        source={source.message}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        label="Internal Note"
                        source={source.note}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <RichTextInput label="Content" source={source.content} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DateInput
                        label="Content Expiration Date"
                        source={source.contentExpiredAt}
                    />
                </Grid>
                <Grid item md xs={12}>
                    <ContentImagesInput
                        label="Expiration dates for embedded content images"
                        source={source.contentImages}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12}>
                    <AttachmentsInput
                        fullWidth
                        source={source.attachments}
                        label="Attachments"
                        accept="application/pdf"
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={6}>
                    <SelectInput
                        fullWidth
                        required
                        label="Category"
                        source={source.category}
                        choices={staticCategory}
                        options={{ fullWidth: true }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ReferenceArrayInput
                        key="select-array-input-sales-channel"
                        required
                        label="Sales Channel"
                        source={source.salesChannels}
                        reference={source.salesChannelsReference}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={6}>
                    <ReferenceArrayInput
                        key="select-array-input-products"
                        required
                        label="Applicable Products"
                        reference={source.productsReference}
                        source={source.products}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth groupBy="business_unit_name" />
                    </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ProductStatesSelect
                        key="select-array-input-states"
                        required
                        fullWidth
                        label="Applicable States"
                        source={source.states}
                        productsSource={source.products}
                        resource={resource}
                    />
                </Grid>
            </Grid>
        </>
    );
};

AlertForm.propTypes = {
    resource: PropTypes.string.isRequired,
    record: PropTypes.object,
};

export default AlertForm;
