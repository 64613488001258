import React from 'react';
import PropTypes from 'prop-types';

import {
    TextInput,
    SelectInput,
    ReferenceInput,
} from 'react-admin';

import {
    ImageInput,
    StatesSelect,
} from '@src/custom';

import Grid from '@material-ui/core/Grid';

import { source } from './data';

const CoBrandedVideoLogoForm = (props) => {
    const { resource, record } = props;

    return (
        <>
            <Grid container >
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        required
                        label="Name"
                        source={source.name}
                    />
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={12}>
                    <ReferenceInput
                        required
                        label="Brand"
                        source={source.brandId}
                        resource={resource}
                        record={record}
                        reference="brands"
                    >
                        <SelectInput
                            fullWidth
                            emptyText="Select brand"
                            optionText="name"
                        />
                    </ReferenceInput>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <StatesSelect
                        key="select-array-input-states"
                        fullWidth
                        label="Available States"
                        source={source.states}
                        resource={resource}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <ImageInput isRequired source={source.image} label="Brand Logo" folderName="brands" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ImageInput source={source.secondImage} label="Spanish Brand Logo" folderName="brands" />
                </Grid>
            </Grid>
        </>
    );
};

CoBrandedVideoLogoForm.propTypes = {
    resource: PropTypes.string.isRequired,
    record: PropTypes.object,
};

export default CoBrandedVideoLogoForm;
