import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
    RichTextField,
    SimpleShowLayout,
    TextField,
} from 'react-admin';

import { MaxWidthShow, ShowActions } from '@src/custom';
import { source } from './data';

const useStyles = makeStyles((theme) => ({
    error: {
        fontFamily: 'monospace',
    },
    log: {
        '& pre': {
            borderRadius: theme.shape.borderRadius,
            border: `1px solid ${theme.palette.divider}`,
            fontFamily: 'monospace',
            maxHeight: '500px',
            overflowY: 'scroll',
            padding: theme.spacing(1),
        },
    },
}));

const ShowCarrierCommissionImport = (props) => {
    const classes = useStyles();

    return (
        <MaxWidthShow
            {...props}
            title="Carrier commission import results"
            actions={<ShowActions />}
        >
            <SimpleShowLayout>
                <TextField
                    source={source.status}
                />
                <TextField
                    className={classes.error}
                    component="pre"
                    source={source.error}
                />
                <RichTextField
                    className={classes.log}
                    component="pre"
                    source={source.log}
                />
            </SimpleShowLayout>
        </MaxWidthShow>
    );
};

export default ShowCarrierCommissionImport;
