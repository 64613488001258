import React from 'react';
import PropTypes from 'prop-types';

import {
    Select,
    MenuItem,
    InputLabel,
    FormHelperText,
    FormControl,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import {
    FieldTitle,
    useInput,
    useChoices,
    InputHelperText,
} from 'react-admin';

const sanitizeRestProps = ({
    addLabel,
    allowEmpty,
    alwaysOn,
    basePath,
    choices,
    classNamInputWithOptionsPropse,
    componenInputWithOptionsPropst,
    crudGetMInputWithOptionsPropsatching,
    crudGetOInputWithOptionsPropsne,
    defaultValue,
    filter,
    filterToQuery,
    formClassName,
    initializeForm,
    input,
    isRequired,
    label,
    limitChoicesToValue,
    loaded,
    locale,
    meta,
    onChange,
    options,
    optionValue,
    optionText,
    perPage,
    record,
    reference,
    resource,
    setFilter,
    setPagination,
    setSort,
    sort,
    source,
    textAlign,
    translate,
    translateChoice,
    validation,
    ...rest
}) => rest;

const useStyles = makeStyles(
    (theme) => ({
        root: {
            minWidth: '160px',
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: theme.spacing(1 / 4),
        },
        select: {
            height: 'auto',
            overflow: 'auto',
        },
    }),
    { name: 'RaSelectArrayInput' }
);

/**
 * This component is rewritten original SelectArrayInput
 * But with support to "Select All" and changed select items style.
 * Changed:
 * - Updated `renderValue` method to show elements as text divided by comma
 * - Add 'Select All` element
 */
const StyledSelectArrayInput = (props) => {
    const {
        choices = [],
        groupBy,
        classes: classesOverride,
        className,
        format,
        helperText,
        label,
        margin = 'dense',
        onBlur,
        onChange,
        onFocus,
        options,
        optionText,
        optionValue,
        parse,
        resource,
        source,
        translateChoice,
        validate,
        variant = 'filled',
        ...rest
    } = props;

    const classes = useStyles(props);
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const { getChoiceText, getChoiceValue } = useChoices({
        optionText,
        optionValue,
        translateChoice,
    });
    const {
        input,
        isRequired,
        meta: { error, touched },
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        validate,
        ...rest,
    });

    const renderMenuItemOption = React.useCallback((choice) => getChoiceText(choice), [
        getChoiceText,
    ]);

    const renderMenuItem = React.useCallback(
        (choice) => {
            return choice ? (
                <MenuItem
                    key={getChoiceValue(choice)}
                    disabled={choice.disabled}
                    value={getChoiceValue(choice)}

                >
                    {renderMenuItemOption(choice)}
                </MenuItem>
            ) : null;
        },
        [getChoiceValue, renderMenuItemOption],
    );

    const addTrailingComma = (index, array) => {
        const currentIteration = index + 1;
        const totalIterations = array.length;

        if (currentIteration !== totalIterations) {
            return (
                <span>,&nbsp;</span>
            );
        }
    };

    const renderValue = React.useCallback((selected) => (
        <div className={classes.chips}>
            {
                selected &&

                selected.map((item) => (
                    choices.find((choice) => getChoiceValue(choice) === item)
                )).map((item, i, array) => (
                    <Typography
                        key={getChoiceValue(item)}
                        component="span"
                        variant="inherit"
                    >
                        { renderMenuItemOption(item) }
                        { addTrailingComma(i, array) }
                    </Typography>
                )).reduce((prev, curr) => [prev, ' ', curr])
            }
        </div>
    ), [classes.chips, choices, getChoiceValue, renderMenuItemOption]);

    if (!input) return false;

    let items = choices;
    if (groupBy) {
        const groupedNames = [...new Set(choices.map((x) => x[groupBy]))];

        items = groupedNames.map((gn) => {
            const _items = [{ id: gn, name: gn, disabled: true }];
            const _choices = _items.concat(choices.filter((p) => p[groupBy] === gn));

            return _choices;
        }).flat();
    }

    return (
        <FormControl
            margin={margin}
            className={classnames(classes.root, className)}
            error={touched && Boolean(error)}
            variant={variant}
            {...sanitizeRestProps(rest)}
        >
            <InputLabel
                ref={inputLabel}
                id={`${label}-outlined-label`}
                error={touched && Boolean(error)}
            >
                <FieldTitle
                    label={label}
                    source={source}
                    resource={resource}
                    isRequired={isRequired}
                />
            </InputLabel>
            <Select
                autoWidth
                multiple
                labelId={`${label}-outlined-label`}
                error={Boolean(touched && error)}
                renderValue={renderValue}
                data-testid="selectArray"
                {...input}
                value={input.value || []}
                {...options}
                labelWidth={labelWidth}
            >
                {items ? items.map(renderMenuItem) : ''}
            </Select>
            <FormHelperText error={touched && Boolean(error)}>
                <InputHelperText
                    touched={touched}
                    error={error}
                    helperText={helperText}
                />
            </FormHelperText>
        </FormControl>
    );
};

StyledSelectArrayInput.propTypes = {
    choices: PropTypes.arrayOf(PropTypes.object),
    groupBy: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.node,
    label: PropTypes.string,
    options: PropTypes.object,
    optionText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.element,
    ]),
    optionValue: PropTypes.string,
    resource: PropTypes.string,
    source: PropTypes.string,
    translateChoice: PropTypes.bool,
};

StyledSelectArrayInput.defaultProps = {
    options: {},
    optionText: 'name',
    optionValue: 'id',
    translateChoice: true,
};

export default React.memo(StyledSelectArrayInput);

