import React from 'react';

import { Permissions } from '@src/global/permissions';

import Error404 from '@src/components/error/Error404';

const Dashboard = () => {
    let redirectURL = null;

    if (Permissions('home_articles', 'read') === true) {
        redirectURL = '/featured_content';
    } else if (Permissions('home_links', 'read') === true) {
        redirectURL = '/home_links';
    } else if (Permissions('product_updates', 'read') === true) {
        redirectURL = '/product_updates';
    } else if (Permissions('underwriting_guidelines', 'read') === true) {
        redirectURL = '/underwriting_guidelines';
    } else if (Permissions('rate_bulletins', 'read') === true) {
        redirectURL = '/rate_bulletins';
    } else if (Permissions('trainings', 'read') === true) {
        redirectURL = '/trainings';
    } else if (Permissions('job_aids', 'read') === true) {
        redirectURL = '/job_aids';
    } else if (Permissions('webinars', 'read') === true) {
        redirectURL = '/webinars';
    } else if (Permissions('alerts', 'read') === true) {
        redirectURL = '/alerts';
    } else if (Permissions('products', 'read') === true) {
        redirectURL = '/products';
    } else if (Permissions('form_entries', 'read') === true) {
        redirectURL = '/contact_forms';
    } else if (Permissions('categories', 'read') === true) {
        redirectURL = '/taxonomies/product_news_and_updates/categories';
    } else if (Permissions('images', 'read') === true) {
        redirectURL = '/images';
    } else if (Permissions('page_expirations', 'read') === true) {
        redirectURL = '/page_expirations';
    } else if (Permissions('redirects', 'read') === true) {
        redirectURL = '/redirects';
    } else if (Permissions('settings', 'read') === true) {
        redirectURL = '/session_timeouts';
    } else if (Permissions('roles', 'read') === true) {
        redirectURL = '/roles';
    } else if (Permissions('users', 'read') === true) {
        redirectURL = '/employees';
    }

    if (redirectURL) {
        window.location.replace(redirectURL);
    } else {
        return <Error404 />;
    }
};

export default React.memo(Dashboard);
