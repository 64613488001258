import castArray from 'lodash/castArray';
import compact from 'lodash/compact';
import forEach from 'lodash/forEach';
import { HttpError } from 'react-admin';

const processValidationError = (response) => {
    return response.json().then((json) => {
        throw new HttpError(formatValidationError(json), response.status, json);
    });
};

const formatValidationError = (json) => {
    let errors = [];
    const { base, ...fields } = json;

    errors.push(formatFieldErrors(base));

    if (fields) {
        forEach(fields, (fieldErrors, field) => {
            const errorMsg = formatFieldErrors(fieldErrors);
            if (errorMsg) {
                errors.push(`${field}: ${errorMsg}`);
            }
        });
    }

    errors = compact(errors);
    return errors.length > 0 ? errors.join('; ') : 'Validation error';
};

const formatFieldErrors = (errors) => {
    if (!errors) return null;

    errors = castArray(errors);
    if (errors.length === 0) return null;

    return errors.join(', ');
};

export {
    processValidationError,
    formatValidationError,
};
