import padStart from 'lodash/padStart';
import momentTz from 'moment-timezone';

import removeCharacter from '@src/utils/removeCharacter';

const TZ_OFFSET = new Date().getTimezoneOffset() / 60;

const dateFormatter = (v) => {
    if (!v) {
        return null;
    }

    const d = new Date(`${v}T00:00:00`);
    if (isNaN(d)) {
        return null;
    }

    return d;
};

const dateParser = (v) => {
    if (!(v instanceof Date) || isNaN(v)) {
        return v;
    }

    const yy = padStart(v.getFullYear().toString(), 4, '0');
    const mm = padStart((v.getMonth() + 1).toString(), 2, '0');
    const dd = padStart(v.getDate().toString(), 2, '0');

    return `${yy}-${mm}-${dd}`;
};

const formatTimestampToDate = (date) => {
    if (!date) return date;
    date = new Date(date);

    return date.toLocaleDateString();
};

const dateParserRemoveTimeZone = (v) => {
    const regexp = /(\d{4})-(\d{2})-(\d{2})/;
    var match = regexp.exec(v);
    if (match === null) {
        return;
    }

    var year = match[1];
    var month = match[2];
    var day = match[3];

    if (TZ_OFFSET < 0) {
        var date = new Date(v);
        date.setDate(date.getDate() + 1);
        match = regexp.exec(date.toISOString());
        year = match[1];
        month = match[2];
        day = match[3];
    }
    const d = [year, month, day].join('-');
    return d;
};

const displayTimezone = (utcTime) => {
    const momentTimeZone = momentTz.tz.guess();
    const timeZone = momentTz.tz(utcTime, momentTimeZone).zoneAbbr();
    return removeCharacter(timeZone, 1);
};

export {
    dateFormatter,
    dateParser,
    dateParserRemoveTimeZone,
    displayTimezone,
    formatTimestampToDate,
};

