import React from 'react';

const subtitleStyle = {
    marginTop: '8px',
    fontSize: '14px',
    fontWeight: 'bold',
};

import logoImg from '@src/img/logos/foremost-logo.svg';

const Logo = () => (
    <div style={{ margin: '32px', width: '200px' }}>
        <img src={logoImg} alt="Foremost Logo" style={{ width: '100%' }} />
        <div style={subtitleStyle}>
            Producer News Portal Admin
        </div>
    </div>
);

export default Logo;
