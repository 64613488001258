import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {
    Select,
    MenuItem,
    FormControlLabel,
    Switch,
    InputLabel,
    Input,
    FormControl,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useNotify } from 'react-admin';
import { apiPost, apiGet } from '@src/services/api/helpers';

import { Permissions } from '@src/global/permissions';

import styles from './setting.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 70,
    },
    formControl: {
        width: '100%',
        minWidth: 120,
        paddingTop: 10,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const PromoNotificationSettingsEdit = () => {
    const notify = useNotify();
    const classes = useStyles();

    const [isLoading, setLoading] = React.useState(true);
    const [states, setStates] = React.useState([]);
    const [selectedStates, setSelectedStates] = React.useState([]);
    const [homepageBannerEnabled, setHomepageBannerEnabled] = React.useState(false);
    const [homepageBannerContent, setHomepageBannerContent] = React.useState('');
    const [homepageBannerUrl, setHomepageBannerUrl] = React.useState('');
    const [innerpageBannerEnabled, setInnerpageBannerEnabled] = React.useState(false);
    const [innerpageBannerContent, setInnerpageBannerContent] = React.useState('');
    const [innerpageBannerUrl, setInnerpageBannerUrl] = React.useState('');

    React.useEffect(() => {
        apiGet('/states').then((response) => {
            const { states } = response.data;

            setStates(states);
        }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
        });

        apiGet('/settings/promo_notification_banners').then((response) => {
            const values = response.data.promo_notification_setting.setting_values;

            setHomepageBannerEnabled(values.homepage_banner_enabled);
            setHomepageBannerContent(values.homepage_banner_content);
            setHomepageBannerUrl(values.homepage_banner_url);
            setInnerpageBannerEnabled(values.innerpage_banner_enabled);
            setInnerpageBannerContent(values.innerpage_banner_content);
            setInnerpageBannerUrl(values.innerpage_banner_url);
            setSelectedStates(JSON.parse(values.states));
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }, [setLoading]);

    const handleHomepageBannerEnabledChange = React.useCallback((e) => {
        setHomepageBannerEnabled(e.target.checked);
    }, [setHomepageBannerEnabled]);

    const handleHomepageBannerContentChange = React.useCallback((e) => {
        setHomepageBannerContent(e.target.value);
    }, [setHomepageBannerContent]);

    const handleHomepageBannerUrlChange = React.useCallback((e) => {
        setHomepageBannerUrl(e.target.value);
    }, [setHomepageBannerUrl]);

    const handleInnerpageBannerEnabledChange = React.useCallback((e) => {
        setInnerpageBannerEnabled(e.target.checked);
    }, [setInnerpageBannerEnabled]);

    const handleInnerpageBannerContentChange = React.useCallback((e) => {
        setInnerpageBannerContent(e.target.value);
    }, [setInnerpageBannerContent]);

    const handleInnerpageBannerUrlChange = React.useCallback((e) => {
        setInnerpageBannerUrl(e.target.value);
    }, [setInnerpageBannerUrl]);

    const handleStatesChange = React.useCallback((e) => {
        setSelectedStates(e.target.value);
    }, [setSelectedStates]);

    const handleSubmit = React.useCallback(() => {
        return apiPost('/settings/promo_notification_banners', {
            // eslint-disable-next-line camelcase
            promo_notification_setting: {
                // eslint-disable-next-line camelcase
                homepage_banner_enabled: homepageBannerEnabled,
                // eslint-disable-next-line camelcase
                homepage_banner_content: homepageBannerContent,
                // eslint-disable-next-line camelcase
                homepage_banner_url: homepageBannerUrl,
                // eslint-disable-next-line camelcase
                innerpage_banner_enabled: innerpageBannerEnabled,
                // eslint-disable-next-line camelcase
                innerpage_banner_content: innerpageBannerContent,
                // eslint-disable-next-line camelcase
                innerpage_banner_url: innerpageBannerUrl,
                states: JSON.stringify(selectedStates),
            },
        }).then((_res) => {
            notify('Successfully updated.');
        }).catch((error) => {
            notify('Error: content and URL can\'t be blank', 'error');
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }, [
        homepageBannerEnabled,
        homepageBannerContent,
        homepageBannerUrl,
        innerpageBannerEnabled,
        innerpageBannerContent,
        innerpageBannerUrl,
        selectedStates,
        notify,
    ]);

    return (
        <Grid container className={classes.root}>
            <Card >
                <CardContent>{
                    isLoading ? <p>Loading ...</p> : (
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography
                                    gutterBottom
                                    component="h2"
                                    display="inline"
                                    variant="title"
                                >
                                    Rediscover Foremost
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={styles.my_3}>
                                <Typography
                                    gutterBottom
                                    component="h2"
                                    display="inline"
                                    variant="title"
                                >
                                    Homepage Banner Promo
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Switch
                                        color="primary"
                                        checked={homepageBannerEnabled}
                                        disabled={Permissions('promo_notification_settings', 'update') !== true}
                                        onChange={handleHomepageBannerEnabledChange}
                                    />}
                                    label="Enabled"
                                />
                            </Grid>
                            {
                                homepageBannerEnabled && (
                                    <Grid item xs={12}>
                                        <FormControl variant="filled" className={classes.formControl}>
                                            <InputLabel
                                                shrink
                                                required
                                                id="homepage-banner-content-label"
                                                className={styles.input_label}
                                            >
                                                Content
                                            </InputLabel>
                                            <Input
                                                fullWidth
                                                labelId="homepage-banner-content-label"
                                                value={homepageBannerContent}
                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                onChange={handleHomepageBannerContentChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                )
                            }
                            {
                                homepageBannerEnabled && (
                                    <Grid item xs={12}>
                                        <FormControl variant="filled" className={classes.formControl}>
                                            <InputLabel
                                                shrink
                                                required
                                                id="homepage-banner-url-label"
                                                className={styles.input_label}
                                            >
                                                URL
                                            </InputLabel>
                                            <Input
                                                fullWidth
                                                labelId="homepage-banner-url-label"
                                                value={homepageBannerUrl}
                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                onChange={handleHomepageBannerUrlChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                )
                            }

                            <Grid item xs={12} className={styles.my_3}>
                                <Typography
                                    gutterBottom
                                    component="h2"
                                    display="inline"
                                    variant="title"
                                >
                                    Innerpage Banner Promo
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Switch
                                        color="primary"
                                        checked={innerpageBannerEnabled}
                                        disabled={Permissions('promo_notification_settings', 'update') !== true}
                                        onChange={handleInnerpageBannerEnabledChange}
                                    />}
                                    label="Enabled"
                                />
                            </Grid>
                            {
                                innerpageBannerEnabled && (
                                    <Grid item xs={12}>
                                        <FormControl variant="filled" className={classes.formControl}>
                                            <InputLabel
                                                shrink
                                                required
                                                id="innerpage-banner-content-label"
                                                className={styles.input_label}
                                            >
                                                Content
                                            </InputLabel>
                                            <Input
                                                fullWidth
                                                labelId="innerpage-banner-content-label"
                                                value={innerpageBannerContent}
                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                onChange={handleInnerpageBannerContentChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                )
                            }
                            {
                                innerpageBannerEnabled && (
                                    <Grid item xs={12}>
                                        <FormControl variant="filled" className={classes.formControl}>
                                            <InputLabel
                                                shrink
                                                required
                                                id="innerpage-banner-url-label"
                                                className={styles.input_label}
                                            >
                                                URL
                                            </InputLabel>
                                            <Input
                                                fullWidth
                                                labelId="innerpage-banner-url-label"
                                                value={innerpageBannerUrl}
                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                onChange={handleInnerpageBannerUrlChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                )
                            }
                            {
                                (homepageBannerEnabled || innerpageBannerEnabled) && states && (
                                    <Grid item xs={12}>
                                        <Typography
                                            gutterBottom
                                            component="h2"
                                            display="inline"
                                            variant="title"
                                        >
                                            States
                                        </Typography>
                                        <FormControl variant="filled" className={classes.formControl}>
                                            <InputLabel
                                                shrink
                                                required
                                                id="states"
                                                className={styles.input_label}
                                            >
                                                States
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                multiple
                                                labelId="session-timeouts-for-employees-label"
                                                value={selectedStates}
                                                disabled={Permissions('promo_notification_settings', 'update') !== true}
                                                onChange={handleStatesChange}
                                            >
                                                {
                                                    states.map((state) => {
                                                        return (
                                                            <MenuItem
                                                                key={state.slug}
                                                                value={state.slug}
                                                            >
                                                                { state.name }
                                                            </MenuItem>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )
                            }
                        </Grid>
                    )
                }
                </CardContent>
                <CardActions className={styles.toolbar}>
                    {
                        Permissions('promo_notification_settings', 'update') === true &&
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    }
                </CardActions>
            </Card>
        </Grid>
    );
};

PromoNotificationSettingsEdit.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default React.memo(PromoNotificationSettingsEdit);
