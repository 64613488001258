import React from 'react';

import {
    SimpleForm,
} from 'react-admin';

import { validate } from './validate';
import { source } from './data';

import { CreateActions, MaxWidthCreate } from '@src/custom';
import Form from './form';

const CreateHomeLink = (props) => (
    <MaxWidthCreate
        title="Create Custom Homepage Link"
        actions={<CreateActions />}
        {...props}
    >
        <SimpleForm
            initialValues={{ [source.status]: 'draft', [source.publishedAt]: new Date() }}
            redirect="edit"
            submitOnEnter={false}
            validate={validate}
        >
            <Form
                isCreate
                {...props}
            />
        </SimpleForm>
    </MaxWidthCreate>
);

export default CreateHomeLink;
