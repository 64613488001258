import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { apiPatch, apiGet, apiPut } from '@src/services/api/helpers';

import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import CancelIcon from '@material-ui/icons/Cancel';

import { linkToRecord } from 'react-admin';

import { Permissions } from '@src/global/permissions';
import { classToResource } from '@src/utils/model-helpers';

const useStyles = makeStyles(() => ({
    main: {
        position: 'absolute',
        left: 'calc(100% - 415px)',
        color: '#000',
        background: '#fff',
        top: '48px',
        width: '360px',
        maxHeight: '400px',
        overflowY: 'scroll',
        border: '1px lightgray solid',
        borderRadius: '4px',
        boxShadow: '-1px 4px 12px 5px rgba(176, 176, 176, 0.56)',

    },
    menu: {
        cursor: 'pointer',
        paddingRight: '16px',
        paddingLeft: '16px',
    },
    menuBadge: {
        cursor: 'pointer',
        marginRight: '16px',
    },
    titleIcon: {
        display: 'inline-block',
        float: 'left',
        marginRight: '12px',
    },
    titleWrapper: {
        display: 'inline-block',
        float: 'left',
    },
    titleRightLink: {
        display: 'inline-block',
        float: 'right',
        cursor: 'pointer',
    },
    titleRightIcon: {
        width: '32px',
        height: '32px',
    },
    title: {
        display: 'block',
        alignItems: 'center',
        padding: '12px',
        color: '#4153af',
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '16px',
        minHeight: '50px',
    },
    titleHint: {
        fontSize: '11px',
        fontWeight: '400',
    },
    list: {
        'padding': '12px 0',
        'li&:nth-child(odd)': {
            backgroundColor: '#eee',
        },
    },
    cancelIcon: {
        cursor: 'pointer',
        minWidth: '40px',
        textAlign: 'center',
        padding: '0 10px',
    },
    item: {
        'cursor': 'pointer',
        'width': '100%',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    itemPrimary: {
        color: 'black',
        fontWeight: 'bold',
        fontSize: '12px',
        paddingRight: '10px',
        lineHeight: '14px',
    },
    itemSecondary: {
        color: 'grey',
        fontSize: '0.75rem',
    },
}));

const AdminNotifications = () => {
    const classes = useStyles();
    const [isLoading, setLoading] = useState(true);
    const [adminNotifications, setAdminNotifications] = useState([]);
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        apiGet('/admin_notifications').then((response) => {
            const { data } = response;

            setAdminNotifications(data.admin_notifications);
        }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }, [setLoading]);

    const handleClick = useCallback(() => {
        setOpen(!isOpen);
        apiGet('/admin_notifications').then((response) => {
            const { data } = response;

            setAdminNotifications(data.admin_notifications);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }, [setLoading, setOpen, isOpen]);

    const handleClickAway = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleReviewClick = useCallback((id) => {
        apiPatch(`/admin_notifications/${id}/review`).then(() => {
            setAdminNotifications(adminNotifications.filter((an) => an.id !== id));
        }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }, [adminNotifications]);

    const handleReviewAllClick = useCallback(() => {
        apiPut('/admin_notifications/review_all').then(() => {
            setAdminNotifications([]);
        }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }, []);

    const handleItemClick = useCallback((an) => {
        const resource = classToResource(an.record_type);
        if (resource === 'social_sharings' || resource === 'field_management_news' || resource === 'articles') {
            return null;
        }

        if (Permissions(resource, 'update') === false) {
            return null;
        }

        const linkToResource = linkToRecord(`/${resource}`, an.resource.id, 'edit');
        window.location.href = linkToResource;
    }, []);

    return (
        <>
            <a
                className={classes.menu}
                onClick={handleClick}
            >
                <Badge badgeContent={adminNotifications.length} className={classes.menuBadge} color="primary">
                    <NotificationsNoneIcon />
                </Badge>
                NOTIFICATIONS
            </a>
            {
                isOpen && (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <Grid container spacing={2} radius={10} className={classes.main}>
                            <Grid item xs={12} md={12}>
                                <Typography variant="h6" className={classes.title}>
                                    <NotificationsNoneIcon className={classes.titleIcon} fontSize="large" />
                                    <div className={classes.titleWrapper}>
                                        NOTIFICATIONS<br />
                                        <small className={classes.titleHint}>You will see updates and modifications</small>
                                    </div>
                                    {
                                        adminNotifications.length > 0 &&
                                            <a
                                                className={classes.titleRightLink}
                                                onClick={handleReviewAllClick}
                                            >
                                                <img
                                                    src="/img/broom.svg"
                                                    alt="Clear All"
                                                    title="Clear All"
                                                    className={classes.titleRightIcon}
                                                />
                                            </a>
                                    }
                                </Typography>
                                <div className={classes.demo}>
                                    {
                                        isLoading && (
                                            <p>Loading...</p>
                                        )
                                    }
                                    {
                                        !isLoading && (
                                            <List>
                                                {
                                                    adminNotifications.map((an) => {
                                                        return (
                                                            <ListItem key={an.id} className={classes.list}>
                                                                <ListItemIcon className={classes.cancelIcon}>
                                                                    <CancelIcon
                                                                        fontSize="small"
                                                                        onClick={() => handleReviewClick(an.id)}
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    className={classes.item}
                                                                    primary={an.title}
                                                                    secondary={an.note ? an.note : null}
                                                                    primaryTypographyProps={{ className: classes.itemPrimary }}
                                                                    secondaryTypographyProps={{ className: classes.itemSecondary }}
                                                                    onClick={() => handleItemClick(an)}
                                                                />
                                                            </ListItem>
                                                        );
                                                    })
                                                }
                                            </List>
                                        )
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </ClickAwayListener>
                )
            }
        </>
    );
};

AdminNotifications.propTypes = {
    resource: PropTypes.string,
};

export default AdminNotifications;
