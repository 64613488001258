import React from 'react';

import { LOGOUT_URL } from '../../global/login';

import styles from './error.scss';

const handleLogout = (e) => {
    e.preventDefault();
    window.localStorage.removeItem('jwt');
    window.location.replace(LOGOUT_URL);
};

const Error403 = () => (
    <div id="home" className={styles.error_page_container}>
        <div className={styles.error_container}>
            <div className="row">
                <div className="col text-center">
                    <img
                        src="/img/Computer-Lock-Icon.png"
                        alt="Computer screen with a Lock Pad on the screen"
                    />
                    <h1>Access denied</h1>
                    <h2>The login ID you are using does not have administration privileges.</h2>
                    <p>
                        Try
                        { ' ' }
                        <a
                            href={LOGOUT_URL}
                            className={styles['ml-link_error']}
                            onClick={handleLogout}
                        >
                            logging out
                        </a>
                        { ' ' }
                        and using a different login ID.
                    </p>
                </div>
            </div>
        </div>
    </div>
);

export default React.memo(Error403);
