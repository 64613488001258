import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import { Labeled, useInput } from 'react-admin';

import CopyURLButton from './copy-video-url-button';
import VideoChooserButton from './video_chooser_button';
import VideoUploaderButton from './video_uploader_button';
import RemoveButton from './remove-video-button';

const useStyles = makeStyles((theme) => ({
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    thumbnailContainer: {
        position: 'relative',
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

const VideoInput = ({
    fullWidth = true,
    helperText,
    label = 'Upload Video',
    ...props
}) => {
    const { input, meta, isRequired } = useInput(props);
    const classes = useStyles();

    const handleChooserChoice = useCallback((video) => {
        input.onChange(video);
    }, [input]);

    const handleVideoUploaded = useCallback((video) => {
        input.onChange(video);
    }, [input]);

    const handleVideoRemoved = useCallback(() => {
        input.onChange(null);
        input.onBlur();
    }, [input]);

    return (
        <Labeled
            fullWidth={fullWidth}
            label={label}
            isRequired={isRequired}
            meta={meta}
            input={input}
            resource={props.resource}
            source={props.source}
        >
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <Grid container spacing={0}>
                            {
                                input.value &&
                                    <Grid item className={classes.thumbnailContainer} xs={12} sm={3} md={4}>
                                        <CardMedia
                                            component="video"
                                            src={get(input.value, 'source')}
                                        />
                                    </Grid>
                            }
                            <Grid item className={classes.details} xs={12} sm={9} md={8}>
                                <CardActions className={classes.actions}>
                                    <VideoChooserButton folderName="featured" onVideoChoosed={handleChooserChoice} />
                                    <VideoUploaderButton folderName="featured" onVideoUploaded={handleVideoUploaded} />
                                </CardActions>
                                {
                                    input.value &&
                                        <CardActions className={classes.actions}>
                                            <CopyURLButton video={input.value} />
                                            <RemoveButton
                                                video={input.value}
                                                onRemove={handleVideoRemoved}
                                            />
                                        </CardActions>
                                }
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                { meta.touched && meta.error && <Grid item xs={12}><FormHelperText>{meta.error}</FormHelperText></Grid> }
                { helperText && <Grid item xs={12}><FormHelperText>{helperText}</FormHelperText></Grid> }
            </Grid>
        </Labeled>
    );
};

VideoInput.propTypes = {
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    resource: PropTypes.string,
    source: PropTypes.string,
    required: PropTypes.bool,
};

export default React.memo(VideoInput);
