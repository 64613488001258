import { useCallback } from 'react';
import { useNotify } from 'react-admin';

import copyStringToClipboard from '@src/utils/copyStringToClipboard';

const useCopyStringToClipboard = (value) => {
    const notify = useNotify();

    return useCallback((e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        copyStringToClipboard(value)
            .then(() => {
                notify('Value has been copied to clipboard', 'info');
            }).catch(() => {
                notify('Value colud not be copied to clipboard', 'error');
            });
    }, [value, notify]);
};

export {
    useCopyStringToClipboard,
};
