import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { TextField } from 'react-admin';

const useStyles = makeStyles({
    root: {
        textTransform: 'capitalize',
    },
});

const CapitalizedTextField = (props) => {
    const classes = useStyles();

    return (
        <TextField
            className={classes.root}
            {...props}
        />
    );
};

export default CapitalizedTextField;
