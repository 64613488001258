import React from 'react';
import PropTypes from 'prop-types';

import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import { useInput } from 'react-admin';

import ImageChooserButton from './image_chooser_button';
import ImageUploaderButton from './image_uploader_button';

const ThumbnailInput = ({
    required,
    ...props
}) => {
    const { input, meta } = useInput(props);

    const handleChooserChoice = React.useCallback((image) => {
        input.onChange(image);
    }, [input]);

    const handleImageUploaded = React.useCallback((image) => {
        input.onChange(image);
    }, [input]);

    return (
        <Grid container spacing={2} >
            {
                input.value &&
                    <Grid item xs={12}>
                        <img
                            src={input.value.source}
                            alt={input.value.alt}
                            style={{ maxWidth: '100%' }}
                        />
                    </Grid>
            }
            <Grid item xs={12}>
                <ImageChooserButton folderName="thumbnail" onImageChoosed={handleChooserChoice} />
                <ImageUploaderButton folderName="thumbnail" onImageUploaded={handleImageUploaded} />
            </Grid>
            { meta.touched && meta.error && <Grid item xs={12}><FormHelperText>{meta.error}</FormHelperText></Grid> }
        </Grid>
    );
};

ThumbnailInput.propTypes = {
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    resource: PropTypes.string,
    source: PropTypes.string,
    required: PropTypes.bool,
};

export default React.memo(ThumbnailInput);
