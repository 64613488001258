import React from 'react';

import {
    Filter,
    SearchInput,
    SelectInput,
} from 'react-admin';

import expirationFilters from '@src/global/choices/expiration-filters';
import status from '@src/global/choices/status';
import { attachOptions } from './data';

const AttachmentsFilter = (props) => (
    <Filter {...props}>
        <SearchInput alwaysOn source="query" />
        <SelectInput
            source="expiration"
            choices={expirationFilters}
        />
        <SelectInput
            source="attached"
            choices={attachOptions}
        />
        <SelectInput
            source="status"
            choices={status}
        />
    </Filter>
);

export default AttachmentsFilter;
