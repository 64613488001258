import { Taxonomies } from '@src/global/taxonomies';
import {
    mapAuthorForOne,
    mapProductsForList,
    mapProductsForOne,
} from './common';

function mapCategories(responseData) {
    if (!responseData.categories) {
        return responseData.categories;
    }

    return {
        categories: responseData.categories.sort((a, b) => (a.name > b.name) ? 1 : -1),
        socialSharingsCategories: responseData.categories
            .filter((category) => category.taxonomy === Taxonomies.socialSharings)
            .map((item) => item.slug),
        safetyTipsCategories: responseData.categories
            .filter((category) => category.taxonomy === Taxonomies.safetyTips)
            .map((item) => item.slug),
        holidayAndSeasonalCategories: responseData.categories
            .filter((category) => category.taxonomy === Taxonomies.holidayAndSeasonal)
            .map((item) => item.slug),
    };
}

function mapGetOneSocialSharingsData(responseData) {
    return {
        ...responseData,
        ...mapCategories(responseData),
        ...mapProductsForOne(responseData),
        ...mapAuthorForOne(responseData),
    };
}

function mapGetListSocialSharingsData(responseData) {
    return responseData.map((sharing) => ({
        ...sharing,
        ...mapCategories(sharing),
        ...mapProductsForList(sharing),
    }));
}

export { mapGetOneSocialSharingsData, mapGetListSocialSharingsData };
