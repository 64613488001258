import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import { TextInput } from 'react-admin';
import { Field } from 'react-final-form';

import { source } from '../data';

const HomeOfficeAssociateForm = () => (
    <>
        <Field
            type="hidden"
            name={source.accessLevel}
            component="input"
        />
        <Grid container>
            <Grid item xs={12} md={6}>
                <TextInput
                    fullWidth
                    label="Employee ID"
                    source={source.employeeId}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextInput
                    fullWidth
                    source={source.email}
                />
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={12} md={5}>
                <TextInput
                    fullWidth
                    source={source.firstName}
                />
            </Grid>
            <Grid item xs={12} md={2}>
                <TextInput
                    fullWidth
                    source={source.middleInitial}
                />
            </Grid>
            <Grid item xs={12} md={5}>
                <TextInput
                    fullWidth
                    source={source.lastName}
                />
            </Grid>
        </Grid>
    </>
);

HomeOfficeAssociateForm.propTypes = {
    isCreate: PropTypes.bool,
    resource: PropTypes.string.isRequired,
};

export default HomeOfficeAssociateForm;
