import { source } from './data';
import { isValidUrl } from '../../../utils/isValidUrl';

import { addError, validateDescriptionLength } from '../../../validations';

function hasCategoryOrProduct(values) {
    return (
        // (values[source.salesAndMarketingCategories] && values[source.salesAndMarketingCategories].length > 0) ||
        (values[source.systemCategories] && values[source.systemCategories].length > 0) ||
        (values[source.productCategories] && values[source.productCategories].length > 0) ||
        (values[source.products] && values[source.products].length > 0)
    );
}

function validatePublished(values, errors) {
    if (!values[source.publishedAt]) {
        errors[source.publishedAt] = ['A Publication Date is required'];
    }

    if (!values[source.title]) {
        errors[source.title] = ['A Title is required'];
    }

    if (!values[source.description]) {
        errors[source.description] = ['A Description is required'];
    }

    if (!values[source.externalUrl]) {
        errors[source.externalUrl] = ['External eLearning URL is required'];
    }

    if (!values[source.salesChannels] || values[source.salesChannels].length <= 0) {
        errors[source.salesChannels] = ['A Sales Channel is required'];
    }

    if (!hasCategoryOrProduct(values)) {
        // errors[source.salesAndMarketingCategories] = ['At least 1 category or product is required'];
        errors[source.systemCategories] = ['At least 1 category or product is required'];
        errors[source.productCategories] = ['At least 1 category or product is required'];
        errors[source.products] = ['At least 1 category or product is required'];
    }
}

export const validate = (values) => {
    const errors = {};

    if (!values[source.slug]) {
        errors[source.slug] = ['An URL is required'];
    }

    addError(
        errors,
        source.description,
        validateDescriptionLength(values[source.description]),
    );

    if (values[source.externalUrl] && !isValidUrl(values[source.externalUrl])) {
        errors[source.externalUrl] = ['External eLearning URL must be an URL'];
    }

    if (values[source.status] === 'published') {
        validatePublished(values, errors);
    }

    return errors;
};
