import React from 'react';

import { SimpleForm } from 'react-admin';

import { CreateActions, MaxWidthCreate } from '@src/custom';
import Form from './Form';

const CreateCarrierCommissionImport = (props) => (
    <MaxWidthCreate
        {...props}
        title="New import"
        actions={<CreateActions />}
    >
        <SimpleForm
            redirect="list"
            submitOnEnter={false}
        >
            <Form {...props} />
        </SimpleForm>
    </MaxWidthCreate>
);

export default CreateCarrierCommissionImport;
