import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import { dateFormatter } from '@src/utils/dateHelpers';

const toLocaleStringSupportsLocales = (() => {
    try {
        new Date().toLocaleString('i');
    } catch (error) {
        return error instanceof RangeError;
    }

    return false;
})();

const FormattedDateField = (props) => {
    const {
        source,
        record,
    } = props;

    const value = dateFormatter(record[source]);
    const date = value instanceof Date ? value : new Date(record[source]);

    const options = {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
    };

    const dateString = toLocaleStringSupportsLocales ? date.toLocaleString('en-us', options) : JSON.stringify(date);

    return (
        <Typography
            component="span"
            variant="body2"
        >
            {dateString}
        </Typography>
    );
};

FormattedDateField.propTypes = {
    source: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
};

export default FormattedDateField;
