import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import EditIcon from '@material-ui/icons/Edit';
import { Button } from 'react-admin';

import Modal from '@src/custom/modal-window';
import Edit from './edit-image';

const EditImageButton = (props) => {
    const { image, onUpdated } = props;

    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenButtonClick = useCallback(() => {
        setModalOpen(true);
    }, [setModalOpen]);

    const handleClose = useCallback(() => {
        setModalOpen(false);
    }, [setModalOpen]);

    const handleUpdated = useCallback((image) => {
        setModalOpen(false);
        onUpdated(image);
    }, [onUpdated, setModalOpen]);

    return (
        <>
            <Button
                label="Edit"
                onClick={handleOpenButtonClick}
            >
                <EditIcon />
            </Button>

            {
                modalOpen &&
                    <Modal
                        open={modalOpen}
                        title="Edit image"
                        onClose={handleClose}
                    >
                        <Edit
                            imageId={image.id}
                            onCancel={handleClose}
                            onUpdated={handleUpdated}
                        />
                    </Modal>

            }
        </>
    );
};

EditImageButton.propTypes = {
    image: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    onUpdated: PropTypes.func.isRequired,
};

export default EditImageButton;
