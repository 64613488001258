import {
    mapStatesForList,
    mapStatesForOne,
} from './common';

function mapGetOneCoBrandedVideoLogoData(responseData) {
    return {
        ...responseData,
        ...mapStatesForOne(responseData),
    };
}

function mapGetListCoBrandedVideoLogosData(responseData) {
    return responseData.map((data) => ({
        ...data,
        ...mapStatesForList(data),
    }));
}

export { mapGetOneCoBrandedVideoLogoData, mapGetListCoBrandedVideoLogosData };
