import get from 'lodash/get';

import { addError } from '@src/validations';

import { source } from './data';

function hasCategoryOrProduct(values) {
    return (
        (get(values, source.socialSharingsCategories, []).length > 0) ||
        (get(values, source.safetyTipsCategories, []).length > 0) ||
        (get(values, source.holidayAndSeasonalCategories, []).length > 0) ||
        (get(values, source.products, []).length > 0)
    );
}

function validatePublished(values, errors) {
    if (!get(values, source.content)) {
        addError(errors, source.content, 'A Social Content is required');
    }
    if (!get(values, source.publishedAt)) {
        addError(errors, source.publishedAt, 'A Publication Date is required');
    }
    if (!hasCategoryOrProduct(values)) {
        addError(errors, source.socialSharingsCategories, 'At least 1 category or product is required');
        addError(errors, source.safetyTipsCategories, 'At least 1 category or product is required');
        addError(errors, source.holidayAndSeasonalCategories, 'At least 1 category or product is required');
        addError(errors, source.products, 'At least 1 category or product is required');
    }
}

export const validate = (values) => {
    const errors = {};

    if (!values[source.title]) {
        errors[source.title] = ['A Title is required'];
    }

    const content = get(values, source.content);
    if (content && content.length > 280) {
        addError(errors, source.content, 'Content cannot exceed 280 characters');
    }

    if (values[source.status] === 'published') {
        validatePublished(values, errors);
    }

    return errors;
};

