import React from 'react';

import {
    Filter,
    ReferenceInput,
    SelectInput,
} from 'react-admin';

// import { StyledSelectArrayInput } from '@src/custom';
import { source } from './data';

const ProductsFormFilter = (props) => {
    return (
        <Filter {...props}>
            <ReferenceInput
                required
                label="Business Unit"
                source={source.businessUnitId}
                reference="business_units"
            >
                <SelectInput
                    fullWidth
                    emptyText="Select Business Unit"
                    optionText="name"
                />
            </ReferenceInput>
        </Filter>
    );
};

export default ProductsFormFilter;
