import {
    cleanCommonParams,
} from './common';

const mapImageParams = (
    isCreate,
    {
        resources,
        ...params
    },
) => {
    return new Promise((resolve) => {
        resolve({
            image: {
                ...cleanCommonParams(params),
            },
        });
    });
};

export { mapImageParams };
