import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import {
    Datagrid,
    ExportButton,
    List,
    TextField,
    TopToolbar,
} from 'react-admin';
import { Link } from 'react-router-dom';

import FilterForm from './FilterForm';

import { source } from './data';

const styles = (theme) => ({
    publishIcon: {
        marginRight: theme.spacing(),
        fontSize: 20,
    },
    button: {
        margin: theme.spacing(),
    },
});

const CustomCommissionActions = withStyles(styles)(({ classes, className }) => (
    <TopToolbar className={className}>
        <Button
            component={Link}
            to="/carrier_commissions/imports"
            color="primary"
            size="small"
        >
            <PublishIcon className={classes.publishIcon} />
            Import
        </Button>
        <ExportButton />
    </TopToolbar>
));

const CarrierCommissionsList = (props) => (
    <List
        {...props}
        title="Carrier Commissions"
        filters={<FilterForm />}
        actions={<CustomCommissionActions />}
        sort={{ field: source.updatedAt, order: 'DESC' }}
    >
        <Datagrid>
            <TextField
                label="State"
                source={source.stateAbbreviation}
            />
            <TextField
                label="Product"
                source={source.productName}
            />
            <TextField
                label="Carrier"
                source={source.carrierName}
            />
            <TextField
                label="New Business (Direct)"
                source={source.nbPremDirect}
            />
            <TextField
                label="Renewal (Direct)"
                source={source.renDirect}
            />
            <TextField
                label="New Business (Referral)"
                source={source.nbPremReferral}
            />
            <TextField
                label="Renewal (Referral)"
                source={source.renReferral}
            />
        </Datagrid>
    </List>
);

export default CarrierCommissionsList;
