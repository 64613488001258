export const source = {
    accessLevel: 'access_level',
    email: 'email',
    employeeId: 'employee_id',
    firstName: 'first_name',
    lastName: 'last_name',
    middleInitial: 'middle_initial',
    producerId: 'producer_id',
    states: 'states',
    showDraft: 'show_draft',
    showExpired: 'show_expired',
    showArchived: 'show_archived',
    showScheduled: 'show_scheduled',
    roleId: 'role_id',
    roles: 'roles',
    notifyAboutOutdated: 'notify_about_outdated',
    outdatedNotifyDays: 'outdated_notify_days',
    notifyAboutPageExpiration: 'notify_about_page_expiration',
    notifyAboutItemExpiration: 'notify_about_item_expiration',
    expireIn1Day: 'expire_in_1_day',
    expireIn7Days: 'expire_in_7_days',
    expireIn30Days: 'expire_in_30_days',
};

export const accessLevelFilterTypes = [
    { id: '', name: 'All' },
    { id: 'agent', name: 'Agents' },
    { id: 'manager', name: 'Managers' },
];

export const outdatedNotifyOptions = [
    { id: '0', name: 'Never' },
    { id: '7', name: '7 days' },
    { id: '14', name: '14 days' },
    { id: '30', name: '30 days' },
];
