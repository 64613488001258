/**
 * Get clean product ids from array of product objects or array of product ids
 * @param {Object[]|string[]} products - Array of product object that have `id`
 *   field or array of product ids.
 * @param {string} products[].id - Product ID
 * @returns {string[]} - Array of product ids with removed temp prefixes.
 */
const getProductIds = (products) => {
    if (!products || products.length === 0) return null;

    if (typeof products[0] === 'object') {
        products = products.map((p) => p.id);
    }

    return products;
};

export { getProductIds };
