import { Taxonomies } from '@src/global/taxonomies';

export const source = {
    attachments: 'attachments',
    authorName: 'authorName',
    authorEmail: 'authorEmail',
    authorId: 'author_id',
    categoryIds: 'category_ids',
    categories: 'categories',
    categoriesReference: `taxonomies/${Taxonomies.fieldManagementUpdates}/categories`,
    content: 'content.content',
    contentExpiredAt: 'content.expired_at',
    contentImages: 'content_images',
    dateCreatedAt: 'created_at',
    datePublishedAt: 'published_at',
    dateUpdatedAt: 'updated_at',
    description: 'description',
    expiredAt: 'expired_at',
    id: 'id',
    image: 'image',
    isFeatured: 'is_featured',
    note: 'note',
    productIds: 'product_ids',
    products: 'products',
    productsReference: 'products',
    salesChannelIds: 'sales_channel_ids',
    salesChannels: 'sales_channels',
    salesChannelsReference: 'sales_channels',
    slug: 'slug',
    stateIds: 'state_ids',
    states: 'states',
    statesReference: 'states',
    status: 'status',
    title: 'title',
    isHomeArticle: 'is_home_article',
    versionDescription: 'version_description',
};
