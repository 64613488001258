function mapGetOneRoleData(responseData) {
    return {
        ...responseData,
    };
}

function mapGetListRolesData(responseData) {
    return responseData.map((role) => ({
        ...role,
    }));
}

export { mapGetOneRoleData, mapGetListRolesData };
