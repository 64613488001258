import {
    mapLogosForOne,
    mapLogosForList,
} from './common';

function mapGetOneBrandData(responseData) {
    return {
        ...responseData,
        ...mapLogosForOne(responseData),
    };
}

function mapGetListBrandsData(responseData) {
    return responseData.map((data) => ({
        ...data,
        ...mapLogosForList(data),
    }));
}

export { mapGetOneBrandData, mapGetListBrandsData };
