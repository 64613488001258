import { apiGet, apiPost } from './helpers';
import { chunkedUploadToCloudinary } from './cloudinary-helpers';
import { removeFileExtenstion } from '@src/utils/filename';

const getVideos = (params) => {
    return apiGet('/videos', { params })
        .then((res) => {
            return {
                videos: res.data.videos,
                page: Number(res.headers.page),
                total: Number(res.headers.total),
                perPage: Number(res.headers['per-page']),
            };
        });
};

const getApiSignedUpload = (publicId, folder) => {
    const params = {
        'upload_params[public_id]': publicId,
        'upload_params[folder]': folder,
    };

    return apiGet('/videos/new', { params })
        .then((response) => response.data.signed_upload);
};

const getVideo = (id) => {
    return apiGet(`/videos/${id}`).then((res) => res.data.video);
};

const createVideo = (videoData) => {
    return apiPost('/videos', { video: videoData })
        .then((response) => {
            return response.data.video;
        });
};

const uploadVideo = async (file, folderName) => {
    return getApiSignedUpload(removeFileExtenstion(file.name), folderName)
        .then(async (signedUpload) => {
            const response = await chunkedUploadToCloudinary(file, signedUpload.upload_url, signedUpload.post_body);

            return {
                source: response.secure_url,
                format: response.format,
                folder: folderName,
                bytes: response.bytes,
                duration: response.duration,
            };
        });
};

const uploadAndCreateVideo = async (file, folderName) => {
    return uploadVideo(file, folderName)
        .then((videoData) => createVideo(videoData));
};

const videoURL = (video) => `/v/${video.id}`;

export {
    getVideos,
    uploadVideo,
    uploadAndCreateVideo,
    getVideo,
    videoURL,
};
