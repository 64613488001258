import get from 'lodash/get';

import { addError } from '@src/validations';
import { isValidUrl } from '@src/utils/isValidUrl';

import { source } from './data';

export const validate = (values) => {
    const errors = {};

    if (!get(values, source.alt)) {
        addError(errors, source.alt, 'An Alternate text is required');
    }

    const sourceValue = get(values, source.source);
    if (!sourceValue) {
        addError(errors, source.source, 'A Source is required');
    } else if (!isValidUrl(sourceValue)) {
        addError(errors, source.source, 'Source must be an URL');
    }

    return errors;
};

