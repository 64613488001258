function mapGetOneUserData(responseData) {
    return {
        ...responseData,
        ...mapRolesForList(responseData),
    };
}

function mapRolesForList(data) {
    return {
        categories: data.roles.length === 0 ? [] : data.roles.map((role) => {
            return {
                id: role.id,
                name: role.name,
            };
        }),
    };
}

export { mapGetOneUserData, mapRolesForList };
