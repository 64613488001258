import {
    cleanCommonParams,
} from './common';

const mapAttachmentParams = (
    isCreate,
    {
        resources,
        thumbnail,
        ...params
    },
) => {
    return new Promise((resolve) => {
        resolve({
            attachment: {
                ...cleanCommonParams(params),
                // eslint-disable-next-line camelcase
                thumbnail_id: thumbnail ? thumbnail.id : null,
            },
        });
    });
};

export { mapAttachmentParams };
