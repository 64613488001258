import {
    cleanCommonParams,
    mapAttachmentsParams,
    mapContentParams,
    mapFeedItemParams,
    mapImageParams,
    mapProductsParams,
    mapSalesChannelsParams,
    mapStatesParams,
} from './common';

const mapCollateralParams = (
    isCreate,
    {
        attachments,
        categories,
        collateralCategories,
        content,
        // eslint-disable-next-line camelcase
        feed_item,
        image,
        products,
        // eslint-disable-next-line camelcase
        sales_channels,
        states,
        ...params
    },
) => {
    return new Promise((resolve) => {
        const categoryIds = [].concat(
            collateralCategories,
        );

        resolve({
            // eslint-disable-next-line camelcase
            collateral: {
                // eslint-disable-next-line camelcase
                role_type: 'agent',
                ...cleanCommonParams(params),
                ...mapAttachmentsParams(isCreate, attachments),
                ...mapContentParams(isCreate, content),
                ...mapFeedItemParams(isCreate, feed_item),
                ...mapImageParams(isCreate, image),
                ...mapProductsParams(isCreate, products),
                ...mapSalesChannelsParams(isCreate, sales_channels),
                ...mapStatesParams(isCreate, states),
                // eslint-disable-next-line camelcase
                category_ids: categoryIds,
            },
        });
    });
};

export { mapCollateralParams };
