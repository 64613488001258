import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import { BooleanInput, TextInput, SelectInput, ReferenceInput } from 'react-admin';
import { Field } from 'react-final-form';

import { source } from '../data';
import { outdatedNotifyOptions } from '../data';
import { Typography } from '@material-ui/core';

const AdminForm = (props) => {
    const { record } = props;
    const [isNotifyOutdatedEnabled, setNotifyOutdatedEnabled] = useState(record.notify_about_outdated);

    const handleOnChange = useCallback(() => {
        setNotifyOutdatedEnabled(!isNotifyOutdatedEnabled);
    }, [setNotifyOutdatedEnabled, isNotifyOutdatedEnabled]);

    return (
        <>
            <Field
                type="hidden"
                name={source.accessLevel}
                component="input"
            />
            <Grid container>
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        label="Employee ID"
                        source={source.employeeId}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        source={source.email}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={5}>
                    <TextInput
                        fullWidth
                        source={source.firstName}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextInput
                        fullWidth
                        source={source.middleInitial}
                    />
                </Grid>
                <Grid item xs={12} md={5}>
                    <TextInput
                        fullWidth
                        source={source.lastName}
                    />
                </Grid>
            </Grid>
            <h3>Access Group</h3>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <ReferenceInput
                        required
                        label="Group Access"
                        source={source.roleId}
                        reference="roles"
                    >
                        <SelectInput
                            fullWidth
                            emptyText="Select Role"
                        />
                    </ReferenceInput>
                </Grid>
            </Grid>
            <h3>Display Preferences</h3>
            <Grid container>
                <Grid item xs={6} md={3}>
                    <BooleanInput
                        label="Draft Content"
                        source={source.showDraft}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <BooleanInput
                        label="Archived Content"
                        source={source.showArchived}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <BooleanInput
                        label="Expired Content"
                        source={source.showExpired}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <BooleanInput
                        label="Scheduled Content"
                        source={source.showScheduled}
                    />
                </Grid>
            </Grid>
            <h3>Notification Preferences</h3>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <BooleanInput
                        label="Notify about Outdated Homepage items"
                        source={source.notifyAboutOutdated}
                        onChange={handleOnChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SelectInput
                        fullWidth
                        allowEmpty={false}
                        disabled={!isNotifyOutdatedEnabled}
                        label="Send me Email with Notifications digest every:"
                        source={source.outdatedNotifyDays}
                        choices={outdatedNotifyOptions}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <BooleanInput
                        label="Expire Pages"
                        source={source.notifyAboutPageExpiration}
                    />
                    <BooleanInput
                        label="Expire Items"
                        source={source.notifyAboutItemExpiration}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography component="h1" variant="h6">
                        Items about to Expire Reminder
                    </Typography>
                    <BooleanInput
                        label="Day Before"
                        source={source.expireIn1Day}
                    />
                    <BooleanInput
                        label="7 days"
                        source={source.expireIn7Days}
                    />
                    <BooleanInput
                        label="30 days"
                        source={source.expireIn30Days}
                    />
                </Grid>
            </Grid>
        </>
    );
};

AdminForm.propTypes = {
    isCreate: PropTypes.bool,
    resource: PropTypes.string.isRequired,
    record: PropTypes.object,
};

export default AdminForm;
