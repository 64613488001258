import React from 'react';
import PropTypes from 'prop-types';

import { SimpleForm } from 'react-admin';

import { createCategoryTitle } from './utils';

import { CreateActions, MaxWidthCreate } from '@src/custom';
import Form from './Form';

const CreateCategory = (props) => (
    <MaxWidthCreate
        {...props}
        title={createCategoryTitle(props.basePath)}
        actions={<CreateActions />}
    >
        <SimpleForm
            redirect="edit"
            submitOnEnter={false}
        >
            <Form {...props} />
        </SimpleForm>
    </MaxWidthCreate>
);

CreateCategory.propTypes = {
    basePath: PropTypes.string,
};

export default CreateCategory;
